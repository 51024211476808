import "whatwg-fetch";
import React from "react";
import { Auth } from "aws-amplify";
import { withRouter } from "react-router-dom";
import { isMobile } from "react-device-detect";
import Button from "@material-ui/core/Button";
import Utils from "./utils";
import DoneIcon from "@material-ui/icons/Check";
import DevicesIcon from "@material-ui/icons/Devices";
import * as Sentry from "@sentry/browser";
import Snackbar from "@material-ui/core/Snackbar";

// import StarbucksLogo from "./starbuckslogo.jpeg";
import SyncIcon from "@material-ui/icons/Sync";
import SignalBadIcon from "@material-ui/icons/SignalCellularConnectedNoInternet4Bar";
import SignalGoodIcon from "@material-ui/icons/SignalCellular4Bar";

class Mobile extends React.Component {
  doSnackBar(input) {
    this.setState({ statusbar: true, statusbartext: input });
  }
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      lastStamp: null,
      type: "",
      logo: "",
      synctext: "Sync",
      // showGeoButton: false,
      faqsection: [],
      signalisgood: false,
      products: []
    };
  }
  async tester() {
    var c = await Utils.isFastInternet();
    // console.log(c);
    this.setState({ signalisgood: c });
  }

  async performCleanup() {
    // console.log(navigator.onLine)
    // console.log(await Utils.isFastInternet())
    this.doSnackBar("Syncing");
    if (navigator.onLine && (await Utils.isFastInternet())) {
      this.setState({ synctext: " Sending..." });
      await Utils.cleanupOfflineData("savePicToS3");
      await Utils.cleanupOfflineData("createPromotionResponse");
      await Utils.cleanupOfflineData("updateLastGeoTag");
      await Utils.cleanupOfflineData("createPromotionStockTake");
      await Utils.cleanupOfflineData("signOffByScheduleId");
      this.setState({ offlineResponses: " " });
      this.doSnackBar("Done");
      this.timer = setInterval(() => {
        this.setState({ synctext: "Sync" });
      }, 4000);
    } else {
      this.setState({ synctext: "Weak signal...try again in 5 min" });
      this.timer = setInterval(() => {
        this.setState({ synctext: "Sync" });
      }, 4000);
    }
  }

  async initUser() {
    this.doSnackBar("Collecting info");
    let cognitouser = await Auth.currentAuthenticatedUser();
    let dynamofinder = "";
    let tempcheck2 = await Auth.userAttributes(cognitouser);
    if (
      tempcheck2.filter(item => item.Name === "custom:dynamoid").length === 0
    ) {
      await Utils.getUser(cognitouser.username);
      tempcheck2 = await Auth.userAttributes(cognitouser);
      dynamofinder = tempcheck2.filter(
        item => item.Name === "custom:dynamoid"
      )[0].Value;
    } else
      dynamofinder = tempcheck2.filter(
        item => item.Name === "custom:dynamoid"
      )[0].Value;

    // this.setState({ type: dynamofinder.type });
    // if (!navigator.onLine || !(await Utils.isFastInternet())) {
    //   user = JSON.parse(localStorage.getItem("cognitouser"));
    //   products = JSON.parse(localStorage.getItem("products"));
    //   this.setState({ products: products });
    // } else {
    let user = null;
    let products = null;
    let ll = null;
    this.doSnackBar("Searching for your promotions");
    if (!navigator.onLine || !this.state.signalisgood) {
      user = JSON.parse(localStorage.getItem("getUserByDynamoId"));
      products = JSON.parse(localStorage.getItem("products"));
      ll = JSON.parse(localStorage.getItem("alllabels"));
    } else {
      let promtest = await Promise.all([
        Utils.getUserByDynamoId(dynamofinder),
        Utils.getAllProductLines(),
        Utils.getAllLabels()
      ]);
      localStorage.setItem("getUserByDynamoId", JSON.stringify(user));
      localStorage.setItem("products", JSON.stringify(products));
      localStorage.setItem("alllabels", JSON.stringify(ll));
      user = promtest[0];
      products = promtest[1];
      this.faqsection(products, user);
      ll = promtest[2];
      Sentry.setUser({
        username: user.name,
        email: cognitouser.attributes.email
      });
    }
    this.doSnackBar("Done");
    // var newsched = [];
    // var todaytest = new Date(Date.now());
    // todaytest.setHours(todaytest.getHours() - 3 * 24);
    // user.schedule.items.forEach(function(a) {
    // a.enddate = new Date(a.enddate);
    // if (a.enddate > todaytest) {
    // newsched.push(a);
    // }
    // });
    // var newuser = user;
    // newuser.schedule.items = newsched;
    // localStorage.setItem("cognitouser", JSON.stringify(newuser));
    // products = await Utils.getAllProductLines();
    // this.setState({ products: products });
    // for (var i = 0; i < products.length; i++) {
    //   if (products[i].faqicon !== null)
    //     products[i].faqicon = await Utils.getPicFromS3(products[i].faqicon);
    // }
    // localStorage.setItem("products", JSON.stringify(products));
    // }
    // user.name = user.name.replace("@promoterplus.com", "");
    // let ll = [];
    // if (!navigator.onLine || !(await Utils.isFastInternet()))
    //   ll = JSON.parse(localStorage.getItem("alllabels"));
    // else {
    //   ll = await Utils.getAllLabels();
    //   localStorage.setItem("alllabels", JSON.stringify(ll));
    // }
    // console.log(ll)
    var logo = null;
    if (ll)
      logo = ll
        .filter(z => z.label === user.label)[0]
        .config.replace("logo=", "");
    // console.log(logo)
    // ll.forEach(function(e) {
    //   if (e.label === user.label)
    //     logo = e.config.split(",")[0].replace("logo=", "");
    // });
    // this.setState({ logo });
    // var scheduleids = [];
    // user.schedule.items.forEach(function(e) {
    //   scheduleids.push(e.id);
    // });
    // user.schedule.items = JSON.parse(
    //   JSON.stringify(
    //     await Promise.all(scheduleids.map(Utils.getPromotionByScheduleId))
    //   )
    // );
    var today = new Date(Date.now());
    if (user)
    user.schedule.items.forEach(function(e) {
      e.signoffdone = false;
      if (e.signofftimestamp) e.signoffdone = true;
      e.location = JSON.parse(e.location);
      e.startdate = new Date(e.startdate);
      e.enddate = new Date(e.enddate);
      e.enddate.setHours(e.enddate.getHours() - 2);
      e.startdate.setHours(e.startdate.getHours() - 2);
      e.disabled = true;
      if (e.enddate > today && e.startdate < today) e.disabled = false;
      e.enddate.setHours(e.enddate.getHours() + 2);
      e.startdate.setHours(e.startdate.getHours() + 2);
    });
    else user = {schedule:{items:[]}}
    // user.schedule.items = user.schedule.items.sort(mycomparator);
    this.setState({
      logo,
      products,
      user: user,
      showGeoButton: true,
      statusbar: false
    });
  }

  async faqsection(products, user) {
    // localStorage.setItem("products", JSON.stringify(products));
    var faqsection = [];
    let clients = [];
    user.schedule.items.forEach(z => clients.push(z.promotionID.client.id));
    clients = [...new Set(clients)];
    products.forEach(a => {
      clients.forEach(b => {
        if (a.clientid === b) {
          if (a.faqicon !== null && (a.faqtext !== null) & (a.faqpdf !== null))
            faqsection.push(a);
        }
      });
    });
    for (var i = 0; i < products.length; i++) {
      if (products[i].faqicon !== null)
        products[i].faqicon = await Utils.getPicFromS3(products[i].faqicon);
    }
    this.setState({ faqsection: faqsection });
  }

  componentDidMount() {
    this.tester();
    try {
      Auth.currentAuthenticatedUser()
        .then(() => {
          if (!isMobile) this.props.history.push("/desktop");
          this.initUser();
        })
        .catch(() => this.props.history.push("/signinmobile"));
    } catch (err) {}
  }

  async getPromotionByScheduleId(id) {
    let s = await Utils.getPromotionByScheduleId(id);
    if (s.promotionID.photos !== null)
      s.promotionID.photos = JSON.parse(s.promotionID.photos);
    else
      s.promotionID.photos = [
        { name: "Sign in", repeating: false, order: 0 },
        { name: "Promoter", repeating: false, order: 1 },
        { name: "Stand", repeating: false, order: 2 },
        { name: "Other photos", repeating: true, order: 3 }
      ];
    // Utils.log("PROMOTDATA");
    // Utils.log(s);
    // Utils.log(s.promotionID.productLine);
    // Utils.log(this.state.products);
    var prods = "";
    this.state.products.forEach(a => {
      if (a.id === s.promotionID.productLine) prods = a.products;
    });
    // Utils.log("prods");
    // Utils.log(prods);
    if (typeof s.promotionID.colours === "string")
      s.promotionID.colours = JSON.parse(s.promotionID.colours);
    // Utils.log(typeof s.promotionID.questions);
    if (typeof s.promotionID.questions === "string") {
      // Utils.log("GOT HERE");
      s.promotionID.questions = JSON.parse(s.promotionID.questions);
    }
    s.promotionID.questions.forEach(function(e) {
      // if (e.question === "product") e.items = JSON.parse(e.items);
      if (e.question === "product") e.items = prods;
    });
    // s.promotionID.products = JSON.parse(s.promotionID.products);
    s.promotionID.products = prods;
    s.location = JSON.parse(s.location);
    // Utils.log(s);
    localStorage.removeItem(s.id + "gpsdone");
    localStorage.removeItem(s.id + "stocktakedone");
    console.log(s.promotionID)
    console.log(this.state.user)
    console.log(s)
    this.props.history.push("/action", {
      promotion: s.promotionID,
      user: this.state.user,
      scheduleID: s
    });
  }

  showSchedule(input) {
    if (input.schedule)
      return input.schedule.items.map((item, index) => (
        <Button
          key={index}
          style={{
            margin: 5,
            textTransform: "none",
            width: "97%"
          }}
          variant="contained"
          disabled={item.disabled}
          color="primary"
          onClick={() => this.getPromotionByScheduleId(item.id)}
        >
          Location:{" " + item.location.address.replace(", South Africa", "")}{" "}
          <br />
          From:
          {" " +
            item.startdate.toDateString() +
            "   " +
            item.startdate.toISOString().substr(11, 5)}{" "}
          <br />
          To:
          {" " +
            new Date(
              item.enddate.setHours(item.enddate.getHours() - 2)
            ).toDateString() +
            "   " +
            new Date(item.enddate.setHours(item.enddate.getHours() + 2))
              .toISOString()
              .substr(11, 5)}
          <DoneIcon style={{ width: item.signoffdone ? 30 : 0 }} />
        </Button>
      ));
    else return <div />;
  }

  showAdminButton(input) {
    // Utils.log(input)
    if (input === "Admin")
      return (
        <Button
          style={{
            margin: 5,
            textTransform: "none"
          }}
          variant="contained"
          color="primary"
          onClick={() => this.props.history.push("/operations")}
        >
          Operations dashboard
        </Button>
      );
    else return <div />;
  }

  render() {
    // Utils.log(navigator.onLine)
    return (
      <div style={{}}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={this.state.statusbar}
          onClose={() => this.setState({ statusbar: false })}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{this.state.statusbartext}</span>}
        />
        <h2
          style={{
            alignSelf: "center",
            justifyContent: "center",
            display: "flex",
            color: "#666"
          }}
        >
          Welcome {this.state.user.name}
        </h2>
        <div
          style={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            color: "#666"
          }}
        >
          <img alt="" src={this.state.logo} width="50%" height="100%" />
          <h3>{this.state.user.label}</h3>
        </div>
        <div
          style={{
            alignSelf: "center",
            justifyContent: "center",
            display: "flex"
          }}
        >
          {this.showAdminButton(this.state.type)}
        </div>
        <br />
        <h2
          style={{
            alignSelf: "center",
            justifyContent: "center",
            display: "flex",
            color: "#666"
          }}
        >
          Your schedule
        </h2>
        <br />
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center"
          }}
          onClick={() => this.props.history.push("/compat")}
        >
          Compatibility test <DevicesIcon />
        </div>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center"
          }}
        >
          Signal quality{" "}
          {this.state.signalisgood ? (
            <SignalGoodIcon />
          ) : (
            <SignalBadIcon style={{ color: "#f00" }} />
          )}
          <div
            onClick={() => {
              localStorage.removeItem("lastofflinetest");
              this.tester();
            }}
          >
            {" "}
            Recheck
          </div>
        </div>
        <br />
        {this.showSchedule(this.state.user)}
        <div
          style={{
            alignSelf: "center",
            justifyContent: "center",
            display: "flex"
          }}
        >
          <Button
            style={{
              margin: 5,
              textTransform: "none"
            }}
            variant="contained"
            color="primary"
            onClick={() => {
              //  Auth.signOut();
              this.props.history.push("/signinmobile");
            }}
          >
            Sign out
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Button
            style={{
              margin: 5,
              textTransform: "none",
              display: "flex",
              justifyContent: "center"
            }}
            color="primary"
            variant="contained"
            onClick={() => {
              this.performCleanup();
            }}
          >
            <SyncIcon />
            {this.state.synctext}
          </Button>
        </div>
        {this.state.faqsection.map((item, index) => (
          <Button
            key={index}
            style={{
              margin: 5,
              textTransform: "none",
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#fff"
            }}
            variant="contained"
            href={item.faqpdf}
            transparent="true"
            target="_blank"
          >
            <img
              alt=""
              src={item.faqicon}
              style={{ width: 30, height: 30, marginRight: 3 }}
            />
            {item.faqtext}
          </Button>
        ))}
      </div>
    );
  }
}
export default withRouter(Mobile);
