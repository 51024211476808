import React from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
// import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
// import CardActions from "@material-ui/core/CardActions";
// import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
// import IconButton from "@material-ui/core/IconButton";
// import Typography from "@material-ui/core/Typography";
// import { red } from "@material-ui/core/colors";
// import FavoriteIcon from "@material-ui/icons/Favorite";
// import ShareIcon from "@material-ui/icons/Share";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import MoreVertIcon from "@material-ui/icons/MoreVert";
import Checkbox from "@material-ui/core/Checkbox";
// import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import EditIcon from "@material-ui/icons/Edit";
// import Utils from "./utils";

class CampaignCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    // console.log("this.props.data.products")
    // console.log(this.props.data)
    var colour = JSON.parse(this.props.data.colours);
    return (
      <div style={{ margin: 10, minWidth: 200 }}>
        <Card>
          <CardHeader
            avatar={
              <Avatar
                aria-label="Recipe"
                style={{ backgroundColor: colour.activebackground }}
              >
                {this.props.clientName.substr(0, 1).toUpperCase()}
              </Avatar>
            }
            subheader={this.props.data.promotionName}
            title={this.props.clientName}
          />
          <CardContent
            style={{
              paddingTop: 0,
              paddingBottom: 0,
              marginBottom: 0,
              maxWidth: 200,
              alignItems: "center",
              justifyContent: "space-between",
              display: "flex"
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.props.data.selected}
                  onChange={() => {
                    this.props.onPress(this.props.data.id);
                  }}
                  value="checkedA"
                />
              }
              label="Add to filter"
            />
            <EditIcon
              style={{ color: "#f50057" }}
              onClick={() => {

                this.props.onEditPress(this.props.data.id);
              }}
            />
          </CardContent>
          <CardContent style={{ fontSize: 12, paddingTop: 0, maxWidth: 200 }}>
            {console.log(this.props.data.startdate)}
            <b>Start date:</b> {this.props.data.startdate.substr(0, 10)}
            <br />
            <b>End date: </b>
            {this.props.data.enddate.substr(0, 10)}
            <br />
            <b>Products: </b>
            {this.props.data.products}
            <br />
          </CardContent>
        </Card>
      </div>
    );
  }
}
export default CampaignCard;
