/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPromoterPlusPromotion = /* GraphQL */ `
  mutation CreatePromoterPlusPromotion(
    $input: CreatePromoterPlusPromotionInput!
    $condition: ModelPromoterPlusPromotionConditionInput
  ) {
    createPromoterPlusPromotion(input: $input, condition: $condition) {
      id
      label
      promotionName
      client {
        id
        clientname
        label
        promotion {
          nextToken
        }
        reportconfig
        icon
        consumableusage {
          nextToken
        }
        consumablesetup
        consumablereplenishemail
      }
      productLine
      products
      questions
      promotionScheduler {
        items {
          id
          startdate
          enddate
          location
          gps
          signoffgps
          photosdone
          signindone
          stocktakedone
          stocktakevalues
          standpicdone
          promoterpicdone
          otheritemsdone
          signoffmanagername
          signofftimestamp
          signoffmanageremail
          signoffmanagerphonenumber
          signoffmanagerrating
          signoffmanagercomments
          label
        }
        nextToken
      }
      photos
      startdate
      enddate
      colours
    }
  }
`;
export const updatePromoterPlusPromotion = /* GraphQL */ `
  mutation UpdatePromoterPlusPromotion(
    $input: UpdatePromoterPlusPromotionInput!
    $condition: ModelPromoterPlusPromotionConditionInput
  ) {
    updatePromoterPlusPromotion(input: $input, condition: $condition) {
      id
      label
      promotionName
      client {
        id
        clientname
        label
        promotion {
          nextToken
        }
        reportconfig
        icon
        consumableusage {
          nextToken
        }
        consumablesetup
        consumablereplenishemail
      }
      productLine
      products
      questions
      promotionScheduler {
        items {
          id
          startdate
          enddate
          location
          gps
          signoffgps
          photosdone
          signindone
          stocktakedone
          stocktakevalues
          standpicdone
          promoterpicdone
          otheritemsdone
          signoffmanagername
          signofftimestamp
          signoffmanageremail
          signoffmanagerphonenumber
          signoffmanagerrating
          signoffmanagercomments
          label
        }
        nextToken
      }
      photos
      startdate
      enddate
      colours
    }
  }
`;
export const deletePromoterPlusPromotion = /* GraphQL */ `
  mutation DeletePromoterPlusPromotion(
    $input: DeletePromoterPlusPromotionInput!
    $condition: ModelPromoterPlusPromotionConditionInput
  ) {
    deletePromoterPlusPromotion(input: $input, condition: $condition) {
      id
      label
      promotionName
      client {
        id
        clientname
        label
        promotion {
          nextToken
        }
        reportconfig
        icon
        consumableusage {
          nextToken
        }
        consumablesetup
        consumablereplenishemail
      }
      productLine
      products
      questions
      promotionScheduler {
        items {
          id
          startdate
          enddate
          location
          gps
          signoffgps
          photosdone
          signindone
          stocktakedone
          stocktakevalues
          standpicdone
          promoterpicdone
          otheritemsdone
          signoffmanagername
          signofftimestamp
          signoffmanageremail
          signoffmanagerphonenumber
          signoffmanagerrating
          signoffmanagercomments
          label
        }
        nextToken
      }
      photos
      startdate
      enddate
      colours
    }
  }
`;
export const createPromoterPlusPhotos = /* GraphQL */ `
  mutation CreatePromoterPlusPhotos(
    $input: CreatePromoterPlusPhotosInput!
    $condition: ModelPromoterPlusPhotosConditionInput
  ) {
    createPromoterPlusPhotos(input: $input, condition: $condition) {
      id
      user {
        id
        cognitoid
        label
        name
        email
        homelocation
        type
        access
        schedule {
          nextToken
        }
        phone
      }
      schedule {
        id
        promotionID {
          id
          label
          promotionName
          productLine
          products
          questions
          photos
          startdate
          enddate
          colours
        }
        user {
          id
          cognitoid
          label
          name
          email
          homelocation
          type
          access
          phone
        }
        responses {
          nextToken
        }
        promotionPhotos {
          nextToken
        }
        startdate
        enddate
        location
        gps
        signoffgps
        photosdone
        signindone
        stocktakedone
        stocktakevalues
        standpicdone
        promoterpicdone
        otheritemsdone
        signoffmanagername
        signofftimestamp
        signoffmanageremail
        signoffmanagerphonenumber
        signoffmanagerrating
        signoffmanagercomments
        label
      }
      timestamp
      filename
      phototype
      label
    }
  }
`;
export const updatePromoterPlusPhotos = /* GraphQL */ `
  mutation UpdatePromoterPlusPhotos(
    $input: UpdatePromoterPlusPhotosInput!
    $condition: ModelPromoterPlusPhotosConditionInput
  ) {
    updatePromoterPlusPhotos(input: $input, condition: $condition) {
      id
      user {
        id
        cognitoid
        label
        name
        email
        homelocation
        type
        access
        schedule {
          nextToken
        }
        phone
      }
      schedule {
        id
        promotionID {
          id
          label
          promotionName
          productLine
          products
          questions
          photos
          startdate
          enddate
          colours
        }
        user {
          id
          cognitoid
          label
          name
          email
          homelocation
          type
          access
          phone
        }
        responses {
          nextToken
        }
        promotionPhotos {
          nextToken
        }
        startdate
        enddate
        location
        gps
        signoffgps
        photosdone
        signindone
        stocktakedone
        stocktakevalues
        standpicdone
        promoterpicdone
        otheritemsdone
        signoffmanagername
        signofftimestamp
        signoffmanageremail
        signoffmanagerphonenumber
        signoffmanagerrating
        signoffmanagercomments
        label
      }
      timestamp
      filename
      phototype
      label
    }
  }
`;
export const deletePromoterPlusPhotos = /* GraphQL */ `
  mutation DeletePromoterPlusPhotos(
    $input: DeletePromoterPlusPhotosInput!
    $condition: ModelPromoterPlusPhotosConditionInput
  ) {
    deletePromoterPlusPhotos(input: $input, condition: $condition) {
      id
      user {
        id
        cognitoid
        label
        name
        email
        homelocation
        type
        access
        schedule {
          nextToken
        }
        phone
      }
      schedule {
        id
        promotionID {
          id
          label
          promotionName
          productLine
          products
          questions
          photos
          startdate
          enddate
          colours
        }
        user {
          id
          cognitoid
          label
          name
          email
          homelocation
          type
          access
          phone
        }
        responses {
          nextToken
        }
        promotionPhotos {
          nextToken
        }
        startdate
        enddate
        location
        gps
        signoffgps
        photosdone
        signindone
        stocktakedone
        stocktakevalues
        standpicdone
        promoterpicdone
        otheritemsdone
        signoffmanagername
        signofftimestamp
        signoffmanageremail
        signoffmanagerphonenumber
        signoffmanagerrating
        signoffmanagercomments
        label
      }
      timestamp
      filename
      phototype
      label
    }
  }
`;
export const createPromoterPlusResponses = /* GraphQL */ `
  mutation CreatePromoterPlusResponses(
    $input: CreatePromoterPlusResponsesInput!
    $condition: ModelPromoterPlusResponsesConditionInput
  ) {
    createPromoterPlusResponses(input: $input, condition: $condition) {
      id
      user {
        id
        cognitoid
        label
        name
        email
        homelocation
        type
        access
        schedule {
          nextToken
        }
        phone
      }
      schedule {
        id
        promotionID {
          id
          label
          promotionName
          productLine
          products
          questions
          photos
          startdate
          enddate
          colours
        }
        user {
          id
          cognitoid
          label
          name
          email
          homelocation
          type
          access
          phone
        }
        responses {
          nextToken
        }
        promotionPhotos {
          nextToken
        }
        startdate
        enddate
        location
        gps
        signoffgps
        photosdone
        signindone
        stocktakedone
        stocktakevalues
        standpicdone
        promoterpicdone
        otheritemsdone
        signoffmanagername
        signofftimestamp
        signoffmanageremail
        signoffmanagerphonenumber
        signoffmanagerrating
        signoffmanagercomments
        label
      }
      timestamp
      response
      label
    }
  }
`;
export const updatePromoterPlusResponses = /* GraphQL */ `
  mutation UpdatePromoterPlusResponses(
    $input: UpdatePromoterPlusResponsesInput!
    $condition: ModelPromoterPlusResponsesConditionInput
  ) {
    updatePromoterPlusResponses(input: $input, condition: $condition) {
      id
      user {
        id
        cognitoid
        label
        name
        email
        homelocation
        type
        access
        schedule {
          nextToken
        }
        phone
      }
      schedule {
        id
        promotionID {
          id
          label
          promotionName
          productLine
          products
          questions
          photos
          startdate
          enddate
          colours
        }
        user {
          id
          cognitoid
          label
          name
          email
          homelocation
          type
          access
          phone
        }
        responses {
          nextToken
        }
        promotionPhotos {
          nextToken
        }
        startdate
        enddate
        location
        gps
        signoffgps
        photosdone
        signindone
        stocktakedone
        stocktakevalues
        standpicdone
        promoterpicdone
        otheritemsdone
        signoffmanagername
        signofftimestamp
        signoffmanageremail
        signoffmanagerphonenumber
        signoffmanagerrating
        signoffmanagercomments
        label
      }
      timestamp
      response
      label
    }
  }
`;
export const deletePromoterPlusResponses = /* GraphQL */ `
  mutation DeletePromoterPlusResponses(
    $input: DeletePromoterPlusResponsesInput!
    $condition: ModelPromoterPlusResponsesConditionInput
  ) {
    deletePromoterPlusResponses(input: $input, condition: $condition) {
      id
      user {
        id
        cognitoid
        label
        name
        email
        homelocation
        type
        access
        schedule {
          nextToken
        }
        phone
      }
      schedule {
        id
        promotionID {
          id
          label
          promotionName
          productLine
          products
          questions
          photos
          startdate
          enddate
          colours
        }
        user {
          id
          cognitoid
          label
          name
          email
          homelocation
          type
          access
          phone
        }
        responses {
          nextToken
        }
        promotionPhotos {
          nextToken
        }
        startdate
        enddate
        location
        gps
        signoffgps
        photosdone
        signindone
        stocktakedone
        stocktakevalues
        standpicdone
        promoterpicdone
        otheritemsdone
        signoffmanagername
        signofftimestamp
        signoffmanageremail
        signoffmanagerphonenumber
        signoffmanagerrating
        signoffmanagercomments
        label
      }
      timestamp
      response
      label
    }
  }
`;
export const createPromoterPlusSchedule = /* GraphQL */ `
  mutation CreatePromoterPlusSchedule(
    $input: CreatePromoterPlusScheduleInput!
    $condition: ModelPromoterPlusScheduleConditionInput
  ) {
    createPromoterPlusSchedule(input: $input, condition: $condition) {
      id
      promotionID {
        id
        label
        promotionName
        client {
          id
          clientname
          label
          reportconfig
          icon
          consumablesetup
          consumablereplenishemail
        }
        productLine
        products
        questions
        promotionScheduler {
          nextToken
        }
        photos
        startdate
        enddate
        colours
      }
      user {
        id
        cognitoid
        label
        name
        email
        homelocation
        type
        access
        schedule {
          nextToken
        }
        phone
      }
      responses {
        items {
          id
          timestamp
          response
          label
        }
        nextToken
      }
      promotionPhotos {
        items {
          id
          timestamp
          filename
          phototype
          label
        }
        nextToken
      }
      startdate
      enddate
      location
      gps
      signoffgps
      photosdone
      signindone
      stocktakedone
      stocktakevalues
      standpicdone
      promoterpicdone
      otheritemsdone
      signoffmanagername
      signofftimestamp
      signoffmanageremail
      signoffmanagerphonenumber
      signoffmanagerrating
      signoffmanagercomments
      label
    }
  }
`;
export const updatePromoterPlusSchedule = /* GraphQL */ `
  mutation UpdatePromoterPlusSchedule(
    $input: UpdatePromoterPlusScheduleInput!
    $condition: ModelPromoterPlusScheduleConditionInput
  ) {
    updatePromoterPlusSchedule(input: $input, condition: $condition) {
      id
      promotionID {
        id
        label
        promotionName
        client {
          id
          clientname
          label
          reportconfig
          icon
          consumablesetup
          consumablereplenishemail
        }
        productLine
        products
        questions
        promotionScheduler {
          nextToken
        }
        photos
        startdate
        enddate
        colours
      }
      user {
        id
        cognitoid
        label
        name
        email
        homelocation
        type
        access
        schedule {
          nextToken
        }
        phone
      }
      responses {
        items {
          id
          timestamp
          response
          label
        }
        nextToken
      }
      promotionPhotos {
        items {
          id
          timestamp
          filename
          phototype
          label
        }
        nextToken
      }
      startdate
      enddate
      location
      gps
      signoffgps
      photosdone
      signindone
      stocktakedone
      stocktakevalues
      standpicdone
      promoterpicdone
      otheritemsdone
      signoffmanagername
      signofftimestamp
      signoffmanageremail
      signoffmanagerphonenumber
      signoffmanagerrating
      signoffmanagercomments
      label
    }
  }
`;
export const deletePromoterPlusSchedule = /* GraphQL */ `
  mutation DeletePromoterPlusSchedule(
    $input: DeletePromoterPlusScheduleInput!
    $condition: ModelPromoterPlusScheduleConditionInput
  ) {
    deletePromoterPlusSchedule(input: $input, condition: $condition) {
      id
      promotionID {
        id
        label
        promotionName
        client {
          id
          clientname
          label
          reportconfig
          icon
          consumablesetup
          consumablereplenishemail
        }
        productLine
        products
        questions
        promotionScheduler {
          nextToken
        }
        photos
        startdate
        enddate
        colours
      }
      user {
        id
        cognitoid
        label
        name
        email
        homelocation
        type
        access
        schedule {
          nextToken
        }
        phone
      }
      responses {
        items {
          id
          timestamp
          response
          label
        }
        nextToken
      }
      promotionPhotos {
        items {
          id
          timestamp
          filename
          phototype
          label
        }
        nextToken
      }
      startdate
      enddate
      location
      gps
      signoffgps
      photosdone
      signindone
      stocktakedone
      stocktakevalues
      standpicdone
      promoterpicdone
      otheritemsdone
      signoffmanagername
      signofftimestamp
      signoffmanageremail
      signoffmanagerphonenumber
      signoffmanagerrating
      signoffmanagercomments
      label
    }
  }
`;
export const createPromoterPlusClient = /* GraphQL */ `
  mutation CreatePromoterPlusClient(
    $input: CreatePromoterPlusClientInput!
    $condition: ModelPromoterPlusClientConditionInput
  ) {
    createPromoterPlusClient(input: $input, condition: $condition) {
      id
      clientname
      label
      promotion {
        items {
          id
          label
          promotionName
          productLine
          products
          questions
          photos
          startdate
          enddate
          colours
        }
        nextToken
      }
      reportconfig
      icon
      consumableusage {
        items {
          id
          userID
          scheduleID
          timestamp
          usage
        }
        nextToken
      }
      consumablesetup
      consumablereplenishemail
    }
  }
`;
export const updatePromoterPlusClient = /* GraphQL */ `
  mutation UpdatePromoterPlusClient(
    $input: UpdatePromoterPlusClientInput!
    $condition: ModelPromoterPlusClientConditionInput
  ) {
    updatePromoterPlusClient(input: $input, condition: $condition) {
      id
      clientname
      label
      promotion {
        items {
          id
          label
          promotionName
          productLine
          products
          questions
          photos
          startdate
          enddate
          colours
        }
        nextToken
      }
      reportconfig
      icon
      consumableusage {
        items {
          id
          userID
          scheduleID
          timestamp
          usage
        }
        nextToken
      }
      consumablesetup
      consumablereplenishemail
    }
  }
`;
export const deletePromoterPlusClient = /* GraphQL */ `
  mutation DeletePromoterPlusClient(
    $input: DeletePromoterPlusClientInput!
    $condition: ModelPromoterPlusClientConditionInput
  ) {
    deletePromoterPlusClient(input: $input, condition: $condition) {
      id
      clientname
      label
      promotion {
        items {
          id
          label
          promotionName
          productLine
          products
          questions
          photos
          startdate
          enddate
          colours
        }
        nextToken
      }
      reportconfig
      icon
      consumableusage {
        items {
          id
          userID
          scheduleID
          timestamp
          usage
        }
        nextToken
      }
      consumablesetup
      consumablereplenishemail
    }
  }
`;
export const createPromoterPlusProduct = /* GraphQL */ `
  mutation CreatePromoterPlusProduct(
    $input: CreatePromoterPlusProductInput!
    $condition: ModelPromoterPlusProductConditionInput
  ) {
    createPromoterPlusProduct(input: $input, condition: $condition) {
      id
      clientid
      productline
      products
      label
      faqpdf
      faqicon
      faqtext
    }
  }
`;
export const updatePromoterPlusProduct = /* GraphQL */ `
  mutation UpdatePromoterPlusProduct(
    $input: UpdatePromoterPlusProductInput!
    $condition: ModelPromoterPlusProductConditionInput
  ) {
    updatePromoterPlusProduct(input: $input, condition: $condition) {
      id
      clientid
      productline
      products
      label
      faqpdf
      faqicon
      faqtext
    }
  }
`;
export const deletePromoterPlusProduct = /* GraphQL */ `
  mutation DeletePromoterPlusProduct(
    $input: DeletePromoterPlusProductInput!
    $condition: ModelPromoterPlusProductConditionInput
  ) {
    deletePromoterPlusProduct(input: $input, condition: $condition) {
      id
      clientid
      productline
      products
      label
      faqpdf
      faqicon
      faqtext
    }
  }
`;
export const createPromoterPlusUser = /* GraphQL */ `
  mutation CreatePromoterPlusUser(
    $input: CreatePromoterPlusUserInput!
    $condition: ModelPromoterPlusUserConditionInput
  ) {
    createPromoterPlusUser(input: $input, condition: $condition) {
      id
      cognitoid
      label
      name
      email
      homelocation
      type
      access
      schedule {
        items {
          id
          startdate
          enddate
          location
          gps
          signoffgps
          photosdone
          signindone
          stocktakedone
          stocktakevalues
          standpicdone
          promoterpicdone
          otheritemsdone
          signoffmanagername
          signofftimestamp
          signoffmanageremail
          signoffmanagerphonenumber
          signoffmanagerrating
          signoffmanagercomments
          label
        }
        nextToken
      }
      phone
    }
  }
`;
export const updatePromoterPlusUser = /* GraphQL */ `
  mutation UpdatePromoterPlusUser(
    $input: UpdatePromoterPlusUserInput!
    $condition: ModelPromoterPlusUserConditionInput
  ) {
    updatePromoterPlusUser(input: $input, condition: $condition) {
      id
      cognitoid
      label
      name
      email
      homelocation
      type
      access
      schedule {
        items {
          id
          startdate
          enddate
          location
          gps
          signoffgps
          photosdone
          signindone
          stocktakedone
          stocktakevalues
          standpicdone
          promoterpicdone
          otheritemsdone
          signoffmanagername
          signofftimestamp
          signoffmanageremail
          signoffmanagerphonenumber
          signoffmanagerrating
          signoffmanagercomments
          label
        }
        nextToken
      }
      phone
    }
  }
`;
export const deletePromoterPlusUser = /* GraphQL */ `
  mutation DeletePromoterPlusUser(
    $input: DeletePromoterPlusUserInput!
    $condition: ModelPromoterPlusUserConditionInput
  ) {
    deletePromoterPlusUser(input: $input, condition: $condition) {
      id
      cognitoid
      label
      name
      email
      homelocation
      type
      access
      schedule {
        items {
          id
          startdate
          enddate
          location
          gps
          signoffgps
          photosdone
          signindone
          stocktakedone
          stocktakevalues
          standpicdone
          promoterpicdone
          otheritemsdone
          signoffmanagername
          signofftimestamp
          signoffmanageremail
          signoffmanagerphonenumber
          signoffmanagerrating
          signoffmanagercomments
          label
        }
        nextToken
      }
      phone
    }
  }
`;
export const createPromoterPlusLabels = /* GraphQL */ `
  mutation CreatePromoterPlusLabels(
    $input: CreatePromoterPlusLabelsInput!
    $condition: ModelPromoterPlusLabelsConditionInput
  ) {
    createPromoterPlusLabels(input: $input, condition: $condition) {
      id
      label
      config
    }
  }
`;
export const updatePromoterPlusLabels = /* GraphQL */ `
  mutation UpdatePromoterPlusLabels(
    $input: UpdatePromoterPlusLabelsInput!
    $condition: ModelPromoterPlusLabelsConditionInput
  ) {
    updatePromoterPlusLabels(input: $input, condition: $condition) {
      id
      label
      config
    }
  }
`;
export const deletePromoterPlusLabels = /* GraphQL */ `
  mutation DeletePromoterPlusLabels(
    $input: DeletePromoterPlusLabelsInput!
    $condition: ModelPromoterPlusLabelsConditionInput
  ) {
    deletePromoterPlusLabels(input: $input, condition: $condition) {
      id
      label
      config
    }
  }
`;
export const createPromoterPlusConsumed = /* GraphQL */ `
  mutation CreatePromoterPlusConsumed(
    $input: CreatePromoterPlusConsumedInput!
    $condition: ModelPromoterPlusConsumedConditionInput
  ) {
    createPromoterPlusConsumed(input: $input, condition: $condition) {
      id
      userID
      scheduleID
      consumableID {
        id
        clientname
        label
        promotion {
          nextToken
        }
        reportconfig
        icon
        consumableusage {
          nextToken
        }
        consumablesetup
        consumablereplenishemail
      }
      timestamp
      usage
    }
  }
`;
export const updatePromoterPlusConsumed = /* GraphQL */ `
  mutation UpdatePromoterPlusConsumed(
    $input: UpdatePromoterPlusConsumedInput!
    $condition: ModelPromoterPlusConsumedConditionInput
  ) {
    updatePromoterPlusConsumed(input: $input, condition: $condition) {
      id
      userID
      scheduleID
      consumableID {
        id
        clientname
        label
        promotion {
          nextToken
        }
        reportconfig
        icon
        consumableusage {
          nextToken
        }
        consumablesetup
        consumablereplenishemail
      }
      timestamp
      usage
    }
  }
`;
export const deletePromoterPlusConsumed = /* GraphQL */ `
  mutation DeletePromoterPlusConsumed(
    $input: DeletePromoterPlusConsumedInput!
    $condition: ModelPromoterPlusConsumedConditionInput
  ) {
    deletePromoterPlusConsumed(input: $input, condition: $condition) {
      id
      userID
      scheduleID
      consumableID {
        id
        clientname
        label
        promotion {
          nextToken
        }
        reportconfig
        icon
        consumableusage {
          nextToken
        }
        consumablesetup
        consumablereplenishemail
      }
      timestamp
      usage
    }
  }
`;
export const createPromoterPlusProjectTracker = /* GraphQL */ `
  mutation CreatePromoterPlusProjectTracker(
    $input: CreatePromoterPlusProjectTrackerInput!
    $condition: ModelPromoterPlusProjectTrackerConditionInput
  ) {
    createPromoterPlusProjectTracker(input: $input, condition: $condition) {
      id
      test
    }
  }
`;
export const updatePromoterPlusProjectTracker = /* GraphQL */ `
  mutation UpdatePromoterPlusProjectTracker(
    $input: UpdatePromoterPlusProjectTrackerInput!
    $condition: ModelPromoterPlusProjectTrackerConditionInput
  ) {
    updatePromoterPlusProjectTracker(input: $input, condition: $condition) {
      id
      test
    }
  }
`;
export const deletePromoterPlusProjectTracker = /* GraphQL */ `
  mutation DeletePromoterPlusProjectTracker(
    $input: DeletePromoterPlusProjectTrackerInput!
    $condition: ModelPromoterPlusProjectTrackerConditionInput
  ) {
    deletePromoterPlusProjectTracker(input: $input, condition: $condition) {
      id
      test
    }
  }
`;
