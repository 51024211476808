import "whatwg-fetch";
import { graphqlOperation, API, Auth } from "aws-amplify";
import * as queries from "./graphql/queries";
import * as mutations from "./graphql/mutations";
import { Storage } from "aws-amplify";
import { isMobile } from "react-device-detect";
var Buffer = require("buffer/").Buffer;

const Utils = {
  log(input) {
    if (process.env.NODE_ENV === "development") console.log(input);
  },
  async createUser(input) {
    // this.log(input)
    try {
      let ret = await API.graphql(
        graphqlOperation(mutations.createPromoterPlusUser, {
          input: {
            cognitoid: input.id,
            name: input.name,
            type: input.type,
            email: input.email,
            access: input.clients,
            label: input.label
          }
        })
      );
      return ret;
    } catch (err) {
      // this.log(err);
    }
    // this.log(user);
  },
  async updateUserlocation(input) {
    // this.log(input)
    try {
      let ret = await API.graphql(
        graphqlOperation(mutations.updatePromoterPlusUser, {
          input: {
            id: input.id,

            homelocation: input.homelocation
          }
        })
      );
      return ret;
    } catch (err) {
      // this.log(err);
    }
    // this.log(user);
  },
  generateName() {
    var text = "";
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < 100; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  },
  getTimeOptions() {
    return [
      "00:00",
      "00:30",
      "01:00",
      "01:30",
      "02:00",
      "02:30",
      "03:00",
      "03:30",
      "04:00",
      "04:30",
      "05:00",
      "05:30",
      "06:00",
      "06:30",
      "07:00",
      "07:30",
      "08:00",
      "08:30",
      "09:00",
      "09:30",
      "10:00",
      "10:30",
      "11:00",
      "11:30",
      "12:00",
      "12:30",
      "13:00",
      "13:30",
      "14:00",
      "14:30",
      "15:00",
      "15:30",
      "16:00",
      "16:30",
      "17:00",
      "17:30",
      "18:00",
      "18:30",
      "19:00",
      "19:30",
      "20:00",
      "20:30",
      "21:00",
      "21:30",
      "22:00",
      "22:30",
      "23:00",
      "23:30",
      "24:00"
    ];
  },
  getTimeOptionsShort() {
    return [
      "07:00",
      "08:00",
      "09:00",
      "10:00",
      "11:00",
      "12:00",
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
      "18:00",
      "19:00",
      "20:00",
      "21:00",
      "22:00"
    ];
  },
  async searchMaps(input) {},
  async getUser(cognitoid) {
    // let cognitouser = await Auth.currentAuthenticatedUser();
    let cognitouser = await Auth.currentUserInfo();

    var online = await this.isFastInternet();
    if (!isMobile) online = true;
    // console.log("in the fubnc");
    // console.log(await this.isFastInternet());
    if (navigator.onLine && online) {
      // console.log("online")
      if (typeof cognitouser.attributes["custom:dynamoid"] === "undefined") {
        let user = await API.graphql(
          graphqlOperation(queries.listPromoterPlusUsers, {
            limit: 10000,
            filter: { cognitoid: { eq: cognitoid } }
          })
        );
        let coguser = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(coguser, {
          "custom:dynamoid": user.data.listPromoterPlusUsers.items[0].id
        });
        localStorage.setItem(
          "getUser",
          JSON.stringify(user.data.listPromoterPlusUsers.items[0])
        );
        return user.data.listPromoterPlusUsers.items[0];
      } else {
        let user = await Utils.getUserByDynamoId(
          cognitouser.attributes["custom:dynamoid"]
        );
        localStorage.setItem("getUser", JSON.stringify(user));
        return user;
      }
    } else {
      // console.log("offline")
      var user = localStorage.getItem("getUser");
      // console.log(user)
      if (user !== null) return JSON.parse(user);
      else return null;
    }
    //this.log(user);
  },
  async getUserAlwaysOnline(cognitoid) {
    let user = await API.graphql(
      graphqlOperation(queries.listPromoterPlusUsers, {
        limit: 10000,
        filter: { cognitoid: { eq: cognitoid } }
      })
    );

    return user.data.listPromoterPlusUsers.items[0];
  },
  async getUserByDynamoId(id) {
    let today = new Date(Date.now());
    today.setHours(today.getHours() - 24);
    let nextday = new Date(Date.now());
    nextday.setHours(today.getHours() + 48);

    let getPromoterPlusUser =
      `
       query GetPromoterPlusUser($id: ID!) {
         getPromoterPlusUser(id: $id) {
           id
           cognitoid
           label
           name
           email
           homelocation
           type
           access
           schedule(
             limit:1000,
             filter:{
               startdate:{
                 between:[
                   "` +
      today.toISOString() +
      `","` +
      nextday.toISOString() +
      `"
                 ]
               }
             }
           ) {
             items {
               promotionID {
                 id
                 label
                 promotionName
                 client {
                   id
                   clientname
                   label
                   reportconfig
                   icon
                 }
                 productLine
                 products
                 questions
                 promotionScheduler {
                   nextToken
                 }
                 photos
                 startdate
                 enddate
                 colours
               }
               user {
                 id
                 cognitoid
                 label
                 name
                 email
                 homelocation
                 type
                 access
                 schedule {
                   nextToken
                 }
                 phone
               }
               responses(limit:1000) {
                 items {
                   id
                   timestamp
                   response
                   label
                 }
                 nextToken
               }
               promotionPhotos(limit:1000)  {
                 items {
                   id
                   timestamp
                   filename
                   phototype
                   label
                 }
                 nextToken
               }
               id
               startdate
               enddate
               location
               gps
               photosdone
               signindone
               stocktakedone
               stocktakevalues
               standpicdone
               promoterpicdone
               otheritemsdone
               signoffmanagername
               signofftimestamp
               signoffmanageremail
               signoffmanagerphonenumber
               signoffmanagerrating
               signoffmanagercomments
               label
             }
             nextToken
           }
           phone
         }
       }
     `;

    let user = await API.graphql(
      graphqlOperation(getPromoterPlusUser, {
        id: id
      })
    );
    return user.data.getPromoterPlusUser;
  },
  async getAllUsers(filter) {
    let user = await API.graphql(
      graphqlOperation(queries.listPromoterPlusUsers, {
        limit: 10000,
        filter: filter
      })
    );
    //this.log(user)
    user.data.listPromoterPlusUsers.items.forEach(function(e) {
      e.email = e.email.replace("@promoterplus.com", "");
      e.name = e.name.replace("@promoterplus.com", "");
    });
    return user.data.listPromoterPlusUsers.items;
  },
  async getAllLabels() {
    let user = await API.graphql(
      graphqlOperation(queries.listPromoterPlusLabelss, { limit: 10000 })
    );
    return user.data.listPromoterPlusLabelss.items;
  },
  async getAllClients(filter) {
    // this.log(filter)
    let user = await API.graphql(
      graphqlOperation(queries.listPromoterPlusClients, {
        limit: 10000,
        filter: filter
      })
    );
    // this.log(user)
    return user.data.listPromoterPlusClients.items;
  },
  async getClientByID(id) {
    let user = await API.graphql(
      graphqlOperation(queries.getPromoterPlusClient, {
        id
      })
    );
    return user.data.getPromoterPlusClient;
  },
  async getScheduleById(id) {
    let user = await API.graphql(
      graphqlOperation(
        queries.getPromoterPlusSchedule
          .replace("responses", "responses(limit:1000)")
          .replace("promotionPhotos", "promotionPhotos(limit:100)"),
        { id: id }
      )
    );
    //this.log(user)
    return user.data.getPromoterPlusSchedule;
  },
  async getPhotoDataById(id) {
    let user = await API.graphql(
      graphqlOperation(queries.getPromoterPlusPhotos, {
        id: id
      })
    );
    if (user.data.getPromoterPlusPhotos.filename.indexOf("{key") > -1)
      user.data.getPromoterPlusPhotos.filename = user.data.getPromoterPlusPhotos.filename
        .replace("{key=", "")
        .replace("}", "");
    user.data.getPromoterPlusPhotos.picURI = await Storage.get(
      user.data.getPromoterPlusPhotos.filename
    );
    return user.data.getPromoterPlusPhotos;
  },
  async getAllProductLines(filter) {
    let user = await API.graphql(
      graphqlOperation(queries.listPromoterPlusProducts, {
        limit: 10000,
        filter: filter
      })
    );
    return user.data.listPromoterPlusProducts.items;
  },
  async createClient(input, label) {
    let user = await API.graphql(
      graphqlOperation(mutations.createPromoterPlusClient, {
        input: {
          clientname: input,
          label: label,
          consumablesetup:JSON.stringify({fixedAssetChips:[],consumableAssetChips:[]})
        }
      })
    );
    //    this.log(user);
    return user.data.createPromoterPlusClient;
  },
  // async createConsumeSetup(input) {
  // let user = await API.graphql(
  //   graphqlOperation(mutations.createPromoterPlusConsumeSetup, {
  //     input: {
  //       id: input.id,
  //       setup: input.setup,
  //       defaultConsumptionPattern: input.defaultConsumptionPattern
  //     }
  //   })
  // );
  // //    this.log(user);
  // return user.data.createPromoterPlusConsumeSetup;
  // },
  // async getPromoterPlusConsumeSetup(input) {
  // console.log(input)
  // try {
  //   let user = await API.graphql(
  //     graphqlOperation(
  //       queries.getPromoterPlusConsumeSetup.replace(
  //         "usage",
  //         "usage(limit:1)"
  //       ),
  //       {
  //         id: input
  //       }
  //     )
  //   );
  //   // console.log(user)
  //   if (user.data.getPromoterPlusConsumeSetup === null) {
  //     return {
  //       defaultConsumptionPattern: '["1","1","0","1"]',
  //       id: input,
  //       setup:
  //         '{"fixedAsset":["Table","Coffee Machine","Charger"],"consumables":["Cup","Spoon","BSugar","WSugar"]}'
  //     };
  //   }
  //   //    this.log(user);
  //   else return user.data.getPromoterPlusConsumeSetup;
  // } catch (err) {
  //   console.log(err);
  // }
  // },
  // async getPromoterConsumed(userID) {
  //   var user = await API.graphql(
  //     graphqlOperation(queries.listPromoterPlusConsumeds, {
  //       limit: 1000,
  //       filter: {
  //         userID: { eq: userID }
  //       }
  //     })
  //   );
  //
  //   return user.data.listPromoterPlusConsumeds;
  // },
  // async getPromoterConsumedAdjustments(userID) {
  //   var user = await API.graphql(
  //     graphqlOperation(queries.listPromoterPlusConsumeds, {
  //       limit: 1000,
  //       filter: {
  //         scheduleID: { eq: "adjustment" },
  //         userID: { eq: userID }
  //         // consumableID:{id:{eq:promotionid}}
  //       }
  //     })
  //   );
  //   return user.data.listPromoterPlusConsumeds;
  // },
  // async getConsumptionByUserIDAndPromoID(promoID, userID, nextToken) {
  //   async function getData(p, u, nt) {
  //     let getPromoterPlusConsumeSetup =
  //       `query test {
  //     getPromoterPlusConsumeSetup(id: "` +
  //       promoID +
  //       `") {
  //       id
  //       usage(limit:1000, nextToken:"` +
  //       nt +
  //       `",filter: {userID:{eq:"` +
  //       userID +
  //       `"}}) {
  //         items {
  //           id
  //           userID
  //           scheduleID
  //           timestamp
  //           usage
  //         }
  //         nextToken
  //       }
  //       setup
  //       defaultConsumptionPattern
  //     }
  //   }
  //   `;
  //
  //     if (nt === null)
  //       getPromoterPlusConsumeSetup =
  //         `query test {
  //     getPromoterPlusConsumeSetup(id: "` +
  //         promoID +
  //         `") {
  //       id
  //       usage(limit:1000, nextToken:null, filter: {userID:{eq:"` +
  //         userID +
  //         `"}}) {
  //         items {
  //           id
  //           userID
  //           scheduleID
  //           timestamp
  //           usage
  //         }
  //         nextToken
  //       }
  //       setup
  //       defaultConsumptionPattern
  //     }
  //   }
  //   `;
  //     try {
  //       var user = await API.graphql(
  //         graphqlOperation(getPromoterPlusConsumeSetup)
  //       );
  //       return user.data.getPromoterPlusConsumeSetup;
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }
  //   // console.log(nextToken)
  //   var data = await getData(promoID, userID, nextToken);
  //   // console.log(data);
  //   // console.log("this is running");
  //   if (typeof data !== "undefined") {
  //     let tkn = data.usage.nextToken;
  //     // console.log(tkn)
  //     while (tkn !== null) {
  //       var newdata = await getData(promoID, userID, tkn);
  //       tkn = newdata.usage.nextToken;
  //       data.usage.items = data.usage.items.concat(newdata.usage.items);
  //     }
  //     data.setup = JSON.parse(data.setup);
  //     data.defaultConsumptionPattern = JSON.parse(
  //       data.defaultConsumptionPattern
  //     );
  //     data.usage.items.forEach(z => (z.usage = JSON.parse(z.usage)));
  //     // console.log(data)
  //     return data;
  //   } else return null;
  // },

  async getConsumableDataNEW(input, nextToken) {
    let tkn = "";
    if (nextToken !== null) tkn = `nextToken:"` + nextToken + `"`;
    let getPromoterPlusClient =
      /* GraphQL */ `
      query GetPromoterPlusClient($id: ID!) {
        getPromoterPlusClient(id: $id) {
          consumableusage(limit:500,` +
      tkn +
      `) {
            items {
              userID
              usage
            }
            nextToken
          }
        }
      }
    `;
    var user = await API.graphql(
      graphqlOperation(getPromoterPlusClient, { id: input })
    );
    user.data.getPromoterPlusClient.consumableusage.items.forEach(
      z => (z.usage = JSON.parse(z.usage))
    );
    // console.log(user);
    return {
      data: user.data.getPromoterPlusClient.consumableusage.items,
      nextToken: user.data.getPromoterPlusClient.consumableusage.nextToken
    };
  },

  pivotConsumptionData(usage, input) {
    input.forEach(z => {
      JSON.parse(z.usage).forEach(x => {
        let found = usage.find(a => a.item === x.item);
        if (!found)
          usage.push({
            item: x.item,
            usage: [
              {
                amount: parseInt(x.amount),
                timestamp: z.timestamp.substr(0, 10),
                scheduleID: z.scheduleID === "adjust" ? "adjust" : "consume"
              }
            ]
          });
        else {
          found.usage.push({
            amount: parseInt(x.amount),
            timestamp: z.timestamp.substr(0, 10),
            scheduleID: z.scheduleID === "adjust" ? "adjust" : "consume"
          });
        }
      });
    });
    function compact(input) {
      let output = [];
      input.forEach(z => {
        let found = output.find(
          a => a.scheduleID === z.scheduleID && a.timestamp === z.timestamp
        );
        if (!found)
          output.push({
            scheduleID: z.scheduleID,
            timestamp: z.timestamp,
            amount: z.amount
          });
        else {
          found.amount += z.amount;
        }
      });
      return output;
    }
    usage.forEach(z => {
      z.usage = compact(z.usage);
    });
    return usage;
  },
  async getConsumableDataByUserID(input, nextToken) {
    let listPromoterPlusConsumeds =
      `
      query test
       {
        listPromoterPlusConsumeds(
          QQQ
          filter:{userID:{eq:"` +   input +      `"}}
          limit: 500
        ) {
          items {
            usage
            timestamp
            scheduleID
          }
          nextToken
        }
      }
    `;
    if (nextToken === null)
      listPromoterPlusConsumeds = listPromoterPlusConsumeds.replace("QQQ", "");
    else
      listPromoterPlusConsumeds = listPromoterPlusConsumeds.replace(
        "QQQ",
        `nextToken:"` + nextToken + `"`
      );
    try {
      let user = await API.graphql(graphqlOperation(listPromoterPlusConsumeds));
      return user.data.listPromoterPlusConsumeds;
    } catch (err) {
      console.log(err);
    }
  },
  async getConsumableData(input, nextToken) {
    try {
      let getPromoterPlusConsumeSetup =
        `query test {
        getPromoterPlusConsumeSetup(id: "` +
        input +
        `") {
          usage(limit:1000, nextToken:"` +
        nextToken +
        `") {
            items {
              id
              userID
              scheduleID
              timestamp
              usage
            }

            nextToken
          }
          setup
          defaultConsumptionPattern
        }
      }
      `;
      if (nextToken === null) {
        getPromoterPlusConsumeSetup =
          `query test {
    getPromoterPlusConsumeSetup(id: "` +
          input +
          `") {
      usage(limit:1000, nextToken:` +
          nextToken +
          `) {
        items {
          id
          userID
          scheduleID
          timestamp
          usage
        }

        nextToken
      }
      setup
      defaultConsumptionPattern
    }
  }
  `;
      }

      var user = await API.graphql(
        graphqlOperation(getPromoterPlusConsumeSetup)
      );
      // console.log(user);
      user.data.getPromoterPlusConsumeSetup.defaultConsumptionPattern = JSON.parse(
        user.data.getPromoterPlusConsumeSetup.defaultConsumptionPattern
      );
      user.data.getPromoterPlusConsumeSetup.defaultConsumptionPattern.forEach(
        z => (z = parseInt(z))
      );
      user.data.getPromoterPlusConsumeSetup.setup = JSON.parse(
        user.data.getPromoterPlusConsumeSetup.setup
      );
      user.data.getPromoterPlusConsumeSetup.usage.items.forEach(
        z => (z.usage = JSON.parse(z.usage))
      );
      user.data.getPromoterPlusConsumeSetup.usage.items.forEach(z => {
        z.usage.forEach(a => (a.amount = parseInt(a.amount)));
      });
      return user.data.getPromoterPlusConsumeSetup;
    } catch (err) {
      console.log(err);
    }
  },
  async updateConsumeSetup(input) {
    try {
      let user = await API.graphql(
        graphqlOperation(mutations.updatePromoterPlusClient, {
          input: {
            id: input.id,
            consumablesetup: JSON.stringify(input.consumablesetup)
          }
        })
      );
      //    this.log(user);
      return user.data.updatePromoterPlusClient;
    } catch (err) {
      return err;
    }
  },
  async createLabel(input) {
    let user = await API.graphql(
      graphqlOperation(mutations.createPromoterPlusLabels, {
        input: {
          label: input,
          config: " "
        }
      })
    );
    //    this.log(user);
    return user.data.createPromoterPlusLabels;
  },
  async updateLabel(input) {
    let user = await API.graphql(
      graphqlOperation(mutations.updatePromoterPlusLabels, {
        input: {
          id: input.id,
          config: input.config
        }
      })
    );
    //    this.log(user);
    return user.data.updatePromoterPlusLabels;
  },
  async deleteClient(input) {
    let user = await API.graphql(
      graphqlOperation(mutations.deletePromoterPlusClient, {
        input: {
          id: input
        }
      })
    );
    //    this.log(user);
    return user.data.deletePromoterPlusClient;
  },
  async deleteLabel(input) {
    let user = await API.graphql(
      graphqlOperation(mutations.deletePromoterPlusLabels, {
        input: {
          id: input
        }
      })
    );
    //    this.log(user);
    return user.data.deletePromoterPlusLabels;
  },
  async createPromoterPlusProduct(
    inputProductLine,
    inputProducts,
    label,
    clientid
  ) {
    let user = await API.graphql(
      graphqlOperation(mutations.createPromoterPlusProduct, {
        input: {
          productline: inputProductLine,
          products: inputProducts,
          label: label,
          clientid: clientid
        }
      })
    );
    //    this.log(user);
    return user.data.createPromoterPlusProduct;
  },
  async deletePromoterPlusProduct(input) {
    let user = await API.graphql(
      graphqlOperation(mutations.deletePromoterPlusProduct, {
        input: {
          id: input
        }
      })
    );
    //    this.log(user);
    return user.data.deletePromoterPlusProduct;
  },
  async createPromotion(input) {
    let user = await API.graphql(
      graphqlOperation(mutations.createPromoterPlusPromotion, {
        input: {
          promotionName: input.promotionName,
          promoterPlusPromotionClientId: input.promoterPlusPromotionClientId,
          productLine: input.productline,
          products: input.products,
          questions: input.questions,
          photos: input.photos,
          colours: input.colours,
          startdate: input.startDate,
          enddate: input.endDate,
          label: input.label
        }
      })
    );
    //    this.log(user);
    return user.data.createPromoterPlusPromotion;
  },
  async createPromotionAndSchedules(input) {
    try {
      let user = await API.graphql(
        graphqlOperation(mutations.createPromoterPlusPromotion, {
          input: {
            promotionName: input.promotionName,
            promoterPlusPromotionClientId: input.promoterPlusPromotionClientId,
            promotionScheduler: input.promotionScheduler,
            productLine: input.productline,
            products: input.products,
            questions: input.questions,
            colours: input.colours,
            startdate: input.startDate,
            enddate: input.endDate,
            label: input.label
          }
        })
      );
      //    this.log(user);
      return user.data.createPromoterPlusPromotion;
    } catch (err) {
      console.log(err);
    }
  },
  async updatePromotion(input) {
    let user = await API.graphql(
      graphqlOperation(
        mutations.updatePromoterPlusPromotion.replace(
          "promotionScheduler",
          "promotionScheduler(limit:200)"
        ),
        {
          input: {
            id: input.id,
            promotionName: input.promotionName,
            client: input.client,
            productLine: input.productline,
            products: input.products,
            questions: input.questions,
            photos: input.photos,
            colours: input.colours,
            startdate: input.startDate,
            enddate: input.endDate
          }
        }
      )
    );
    //    this.log(user);
    return user.data.updatePromoterPlusPromotion;
  },
  async switchUserAccess(input) {
    try {
      let user = await API.graphql(
        graphqlOperation(
          mutations.updatePromoterPlusUser,
          {
            input:{
              id:input.id,
              label:input.label
            }
          }
        )
      );
      return user.data.updatePromoterPlusUser;
    } catch (err) {
      console.log(err)
    }
  },
  async createPromotionSchedule(input) {
    // console.log("got util")
    try {
      let user = await API.graphql(
        graphqlOperation(mutations.createPromoterPlusSchedule, {
          input: {
            promoterPlusSchedulePromotionIDId: input.promotionid,
            promoterPlusScheduleUserId: input.promoterId,
            startdate: input.promoterStartDate,
            enddate: input.promoterEndDate,
            location: input.promoterLocation
          }
        })
      );
      // console.log(user);
      return user.data.createPromoterPlusSchedule;
    } catch (err) {
      console.log(err);
    }
  },
  createPromotionScheduleNORETURN(input) {
    // console.log("got util")
    try {
      API.graphql(
        graphqlOperation(mutations.createPromoterPlusSchedule, {
          input: {
            promoterPlusSchedulePromotionIDId: input.promotionid,
            promoterPlusScheduleUserId: input.promoterId,
            startdate: input.promoterStartDate,
            enddate: input.promoterEndDate,
            location: input.promoterLocation
          }
        })
      );
      // console.log(user);
      // return user.data.createPromoterPlusSchedule;
    } catch (err) {
      console.log(err);
    }
  },
  async BULKcreatePromotionSchedule(input) {
    // console.log("got util")
    try {
      let user = await API.graphql(
        graphqlOperation(mutations.createPromoterPlusSchedule, {
          input: {
            promoterPlusSchedulePromotionIDId: input.promotionid,
            promoterPlusScheduleUserId: input.promoterId,
            startdate: input.promoterStartDate,
            enddate: input.promoterEndDate,
            location: input.promoterLocation,
            signoffmanagername: input.signoffmanagername,
            signoffmanageremail: input.signoffmanageremail,
            signofftimestamp: input.signofftimestamp,
            signoffmanagerphonenumber: input.signoffmanagerphonenumber,
            signoffmanagerrating: input.signoffmanagerrating,
            signoffmanagercomments: input.signoffmanagercomments
          }
        })
      );
      // console.log(user);
      return user.data.createPromoterPlusSchedule;
    } catch (err) {
      console.log(err);
    }
  },
  async updatePromotionSchedule(input) {
    try {
      let user = await API.graphql(
        graphqlOperation(mutations.updatePromoterPlusSchedule, {
          input: {
            id: input.id,
            promoterPlusSchedulePromotionIDId: input.promotionid,
            promoterPlusScheduleUserId: input.promoterId,
            startdate: input.promoterStartDate,
            enddate: input.promoterEndDate,
            location: input.promoterLocation
          }
        })
      );
      // console.log(user);
      return user.data.updatePromoterPlusSchedule;
    } catch (err) {
      console.log(err);
    }
  },
  async REPAIRupdatePromotionSchedule(input) {
    try {
      let user = await API.graphql(
        graphqlOperation(mutations.updatePromoterPlusSchedule, {
          input: {
            id: input.id,

            startdate: input.startdate,
            enddate: input.enddate,
            location: input.location
          }
        })
      );
      console.log(user);
      return user.data.updatePromoterPlusSchedule;
    } catch (err) {
      console.log(err);
    }
  },
  async deletePromoterPlusSchedule(input) {
    let user = await API.graphql(
      graphqlOperation(mutations.deletePromoterPlusSchedule, {
        input: {
          id: input
        }
      })
    );
    //    this.log(user);
    return user.data.deletePromoterPlusSchedule;
  },
  async deletePromoterPlusPromotion(input) {
    let user = await API.graphql(
      graphqlOperation(mutations.deletePromoterPlusPromotion, {
        input: {
          id: input
        }
      })
    );
    //    this.log(user);
    return user.data.deletePromoterPlusPromotion;
  },
  async getPromotionByScheduleId(id) {
    await Utils.isFastInternet();
    // console.log("here again");
    var speed = await Utils.isFastInternet();
    // console.log(speed);
    try {
      if (!navigator.onLine || !speed) {
        // console.log(await this.isFastInternet())
        return JSON.parse(
          localStorage.getItem("getPromotionByScheduleId" + id)
        );
      } else {
        let user = await API.graphql(
          graphqlOperation(
            queries.getPromoterPlusSchedule
              .replace("responses", "responses(limit:1000)")
              .replace("promotionPhotos", "promotionPhotos(limit:100)"),
            {
              id: id
            }
          )
        );
        // console.log(user.data.getPromoterPlusSchedule);
        // let oo = await Utils.getPromoterPlusConsumeSetup(
        //   user.data.getPromoterPlusSchedule.promotionID.id
        // );
        // console.log(oo);
        // user.data.getPromoterPlusSchedule.consumableSetup = oo;
        localStorage.setItem(
          "getPromotionByScheduleId" + id,
          JSON.stringify(user.data.getPromoterPlusSchedule)
        );
        return user.data.getPromoterPlusSchedule;
      }
    } catch (err) {
      Utils.log(err);
    }
  },
  async createPromotionPhoto(input) {
    // this.log(input);
    let user = await API.graphql(
      graphqlOperation(mutations.createPromoterPlusPhotos, {
        input: {
          promoterPlusPhotosScheduleId: input.promoterPlusPhotosScheduleId,
          promoterPlusPhotosUserId: input.userid,
          timestamp: input.timestamp,
          filename: input.filename,
          phototype: input.phototype
        }
      })
    );
    // this.log(user);
    return user.data.createPromoterPlusPhotos;
  },
  async createConsumeResponse(input) {
    // this.log(input);
    let user = await API.graphql(
      graphqlOperation(mutations.createPromoterPlusConsumed, {
        input: {
          userID: input.userID,
          scheduleID: input.scheduleID,
          timestamp: input.timestamp,
          usage: input.usage,
          promoterPlusConsumedConsumableIDId:
            input.promoterPlusConsumedConsumableIDId
        }
      })
    );
    // this.log(user);
    return user.data.createPromoterPlusConsumed;
  },
  async turnOnSchedulePhotoByScheduleId(input, type) {
    let user = [];
    // console.log("tag 3 ");
    // this.log(input);
    // this.log(type);
    if (type === "Sign in") {
      user = await API.graphql(
        graphqlOperation(mutations.updatePromoterPlusSchedule, {
          input: { id: input, signindone: true }
        })
      );
    }
    if (type === "Stand") {
      user = await API.graphql(
        graphqlOperation(mutations.updatePromoterPlusSchedule, {
          input: { id: input, standpicdone: true }
        })
      );
    }
    if (type === "Promoter") {
      user = await API.graphql(
        graphqlOperation(mutations.updatePromoterPlusSchedule, {
          input: { id: input, promoterpicdone: true }
        })
      );
    }
    if (type === "Other photos") {
      user = await API.graphql(
        graphqlOperation(mutations.updatePromoterPlusSchedule, {
          input: { id: input, otheritemsdone: true }
        })
      );
    }
    // console.log("tag 4 ");
    // this.log(user);
    return user.data.updatePromoterPlusSchedule;
  },
  async createPromotionStockTake(input) {
    let user = await API.graphql(
      graphqlOperation(mutations.updatePromoterPlusSchedule, {
        input: {
          id: input.id,
          stocktakedone: true,
          stocktakevalues: input.stocktakevalues
        }
      })
    );
    //    this.log(user);
    return user.data.updatePromoterPlusSchedule;
  },
  addOffLineDataStore(func, data) {
    var temp = JSON.parse(localStorage.getItem(func));
    if (!temp) {
      temp = [data];
    } else {
      temp.push(data);
    }
    var count = JSON.parse(localStorage.getItem("cacheCount"));
    if (!count) count = 1;
    else count = parseInt(count) + 1;
    localStorage.setItem("cacheCount", count);

    localStorage.setItem(func, JSON.stringify(temp));
    return temp.length;
  },
  async cleanupOfflineData(func) {
    var temp = JSON.parse(localStorage.getItem(func));
    if (temp) {
      // this.log("GOT HERE");
      // this.log(temp);
      // this.log(func);
      localStorage.removeItem(func);
      var count = JSON.parse(localStorage.getItem("cacheCount"));
      if (!count) count = 1;
      else count = parseInt(count) - temp.length;
      if (count < 0) count = 0;
      localStorage.setItem("cacheCount", count);
      const promises = temp.map(this[func]);
      await Promise.all(promises);
      // this.log(cl);
    }
    temp = JSON.parse(localStorage.getItem(func));
    var counter = 0;
    if (temp) counter = temp.length;
    return counter;
  },
  async createPromotionResponse(input) {
    // try{
    // console.log("input")
    // console.log(input)
    let user = await API.graphql(
      graphqlOperation(mutations.createPromoterPlusResponses, {
        input: {
          promoterPlusResponsesScheduleId: input.scheduleID,
          promoterPlusResponsesUserId: input.userid,
          timestamp: input.timestamp,
          response: input.response
        }
      })
    );
    // console.log("output")
    // console.log(user)
    return user.data.createPromoterPlusResponses;
    // } catch(err) {
    //   console.log(err)
    // }
  },
  async signOffByScheduleId(input) {
    let user = await API.graphql(
      graphqlOperation(mutations.updatePromoterPlusSchedule, {
        input: {
          id: input.id,
          signoffmanagername: input.signoffmanagername,
          signoffmanageremail: input.signoffmanageremail,
          signofftimestamp: input.signofftimestamp,
          signoffmanagerphonenumber: input.signoffmanagerphonenumber,
          signoffmanagerrating: input.signoffmanagerrating,
          signoffmanagercomments: input.signoffmanagercomments
        }
      })
    );
    //    this.log(user);
    return user.data.updatePromoterPlusSchedule;
  },
  async getAllPromotions(filter) {
    const listPromoterPlusPromotions = `query ListPromoterPlusPromotions(
      $filter: ModelPromoterPlusPromotionFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listPromoterPlusPromotions(
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          colours
          label
          startdate
          enddate
          promotionScheduler {
            items {

              location
              user {

                name

              }
            }
            nextToken
          }
            products
              promotionName
              client {
                id
                clientname

              }
      }
        nextToken
      }
    }
    `;

    let user = await API.graphql(
      graphqlOperation(listPromoterPlusPromotions, {
        limit: 10000,
        filter: filter
      })
    );
    //this.log(user)
    return user.data.listPromoterPlusPromotions.items;
  },
  async getPromotionByPromotionId(input) {
    let user = await API.graphql(
      graphqlOperation(
        queries.getPromoterPlusPromotion.replace(
          "promotionScheduler",
          "promotionScheduler(limit:3000)"
        ),
        { id: input }
      )
    );
    return user.data.getPromoterPlusPromotion;
  },
  async getPromotionByPromotionIdNEW(input) {
    // console.log(input)
    const getPromoterPlusPromotion =
      `query test {
      getPromoterPlusPromotion(id: "` +
      input +
      `") {
        id
        label
        promotionName
        client {
          id
          clientname
          label
          promotion {
            nextToken
          }
          reportconfig
          icon
        }
        productLine
        products
        questions
        promotionScheduler(limit:5000) {
          items {
              user {
                id
                name
              }
            id
            startdate
            enddate
            location
            gps


          }
          nextToken
        }
        photos
        startdate
        enddate
        colours
      }
    }
    `;
    try {
      let user = await API.graphql(graphqlOperation(getPromoterPlusPromotion));
      // console.log(JSON.stringify(user).length)
      return user.data.getPromoterPlusPromotion;
    } catch (err) {
      console.log(err);
    }
  },
  async getPromotionByPromotionIdNEW2(input) {
    // console.log(input)
    const getPromoterPlusPromotion =
      `query test {
      getPromoterPlusPromotion(id: "` +
      input +
      `") {
        id
        label
        promotionName
        client {
          id
          clientname
          label
          promotion {
            nextToken
          }
          reportconfig
          icon
        }
        productLine
        products
        questions
        promotionScheduler(limit:5000) {
          items {
              user {
                id
                name
              }
            id
            responses {
              items{

                response
              }
              nextToken
            }
            startdate
            enddate
            location
            gps


          }
          nextToken
        }
        photos
        startdate
        enddate
        colours
      }
    }
    `;
    try {
      let user = await API.graphql(graphqlOperation(getPromoterPlusPromotion));
      // console.log(JSON.stringify(user).length)
      return user.data.getPromoterPlusPromotion;
    } catch (err) {
      console.log(err);
    }
  },
  async saveFAQPicToS3(packet) {
    // this.log(packet);
    let s3 = await Storage.put(
      packet.filename,

      Buffer.from(packet.picture, "base64"),
      {
        contentType: "image/jpeg"
      }
    );

    return s3;
  },
  async updateFAQofProduct(input) {
    let user = await API.graphql(
      graphqlOperation(mutations.updatePromoterPlusProduct, {
        input: {
          id: input.id,
          faqpdf: input.faqpdf,
          faqicon: input.faqicon,
          faqtext: input.faqtext
        }
      })
    );
    return user.data.updatePromoterPlusProduct;
  },
  async updateProductInProductLine(input) {
    let user = await API.graphql(
      graphqlOperation(mutations.updatePromoterPlusProduct, {
        input: {
          id: input.id,
          products: input.products,
          clientid: input.clientid
        }
      })
    );
    return user.data.updatePromoterPlusProduct;
  },
  async savePicToS3(packet) {
    try {
      let s3 = await Storage.put(
        packet.filename,
        Buffer.from(
          //packet.picture.replace(/^data:image\/\w+;base64,/, ""),
          packet.picture.split(",")[1],
          "base64"
        ),
        {
          contentType: packet.picture
            .split(",")[0]
            .split(";")[0]
            .split(":")[1]
        }
      );
      if (s3) {
        let t2 = await Utils.createPromotionPhoto(packet);
        if (t2) return true;
        return true;
      }
    } catch (err) {
      return false;
    }
  },
  async getPicFromS3(filename) {
    let s3 = await Storage.get(filename);
    return s3;
  },
  async getPicFromS3v2(input) {
    let s3 = await Storage.get(input.filename);
    var a = await fetch(s3);
    var b = await a.blob();
    input.blob = b;
    return input;
  },
  async updateLastGeoTag(input) {
    let user = await API.graphql(
      graphqlOperation(mutations.updatePromoterPlusSchedule, {
        input: {
          id: input.id,
          gps: input.lastgeotag
        }
      })
    );
    return user.data.updatePromoterPlusSchedule;
  },
  async updatesignoffLastGeoTag(input) {
    let user = await API.graphql(
      graphqlOperation(mutations.updatePromoterPlusSchedule, {
        input: {
          id: input.scheduleID,
          signoffgps: input.lastgeotag
        }
      })
    );
    return user.data.updatePromoterPlusSchedule;
  },
  async saveDesign(input) {
    let user = await API.graphql(
      graphqlOperation(mutations.updatePromoterPlusClient, {
        input: {
          id: input.id,
          reportconfig: input.reportconfig
        }
      })
    );
    return user.data.updatePromoterPlusClient;
  },
  async saveClientIcon(input) {
    let user = await API.graphql(
      graphqlOperation(mutations.updatePromoterPlusClient, {
        input: {
          id: input.id,
          icon: input.icon
        }
      })
    );
    return user.data.updatePromoterPlusClient;
  },
  async getUserByResponseId(input) {
    const getPromoterPlusResponses = `query GetPromoterPlusResponses($id: ID!) {
      getPromoterPlusResponses(id: $id) {
        id          user {          id        }        timestamp            }    }    `;
    let user = await API.graphql(
      graphqlOperation(getPromoterPlusResponses, { id: input })
    );
    return {
      ResponseID: user.data.getPromoterPlusResponses.id,
      UserID: user.data.getPromoterPlusResponses.user.id
    };
  },
  removeDuplicates(input) {
    var temp = new Set(input);
    input = [...temp];
    return input;
  },
  async deleteResponse(input) {
    let user = await API.graphql(
      graphqlOperation(mutations.deletePromoterPlusResponses, {
        input: { id: input }
      })
    );
    return user.data.deletePromoterPlusResponses;
  },
  async deleteuser(input) {
    let user = await API.graphql(
      graphqlOperation(mutations.deletePromoterPlusUser, {
        input: { id: input }
      })
    );
    return user.data.deletePromoterPlusUser;
  },
  getIDlist(arr) {
    var output = [];
    arr.forEach(function(e) {
      output.push(e.id);
    });
    return output;
  },
  async getsummaryByID(input) {
    console.log("here1");
    const getPromoterPlusPromotion = `query GetPromoterPlusPromotion($id: ID!) {
    getPromoterPlusPromotion(id: $id) {
      id
      label
      promotionName
      client {
        id
        clientname
        label
        promotion {
          nextToken
        }
      }
      productLine
      products
      questions
      promotionScheduler(limit:100) {
        items {
          id
          startdate
          enddate
            promotionID {
              client {
                clientname
              }
            }
          location
          gps
          signindone
          stocktakedone
          stocktakevalues
          standpicdone
          promoterpicdone
          otheritemsdone
          signoffmanagername
          signofftimestamp
          user {
            id
            name
          }
          signoffmanageremail
          signoffmanagerphonenumber
          signoffmanagerrating
          signoffmanagercomments
          label
          promotionPhotos(limit:20) {
            items {
              id
              timestamp
              filename
              phototype
              label
            }
            nextToken
          }
          responses(limit:10000) {
            items {
              id
              timestamp
              response
              label

            }
            nextToken
          }
        }
        nextToken
      }
      startdate
      enddate
      colours
    }
  }
  `;
    let user = await API.graphql(
      graphqlOperation(getPromoterPlusPromotion, { id: input, limit: 1000 })
    );

    return user.data.getPromoterPlusPromotion;
  },
  async getAllPromotions2(filter) {
    const listPromoterPlusPromotions = `query ListPromoterPlusPromotions(
      $filter: ModelPromoterPlusPromotionFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listPromoterPlusPromotions(
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          label
          client {
            id
            clientname
            label
            promotion {
              nextToken
            }
          }
      }
        nextToken
      }
    }
    `;

    var clientfilter = "";
    var dates = filter.cdates;
    delete filter.cdates;
    if (typeof filter.client !== "undefined") {
      clientfilter = filter.client.id;
      delete filter.client;
    }

    var user = await API.graphql(
      graphqlOperation(listPromoterPlusPromotions, {
        limit: 1000,
        filter: filter
      })
    );

    filter.cdates = dates;
    if (clientfilter !== "")
      user.data.listPromoterPlusPromotions.items = user.data.listPromoterPlusPromotions.items.filter(
        usr1 => usr1.client.id === clientfilter.eq
      );

    const getPromoterPlusPromotion = `query GetPromoterPlusPromotion($id: ID!) {
    getPromoterPlusPromotion(id: $id) {
      id
      label
      promotionName
      photos
      client {
        id
        clientname
        label
        promotion {
          nextToken
        }
      }
      productLine
      products
      questions
      promotionScheduler(limit:500) {
        items {
          id
          startdate
          enddate

        }
        nextToken
      }
      startdate
      enddate
      colours
    }
  }
  `;

    async function getpromodetail(id) {
      // try {
      var pro = await API.graphql(
        graphqlOperation(getPromoterPlusPromotion, { id: id })
      );
      // console.log(pro);

      return pro.data.getPromoterPlusPromotion;
      // } catch (err) {
      //   console.log(err);
      // }
    }

    var promosdetail = await Promise.all(
      user.data.listPromoterPlusPromotions.items
        .map(a => a.id)
        .map(getpromodetail)
    );
    // console.log("promosdetail");
    // var tester = JSON.parse( JSON.stringify(promosdetail,null,4))
    // console.log(tester);
    promosdetail = promosdetail.map(item => {
      item.promotionScheduler.items = item.promotionScheduler.items.filter(
        scheds => new Date(scheds.startdate) >= filter.cdates.fdt
      );
      item.promotionScheduler.items = item.promotionScheduler.items.filter(
        scheds => new Date(scheds.enddate) >= filter.cdates.fdt
      );
      item.promotionScheduler.items = item.promotionScheduler.items.filter(
        scheds => new Date(scheds.startdate) <= filter.cdates.ldt
      );
      item.promotionScheduler.items = item.promotionScheduler.items.filter(
        scheds => new Date(scheds.enddate) <= filter.cdates.ldt
      );
      return item;
    });

    promosdetail = promosdetail.filter(
      a => a.promotionScheduler.items.length > 0
    );
    // console.log("promosdetail2");
    // var tester = JSON.parse( JSON.stringify(promosdetail,null,4))
    // console.log(tester);

    const getPromoterPlusSchedule = `query GetPromoterPlusSchedule($id: ID!) {
        getPromoterPlusSchedule(id: $id) {
            id
            promotionID {
                id
                label
                promotionName
                photos
                client {
                    id
                    clientname
                    label
                    reportconfig
                  }
                  productLine
                  products
                  questions

                  startdate
                  enddate
                  colours
                }
                user {
                    id
                    cognitoid
                    label
                    name
                    email
                    type
                    access
                    schedule {
                        nextToken
                      }
                    }
                    responses(limit:1000) {
                        items {
                            id
                            timestamp
                            response
                            label
                          }
                          nextToken
                        }
                        promotionPhotos(limit:100) {
                            items {
                                id
                                timestamp
                                filename
                                phototype
                                label
                              }
                              nextToken
                            }
                            startdate
                            enddate
                            photosdone
                            location
                            gps
                            signindone
                            stocktakedone
                            stocktakevalues
                            standpicdone
                            promoterpicdone
                            otheritemsdone
                            signoffmanagername
                            signofftimestamp
                            signoffmanageremail
                            signoffmanagerphonenumber
                            signoffmanagerrating
                            signoffmanagercomments
                            label
                          }
                        }
                        `;

    var schedsids = [];
    promosdetail.forEach(p1 =>
      p1.promotionScheduler.items.forEach(p2 => schedsids.push(p2.id))
    );

    async function getscheduledetail(id) {
      try {
        var pro = await API.graphql(
          graphqlOperation(getPromoterPlusSchedule, { id: id })
        );
        // console.log(pro)
        return pro.data.getPromoterPlusSchedule;
      } catch (err) {
        console.log(err);
      }
    }

    var individualSchedulesDetail = await Promise.all(
      schedsids.map(getscheduledetail)
    );
    // console.log(individualSchedulesDetail);
    promosdetail = promosdetail.map(promo => {
      promo.promotionScheduler.items = promo.promotionScheduler.items.map(
        sched => {
          return individualSchedulesDetail.find(indiv => indiv.id === sched.id);
        }
      );
      return promo;
    });

    var pline = await this.getAllProductLines();

    promosdetail.forEach(function(a) {
      pline.forEach(plines => {
        if (a.productLine === plines.id) {
          // console.log("match");
          a.products = plines.products.replace(/, /g, ",").split(",");
          var parr = [];
          a.products.forEach(apr => {
            parr.push(apr.trim());
          });
          a.products = parr;
        }
      });
      delete a.questions;

      a.enddate = new Date(a.enddate);
      a.enddate.setHours(a.enddate.getHours() - 2);
      a.startdate = new Date(a.startdate);
      a.startdate.setHours(a.startdate.getHours() - 2);
      a.promotionScheduler.items.forEach(function(b) {
        pline.forEach(function(pl) {
          if (pl.id === b.promotionID.productLine)
            b.promotionID.productLine = pl;
        });
        b.enddate = new Date(b.enddate);
        b.enddate.setHours(b.enddate.getHours() - 2);

        b.startdate = new Date(b.startdate);

        b.startdate.setHours(b.startdate.getHours() - 2);
        // console.log(b.location )
        b.location = JSON.parse(b.location);
        if (b.location.address === "") b.location.address = "Blank,Blank";
        var locations = b.location.address.split(",");
        b.location.region = locations[locations.length - 2].trim();
        if (b.gps === null) b.gps = null;
        else {
          b.gps = b.gps.split(",");
          var newgps = {};

          newgps.date = new Date(b.gps[0].replace("{date=", ""));
          newgps.date.setHours(newgps.date.getHours() - 2);
          newgps.geo = {
            lat: parseFloat(b.gps[1].replace(" geo={lat=", "")),
            lng: parseFloat(b.gps[2].replace(" lng=", ""))
          };
          b.gps = newgps;
        }
        b.responses.items.forEach(function(c) {
          c.timestamp = new Date(c.timestamp);
          c.timestamp.setHours(c.timestamp.getHours() - 2);
          c.response = JSON.parse(c.response);
          c.response.forEach(function(resitm) {
            delete resitm.items;
          });
        });
        if (b.stocktakevalues !== null) {
          b.stocktakevalues = JSON.parse(b.stocktakevalues);
          b.stocktakevalues.forEach(function(d) {
            d.count = parseInt(d.count);
            if (isNaN(d.count)) d.count = 0;
          });
        }
      });
    });

    return promosdetail;
  },
  async getAllPromotions3(filter) {
    const listPromoterPlusPromotions = `query ListPromoterPlusPromotions(
      $filter: ModelPromoterPlusPromotionFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listPromoterPlusPromotions(
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          label
          client {
            id
            clientname
            label
            promotion {
              nextToken
            }
          }
      }
        nextToken
      }
    }
    `;

    var clientfilter = "";
    var dates = filter.cdates;
    delete filter.cdates;
    if (typeof filter.client !== "undefined") {
      clientfilter = filter.client.id;
      delete filter.client;
    }

    var user = await API.graphql(
      graphqlOperation(listPromoterPlusPromotions, {
        limit: 1000,
        filter: filter
      })
    );

    filter.cdates = dates;
    if (clientfilter !== "")
      user.data.listPromoterPlusPromotions.items = user.data.listPromoterPlusPromotions.items.filter(
        usr1 => usr1.client.id === clientfilter.eq
      );

    const getPromoterPlusPromotion = `query GetPromoterPlusPromotion($id: ID!) {
    getPromoterPlusPromotion(id: $id) {
      id
      label
      promotionName
      photos
      client {
        id
        clientname
        label
        promotion {
          nextToken
        }
      }
      productLine
      products
      questions
      promotionScheduler(limit:1000) {
        items {
          id
          startdate
          enddate

        }
        nextToken
      }
      startdate
      enddate
      colours
    }
  }
  `;

    async function getpromodetail(id) {
      // try {
      var pro = await API.graphql(
        graphqlOperation(getPromoterPlusPromotion, { id: id })
      );
      // console.log(pro);

      return pro.data.getPromoterPlusPromotion;
      // } catch (err) {
      //   console.log(err);
      // }
    }

    var promosdetail = await Promise.all(
      user.data.listPromoterPlusPromotions.items
        .map(a => a.id)
        .map(getpromodetail)
    );
    // console.log("promosdetail");
    // var tester = JSON.parse( JSON.stringify(promosdetail,null,4))
    // console.log(tester);
    promosdetail = promosdetail.map(item => {
      item.promotionScheduler.items = item.promotionScheduler.items.filter(
        scheds => new Date(scheds.startdate) >= filter.cdates.fdt
      );
      item.promotionScheduler.items = item.promotionScheduler.items.filter(
        scheds => new Date(scheds.enddate) >= filter.cdates.fdt
      );
      item.promotionScheduler.items = item.promotionScheduler.items.filter(
        scheds => new Date(scheds.startdate) <= filter.cdates.ldt
      );
      item.promotionScheduler.items = item.promotionScheduler.items.filter(
        scheds => new Date(scheds.enddate) <= filter.cdates.ldt
      );
      return item;
    });

    promosdetail = promosdetail.filter(
      a => a.promotionScheduler.items.length > 0
    );
    // console.log("promosdetail2");
    // var tester = JSON.parse( JSON.stringify(promosdetail,null,4))
    // console.log(tester);

    const getPromoterPlusSchedule = `query GetPromoterPlusSchedule($id: ID!) {
        getPromoterPlusSchedule(id: $id) {
            id
            promotionID {
                id
                label
                promotionName
                photos
                client {
                    id
                    clientname
                    label
                    reportconfig
                  }
                  productLine
                  products
                  questions

                  startdate
                  enddate
                  colours
                }
                user {
                    id
                    cognitoid
                    label
                    name
                    email
                    type
                    access
                    schedule {
                        nextToken
                      }
                    }
                    responses(limit:1000) {
                        items {
                            id
                            timestamp
                            response
                            label
                          }
                          nextToken
                        }
                        promotionPhotos(limit:100) {
                            items {
                                id
                                timestamp
                                filename
                                phototype
                                label
                              }
                              nextToken
                            }
                            startdate
                            enddate
                            photosdone
                            location
                            gps
                            signindone
                            stocktakedone
                            stocktakevalues
                            standpicdone
                            promoterpicdone
                            otheritemsdone
                            signoffmanagername
                            signoffgps
                            signofftimestamp
                            signoffmanageremail
                            signoffmanagerphonenumber
                            signoffmanagerrating
                            signoffmanagercomments
                            label
                          }
                        }
                        `;

    var schedsids = [];
    promosdetail.forEach(p1 =>
      p1.promotionScheduler.items.forEach(p2 => schedsids.push(p2.id))
    );

    async function getscheduledetail(id) {
      try {
        var pro = await API.graphql(
          graphqlOperation(getPromoterPlusSchedule, { id: id })
        );
        // console.log(pro)
        return pro.data.getPromoterPlusSchedule;
      } catch (err) {
        console.log(err);
      }
    }

    var individualSchedulesDetail = await Promise.all(
      schedsids.map(getscheduledetail)
    );
    // console.log(individualSchedulesDetail);
    promosdetail = promosdetail.map(promo => {
      promo.promotionScheduler.items = promo.promotionScheduler.items.map(
        sched => {
          return individualSchedulesDetail.find(indiv => indiv.id === sched.id);
        }
      );
      return promo;
    });

    var pline = await this.getAllProductLines();

    promosdetail.forEach(function(a) {
      pline.forEach(plines => {
        if (a.productLine === plines.id) {
          // console.log("match");
          a.products = plines.products.replace(/, /g, ",").split(",");
          var parr = [];
          a.products.forEach(apr => {
            parr.push(apr.trim());
          });
          a.products = parr;
        }
      });
      delete a.questions;

      // a.enddate = new Date(a.enddate);
      // a.enddate.setHours(a.enddate.getHours() - 2);
      // a.startdate = new Date(a.startdate);
      // a.startdate.setHours(a.startdate.getHours() - 2);
      a.promotionScheduler.items.forEach(function(b) {
        pline.forEach(function(pl) {
          if (pl.id === b.promotionID.productLine)
            b.promotionID.productLine = pl;
        });
        // b.enddate = new Date(b.enddate);
        // b.enddate.setHours(b.enddate.getHours() - 2);
        //
        // b.startdate = new Date(b.startdate);
        //
        // b.startdate.setHours(b.startdate.getHours() - 2);
        b.location = JSON.parse(b.location);
        if (b.location.address === "") b.location.address = "Blank,Blank";
        var locations = b.location.address.split(",");
        b.location.region = locations[locations.length - 2].trim();
        if (b.gps === null) b.gps = null;
        else {
          b.gps = b.gps.split(",");
          var newgps = {};

          newgps.date = new Date(b.gps[0].replace("{date=", ""));
          newgps.date.setHours(newgps.date.getHours() - 2);
          newgps.geo = {
            lat: parseFloat(b.gps[1].replace(" geo={lat=", "")),
            lng: parseFloat(b.gps[2].replace(" lng=", ""))
          };
          b.gps = newgps;
        }
        if (b.signoffgps === null) b.signoffgps = null;
        else {
          b.signoffgps = b.signoffgps.split(",");
          var newgps = {};

          newgps.date = new Date(b.signoffgps[0].replace("{date=", ""));
          newgps.date.setHours(newgps.date.getHours() - 2);
          newgps.geo = {
            lat: parseFloat(b.signoffgps[1].replace(" geo={lat=", "")),
            lng: parseFloat(b.signoffgps[2].replace(" lng=", ""))
          };
          b.signoffgps = newgps;
        }
        b.responses.items.forEach(function(c) {
          c.timestamp = new Date(c.timestamp);
          c.timestamp.setHours(c.timestamp.getHours() - 2);
          c.response = JSON.parse(c.response);
          c.response.forEach(function(resitm) {
            delete resitm.items;
          });
        });
        if (b.stocktakevalues !== null) {
          b.stocktakevalues = JSON.parse(b.stocktakevalues);
          b.stocktakevalues.forEach(function(d) {
            d.count = parseInt(d.count);
            if (isNaN(d.count)) d.count = 0;
          });
        }
      });
    });
    return promosdetail;
  },
  findtavern(stpcode) {
    const STPUtils = require("./stputils").default;

    var output = null;
    for (var i = 0; i < STPUtils.length; i++) {
      if (STPUtils[i].stp + "" === stpcode)
        output = { address: STPUtils[i].address, geo: STPUtils[i].geo };
    }
    return output;
  },
  async isFastInternet() {
    function timeoutPromise(ms, promise) {
      return new Promise((resolve, reject) => {
        const timeoutId = setTimeout(() => {
          reject(new Error("promise timeout"));
        }, ms);
        promise.then(
          res => {
            clearTimeout(timeoutId);
            resolve(res);
          },
          err => {
            clearTimeout(timeoutId);
            reject(err);
          }
        );
      });
    }
    var starttest = new Date(Date.now());
    var lasttest = new Date(localStorage.getItem("lastofflinetest"));
    lasttest.setMinutes(lasttest.getMinutes() + 5);
    // console.log(starttest)
    // console.log(lasttest)
    var setter;
    if (starttest > lasttest && navigator.onLine) {
      try {
        await timeoutPromise(
          2000,
          window.fetch("https://promoter.plus/manifest.json?a=" + Math.random())
          // window.fetch(
          //   "https://1krl0jzrfk.execute-api.eu-west-1.amazonaws.com/production/dummy?random=" +
          //     Math.random()
          // )
        );
        // console.log("good speed")
        setter = true;
      } catch (error) {
        localStorage.setItem("lastofflinetest", starttest);
        setter = false;
      }

      return setter;
    } else {
      // console.log("wait 5 mins")
      return false;
    }
  }
};
export default Utils;
