/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPromoterPlusPromotion = /* GraphQL */ `
  query GetPromoterPlusPromotion($id: ID!) {
    getPromoterPlusPromotion(id: $id) {
      id
      label
      promotionName
      client {
        id
        clientname
        label
        promotion {
          nextToken
        }
        reportconfig
        icon
        consumableusage {
          nextToken
        }
        consumablesetup
        consumablereplenishemail
      }
      productLine
      products
      questions
      promotionScheduler {
        items {
          id
          startdate
          enddate
          location
          gps
          signoffgps
          photosdone
          signindone
          stocktakedone
          stocktakevalues
          standpicdone
          promoterpicdone
          otheritemsdone
          signoffmanagername
          signofftimestamp
          signoffmanageremail
          signoffmanagerphonenumber
          signoffmanagerrating
          signoffmanagercomments
          label
        }
        nextToken
      }
      photos
      startdate
      enddate
      colours
    }
  }
`;
export const listPromoterPlusPromotions = /* GraphQL */ `
  query ListPromoterPlusPromotions(
    $filter: ModelPromoterPlusPromotionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPromoterPlusPromotions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        label
        promotionName
        client {
          id
          clientname
          label
          reportconfig
          icon
          consumablesetup
          consumablereplenishemail
        }
        productLine
        products
        questions
        promotionScheduler {
          nextToken
        }
        photos
        startdate
        enddate
        colours
      }
      nextToken
    }
  }
`;
export const getPromoterPlusPhotos = /* GraphQL */ `
  query GetPromoterPlusPhotos($id: ID!) {
    getPromoterPlusPhotos(id: $id) {
      id
      user {
        id
        cognitoid
        label
        name
        email
        homelocation
        type
        access
        schedule {
          nextToken
        }
        phone
      }
      schedule {
        id
        promotionID {
          id
          label
          promotionName
          productLine
          products
          questions
          photos
          startdate
          enddate
          colours
        }
        user {
          id
          cognitoid
          label
          name
          email
          homelocation
          type
          access
          phone
        }
        responses {
          nextToken
        }
        promotionPhotos {
          nextToken
        }
        startdate
        enddate
        location
        gps
        signoffgps
        photosdone
        signindone
        stocktakedone
        stocktakevalues
        standpicdone
        promoterpicdone
        otheritemsdone
        signoffmanagername
        signofftimestamp
        signoffmanageremail
        signoffmanagerphonenumber
        signoffmanagerrating
        signoffmanagercomments
        label
      }
      timestamp
      filename
      phototype
      label
    }
  }
`;
export const listPromoterPlusPhotoss = /* GraphQL */ `
  query ListPromoterPlusPhotoss(
    $filter: ModelPromoterPlusPhotosFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPromoterPlusPhotoss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user {
          id
          cognitoid
          label
          name
          email
          homelocation
          type
          access
          phone
        }
        schedule {
          id
          startdate
          enddate
          location
          gps
          signoffgps
          photosdone
          signindone
          stocktakedone
          stocktakevalues
          standpicdone
          promoterpicdone
          otheritemsdone
          signoffmanagername
          signofftimestamp
          signoffmanageremail
          signoffmanagerphonenumber
          signoffmanagerrating
          signoffmanagercomments
          label
        }
        timestamp
        filename
        phototype
        label
      }
      nextToken
    }
  }
`;
export const getPromoterPlusResponses = /* GraphQL */ `
  query GetPromoterPlusResponses($id: ID!) {
    getPromoterPlusResponses(id: $id) {
      id
      user {
        id
        cognitoid
        label
        name
        email
        homelocation
        type
        access
        schedule {
          nextToken
        }
        phone
      }
      schedule {
        id
        promotionID {
          id
          label
          promotionName
          productLine
          products
          questions
          photos
          startdate
          enddate
          colours
        }
        user {
          id
          cognitoid
          label
          name
          email
          homelocation
          type
          access
          phone
        }
        responses {
          nextToken
        }
        promotionPhotos {
          nextToken
        }
        startdate
        enddate
        location
        gps
        signoffgps
        photosdone
        signindone
        stocktakedone
        stocktakevalues
        standpicdone
        promoterpicdone
        otheritemsdone
        signoffmanagername
        signofftimestamp
        signoffmanageremail
        signoffmanagerphonenumber
        signoffmanagerrating
        signoffmanagercomments
        label
      }
      timestamp
      response
      label
    }
  }
`;
export const listPromoterPlusResponsess = /* GraphQL */ `
  query ListPromoterPlusResponsess(
    $filter: ModelPromoterPlusResponsesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPromoterPlusResponsess(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user {
          id
          cognitoid
          label
          name
          email
          homelocation
          type
          access
          phone
        }
        schedule {
          id
          startdate
          enddate
          location
          gps
          signoffgps
          photosdone
          signindone
          stocktakedone
          stocktakevalues
          standpicdone
          promoterpicdone
          otheritemsdone
          signoffmanagername
          signofftimestamp
          signoffmanageremail
          signoffmanagerphonenumber
          signoffmanagerrating
          signoffmanagercomments
          label
        }
        timestamp
        response
        label
      }
      nextToken
    }
  }
`;
export const getPromoterPlusSchedule = /* GraphQL */ `
  query GetPromoterPlusSchedule($id: ID!) {
    getPromoterPlusSchedule(id: $id) {
      id
      promotionID {
        id
        label
        promotionName
        client {
          id
          clientname
          label
          reportconfig
          icon
          consumablesetup
          consumablereplenishemail
        }
        productLine
        products
        questions
        promotionScheduler {
          nextToken
        }
        photos
        startdate
        enddate
        colours
      }
      user {
        id
        cognitoid
        label
        name
        email
        homelocation
        type
        access
        schedule {
          nextToken
        }
        phone
      }
      responses {
        items {
          id
          timestamp
          response
          label
        }
        nextToken
      }
      promotionPhotos {
        items {
          id
          timestamp
          filename
          phototype
          label
        }
        nextToken
      }
      startdate
      enddate
      location
      gps
      signoffgps
      photosdone
      signindone
      stocktakedone
      stocktakevalues
      standpicdone
      promoterpicdone
      otheritemsdone
      signoffmanagername
      signofftimestamp
      signoffmanageremail
      signoffmanagerphonenumber
      signoffmanagerrating
      signoffmanagercomments
      label
    }
  }
`;
export const listPromoterPlusSchedules = /* GraphQL */ `
  query ListPromoterPlusSchedules(
    $filter: ModelPromoterPlusScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPromoterPlusSchedules(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        promotionID {
          id
          label
          promotionName
          productLine
          products
          questions
          photos
          startdate
          enddate
          colours
        }
        user {
          id
          cognitoid
          label
          name
          email
          homelocation
          type
          access
          phone
        }
        responses {
          nextToken
        }
        promotionPhotos {
          nextToken
        }
        startdate
        enddate
        location
        gps
        signoffgps
        photosdone
        signindone
        stocktakedone
        stocktakevalues
        standpicdone
        promoterpicdone
        otheritemsdone
        signoffmanagername
        signofftimestamp
        signoffmanageremail
        signoffmanagerphonenumber
        signoffmanagerrating
        signoffmanagercomments
        label
      }
      nextToken
    }
  }
`;
export const getPromoterPlusClient = /* GraphQL */ `
  query GetPromoterPlusClient($id: ID!) {
    getPromoterPlusClient(id: $id) {
      id
      clientname
      label
      promotion {
        items {
          id
          label
          promotionName
          productLine
          products
          questions
          photos
          startdate
          enddate
          colours
        }
        nextToken
      }
      reportconfig
      icon
      consumableusage {
        items {
          id
          userID
          scheduleID
          timestamp
          usage
        }
        nextToken
      }
      consumablesetup
      consumablereplenishemail
    }
  }
`;
export const listPromoterPlusClients = /* GraphQL */ `
  query ListPromoterPlusClients(
    $filter: ModelPromoterPlusClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPromoterPlusClients(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientname
        label
        promotion {
          nextToken
        }
        reportconfig
        icon
        consumableusage {
          nextToken
        }
        consumablesetup
        consumablereplenishemail
      }
      nextToken
    }
  }
`;
export const getPromoterPlusProduct = /* GraphQL */ `
  query GetPromoterPlusProduct($id: ID!) {
    getPromoterPlusProduct(id: $id) {
      id
      clientid
      productline
      products
      label
      faqpdf
      faqicon
      faqtext
    }
  }
`;
export const listPromoterPlusProducts = /* GraphQL */ `
  query ListPromoterPlusProducts(
    $filter: ModelPromoterPlusProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPromoterPlusProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientid
        productline
        products
        label
        faqpdf
        faqicon
        faqtext
      }
      nextToken
    }
  }
`;
export const getPromoterPlusUser = /* GraphQL */ `
  query GetPromoterPlusUser($id: ID!) {
    getPromoterPlusUser(id: $id) {
      id
      cognitoid
      label
      name
      email
      homelocation
      type
      access
      schedule {
        items {
          id
          startdate
          enddate
          location
          gps
          signoffgps
          photosdone
          signindone
          stocktakedone
          stocktakevalues
          standpicdone
          promoterpicdone
          otheritemsdone
          signoffmanagername
          signofftimestamp
          signoffmanageremail
          signoffmanagerphonenumber
          signoffmanagerrating
          signoffmanagercomments
          label
        }
        nextToken
      }
      phone
    }
  }
`;
export const listPromoterPlusUsers = /* GraphQL */ `
  query ListPromoterPlusUsers(
    $filter: ModelPromoterPlusUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPromoterPlusUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoid
        label
        name
        email
        homelocation
        type
        access
        schedule {
          nextToken
        }
        phone
      }
      nextToken
    }
  }
`;
export const getPromoterPlusLabels = /* GraphQL */ `
  query GetPromoterPlusLabels($id: ID!) {
    getPromoterPlusLabels(id: $id) {
      id
      label
      config
    }
  }
`;
export const listPromoterPlusLabelss = /* GraphQL */ `
  query ListPromoterPlusLabelss(
    $filter: ModelPromoterPlusLabelsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPromoterPlusLabelss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        label
        config
      }
      nextToken
    }
  }
`;
export const getPromoterPlusConsumed = /* GraphQL */ `
  query GetPromoterPlusConsumed($id: ID!) {
    getPromoterPlusConsumed(id: $id) {
      id
      userID
      scheduleID
      consumableID {
        id
        clientname
        label
        promotion {
          nextToken
        }
        reportconfig
        icon
        consumableusage {
          nextToken
        }
        consumablesetup
        consumablereplenishemail
      }
      timestamp
      usage
    }
  }
`;
export const listPromoterPlusConsumeds = /* GraphQL */ `
  query ListPromoterPlusConsumeds(
    $filter: ModelPromoterPlusConsumedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPromoterPlusConsumeds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        scheduleID
        consumableID {
          id
          clientname
          label
          reportconfig
          icon
          consumablesetup
          consumablereplenishemail
        }
        timestamp
        usage
      }
      nextToken
    }
  }
`;
export const getPromoterPlusProjectTracker = /* GraphQL */ `
  query GetPromoterPlusProjectTracker($id: ID!) {
    getPromoterPlusProjectTracker(id: $id) {
      id
      test
    }
  }
`;
export const listPromoterPlusProjectTrackers = /* GraphQL */ `
  query ListPromoterPlusProjectTrackers(
    $filter: ModelPromoterPlusProjectTrackerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPromoterPlusProjectTrackers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        test
      }
      nextToken
    }
  }
`;
