import React from "react";
import { Auth } from "aws-amplify";
import { withRouter } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import Utils from "./utils";
import { isMobile } from "react-device-detect";
import Dash2Filters from "./dash2filters";
import Promotertable from "./promotertable2";
import ModalImage from "./modalimage";
import ModalStock from "./modalstock";
import ModalSignoff from "./modalsignoff";
import ModalCons from "./modalcons";
import ModalEditSales from "./modaleditsales";
import Paper from "@material-ui/core/Paper";
import SaveIcon from "@material-ui/icons/CloudDownload";
import PhotoIcon from "@material-ui/icons/PhotoCamera";
import PromotionsMaps from "./mapsdashboard2";
import GraphPie from "./graphpie";
import DownIcon from "@material-ui/icons/ExpandMore";
import Snackbar from "@material-ui/core/Snackbar";

import UpIcon from "@material-ui/icons/ExpandLess";
import zip from "jszip";
import Button from "@material-ui/core/Button";
import Eye from "@material-ui/icons/RemoveRedEye";

class PIcon extends React.Component {
  render() {
    return (
      <div
        style={{
          width: this.props.size,
          height: this.props.size,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: this.props.colour,
          color: "#000",
          borderRadius: 5,
          marginRight: 10
        }}
      >
        {this.props.icon}
      </div>
    );
  }
}
class Blocks extends React.Component {
  render() {
    return (
      <div>
        <div
          style={{ fontSize: 20, justifyContent: "center", display: "flex" }}
        >
          <b>{this.props.header}</b>
        </div>
        <div
          style={{ fontSize: 40, justifyContent: "center", display: "flex" }}
        >
          <b>{this.props.main}</b>
        </div>
        <div
          style={{ fontSize: 14, justifyContent: "center", display: "flex" }}
        >
          {this.props.subtext + "  "}{" "}
          <div
            style={{
              marginLeft: 3,
              color: this.props.sugar.indexOf("-") > -1 ? "#f00" : "#0f0"
            }}
          >
            {this.props.sugar}
          </div>
        </div>
      </div>
    );
  }
}

class Desktop extends React.Component {
  doSnackBar(input) {
    this.setState({ statusbar: true, statusbartext: input });
  }
  constructor(props) {
    super(props);
    let promoenddate = new Date(Date.now()).toISOString().split("T")[0];
    let enddate = new Date(promoenddate + "T23:59:59");
    let startdatea = new Date(promoenddate + "T00:00:00");
    startdatea.setHours(startdatea.getHours() + 2);
    enddate.setHours(enddate.getHours() + 2);
    this.state = {
      APIfilter: {},
      promotions: [],
      centre: {
        lat: -26.04731,
        lng: 28.095623
      },
      promotionsFiltered: [],
      modalImageIsOpen: false,
      modalImageImages: [],
      modalStockIsOpen: false,
      modalStockData: [],
      modalConsOpen: false,
      modalConsData: [],
      modalSignoffIsOpen: false,
      modalSignoffData: [],
      consumptiondata: [],
      refreshInterval: 120,
      editModalOpen: false,
      editModalData: {
        sales: 0,
        taste: 0,
        userid: "",
        scheduleID: "",
        responses: []
      },
      filterOptions: {
        promolistenddate: new Date("2025-01-01T00:00:00"),
        promoliststartdate: new Date("2024-01-01T02:00:00"),
        products: [],
        promoters: [],
        promotions: [],
        regions: [],
        clients: [],
        promotersSelected: [],
        productsSelected: [],
        markers: [],
        regionsSelected: [],
        clientsSelected: [],
        firstdate: startdatea,
        lastdate: enddate
      },
      photodlstatus: "0/0"
    };
    this.startTimer = this.startTimer.bind(this);
    this.stopTimer = this.stopTimer.bind(this);
    this.resetTimer = this.resetTimer.bind(this);
  }

  async initialise(startdate, enddate, fdt, ldt) {
    let p = await Auth.currentAuthenticatedUser();
    Sentry.setUser({ email: p.attributes.email });
    let t = await Utils.getUserAlwaysOnline(p.username);
    var APIfilter = {
      label: { eq: t.label },
      enddate: { le: enddate },
      startdate: { ge: startdate },
      cdates: { fdt: fdt, ldt: ldt }
    };

    if (t.access !== "all") this.props.history.push("/cdashboard");
    this.setState({ APIfilter: APIfilter });
    this.doSnackBar("Collecting data...");
    var promotions = await Utils.getAllPromotions3(APIfilter);


    var filteroptions = this.getFilterOptions(promotions);
    var temppromo = JSON.parse(JSON.stringify(promotions));
    var results = this.applyFilters(temppromo, filteroptions);
    this.setState({
      promotions: promotions,
      promotionsFiltered: results,
      filterOptions: filteroptions,
      statusbar: false
    });
    await this.updateConsumables(results);
  }

  getFilterOptions(promos) {
    var promoters = [];
    var promotions = [];
    var products = [];
    var regions = [];
    var clients = [];
    promos.forEach(function(a) {
      clients.push(a.client.clientname);
      products = products.concat(a.products);
      promotions.push({
        client: a.client,
        colours: a.colours,
        selected: true,
        promotionName: a.promotionName,
        products: a.products.join(","),
        id: a.id,
        startdate: a.startdate,
        enddate: a.enddate
      });
      a.promotionScheduler.items.forEach(function(b) {
        // console.log(b)
        if (b.user === null) promoters.push("DELETED");
        else promoters.push(b.user.name);
        regions.push(b.location.region);
      });
    });
    var oldfilterOptionsAll = this.state.filterOptions;
    oldfilterOptionsAll.promotions = promotions;
    oldfilterOptionsAll.promoters = Utils.removeDuplicates(promoters);
    oldfilterOptionsAll.products = Utils.removeDuplicates(products);
    oldfilterOptionsAll.clients = Utils.removeDuplicates(clients);
    oldfilterOptionsAll.regions = Utils.removeDuplicates(regions);
    oldfilterOptionsAll.promotersSelected = Utils.removeDuplicates(promoters);
    oldfilterOptionsAll.productsSelected = Utils.removeDuplicates(products);
    oldfilterOptionsAll.clientsSelected = Utils.removeDuplicates(clients);
    oldfilterOptionsAll.regionsSelected = Utils.removeDuplicates(regions);
    return oldfilterOptionsAll;
  }

  componentDidMount() {
    this.startTimer(this.state.refreshInterval);
    Auth.currentAuthenticatedUser().catch(() => {
      if (isMobile) this.props.history.push("/signinmobile");
      else this.props.history.push("/signin");
    });

    this.initialise(
      this.state.filterOptions.promoliststartdate,
      this.state.filterOptions.promolistenddate,
      this.state.filterOptions.firstdate,
      this.state.filterOptions.lastdate
    );
  }

  async updateConsumables(input) {
    let userids = [];
    input.forEach(a => {
      a.promotionScheduler.items.forEach(b => {
        userids.push(b.user.id);
      });
    });
    userids = [...new Set(userids)];
    async function getUsersConsumptionData(userid) {
      let result = await Utils.getConsumableDataByUserID(userid, null);
      result.items.forEach(z => (z.usage = JSON.parse(z.usage)));
      return { userid, nextToken: result.nextToken, items: result.items };
    }

    async function getUsersConsumptionDataRepeat(userid) {
      let result = await Utils.getConsumableDataByUserID(
        userid.userid,
        userid.nextToken
      );
      result.items.forEach(z => (z.usage = JSON.parse(z.usage)));
      result.items = result.items.concat(userid.items);
      return {
        userid: userid.userid,
        nextToken: result.nextToken,
        items: result.items
      };
    }

    function compressamounts(input) {
      // console.log(input);
      let result = input.reduce((acc, d) => {
        let found = acc.find(
          a => a.timestamp === d.timestamp && a.type === d.type
        );
        if (!found)
          acc.push({
            timestamp: d.timestamp,
            amount: parseInt(d.amount),
            type: d.type
          });
        else {
          found.amount += parseInt(d.amount);
        }
        return acc;
      }, []);

      return result.sort((a,b)=>{
        if (a.timestamp > b.timestamp) return 1
        else if (a.timestamp < b.timestamp) return -1
        else return 0
      });
    }

    function pivotUserArray(original, newdata) {
      // console.log(original);
      // console.log(newdata);
      newdata.items.forEach(a => {
        a.usage.forEach(b => {
          if (original.items.filter(z => z.item === b.item).length === 0)
            original.items.push({ item: b.item, amounts: [] });
          let amounts = original.items.find(c => c.item === b.item).amounts;
          amounts.push({
            amount: b.amount,
            timestamp: a.timestamp.substr(0, 10),
            type: a.scheduleID === "adjust" ? "adjust" : "consumed"
          });


          // amounts = compressamounts(amounts);
          // console.log(amounts);
        });
      });
      return original;
    }

    let usr = await Promise.all(userids.map(getUsersConsumptionData));
    // console.log(usr);
    this.setState(prevState => {
      let consumptiondata = prevState.consumptiondata;
      usr.forEach(x => {
        if (consumptiondata.filter(z => z.userid === x.userid).length === 0)
          consumptiondata.push({ userid: x.userid, items: [] });
        let usersdata = consumptiondata.find(a => a.userid === x.userid);
        usersdata = pivotUserArray(usersdata, x);
      });
      consumptiondata.forEach(z=>{
        z.items.forEach(x=>{
          x.amounts  = compressamounts(x.amounts  )

        })
      })
      return { ...prevState, consumptiondata };
    });

    while (usr.filter(z => z.nextToken !== null).length > 0) {
      this.doSnackBar("Still collecting consumable data...");
      usr = await Promise.all(usr.map(getUsersConsumptionDataRepeat));
      this.setState(prevState => {
        let consumptiondata = prevState.consumptiondata;
        usr.forEach(x => {
          if (consumptiondata.filter(z => z.userid === x.userid).length === 0)
            consumptiondata.push({ userid: x.userid, items: [] });
          let usersdata = consumptiondata.find(a => a.userid === x.userid);
          usersdata = pivotUserArray(usersdata, x);
        });
        consumptiondata.forEach(z=>{
          z.items.forEach(x=>{
            x.amounts  = compressamounts(x.amounts  )

          })
        })
        return { ...prevState, consumptiondata };
      });
    }
    this.setState({ statusbar: false });
    // let processed2 = Utils.pivotConsumptionData(processed,result.items)
  }

  async refesh() {
    this.doSnackBar("Collecting data...");
    var tempfilter = this.state.APIfilter;
    tempfilter.cdates = {
      ldt: this.state.filterOptions.lastdate,
      fdt: this.state.filterOptions.firstdate
    };
    var promotions = await Utils.getAllPromotions3(tempfilter);
    var filteroptions = this.state.filterOptions;
    var temppromo = JSON.parse(JSON.stringify(promotions));
    var results = this.applyFilters(temppromo, filteroptions);
    this.setState({
      promotions: promotions,
      promotionsFiltered: results,
      statusbar: false
    });
    this.updateConsumables(results);
  }

  startTimer(interval) {
    this.setState({
      isOn: true,
      time: this.state.time,
      start: Date.now() - this.state.time
    });
    this.timer = setInterval(() => {
      this.refesh();
    }, interval * 1000);
  }

  stopTimer() {
    this.setState({ isOn: false });
    clearInterval(this.timer);
  }

  resetTimer() {
    this.setState({ time: 0, isOn: false });
  }

  applyFilters(input, filters) {
    var output = [];
    input = JSON.parse(JSON.stringify(input));
    input.forEach(function(a) {
      filters.clientsSelected.forEach(function(b) {
        filters.promotions.forEach(function(c) {
          if ((c.id === a.id) & c.selected && b === a.client.clientname)
            output.push(a);
        });
      });
    });

    output.forEach(function(a) {
      var newitems = [];
      a.promotionScheduler.items.forEach(function(b) {
        // console.log(b.gps)
        filters.regionsSelected.forEach(function(c) {
          if (b.location.region === c) newitems.push(b);
        });
      });
      a.promotionScheduler.items = newitems;
    });
    output.forEach(function(a) {
      var newitems = [];
      a.promotionScheduler.items.forEach(function(b) {
        filters.promotersSelected.forEach(function(c) {
          if (b.user !== null) if (b.user.name === c) newitems.push(b);
        });
      });
      a.promotionScheduler.items = newitems;
    });
    output.forEach(function(a) {
      a.promotionScheduler.items.forEach(function(b) {
        if (b.gps !== null) {
          if (typeof b.gps.date === "string") {
            b.gps.date = new Date(b.gps.date);
            b.gps.date.setHours(b.gps.date.getHours() + 2);
          }
        }
      });
    });
    output.forEach(function(a) {
      a.promotionScheduler.items.forEach(function(b) {
        var newitems = [];
        b.responses.items.forEach(function(c) {
          c.timestamp = new Date(c.timestamp);
          c.timestamp.setHours(c.timestamp.getHours() - 2);
          c.response.forEach(function(d) {
            filters.productsSelected.forEach(function(e) {
              if (d.question === "product" && d.response === e)
                newitems.push(c);
            });
          });
        });
        b.responses.items = newitems;
      });
    });
    var o2 = JSON.parse(JSON.stringify(output));
    o2.forEach(function(a) {
      var newitems = [];
      a.promotionScheduler.items.forEach(function(b) {
        let sd = new Date(b.startdate);
        if (sd > filters.firstdate && sd < filters.lastdate) newitems.push(b);
      });
      a.promotionScheduler.items = newitems;
    });
    // console.log(o2)
    return JSON.parse(JSON.stringify(o2));
  }

  async addNewResponses(sales, taste, userAndPromoID) {
    // console.log(sales)
    // console.log(taste)
    // console.log(userAndPromoID)
    var promoid = await Utils.getScheduleById(userAndPromoID.scheduleID);
    var prod = JSON.parse(promoid.promotionID.products);
    if (prod.indexOf(",") > -1) prod = prod.split(",")[0].trim();
    // console.log(prod)
    var date = new Date(Date.now());
    date.setHours(date.getHours() + 2);
    if (sales > 0) {
      for (var i = 0; i < sales; i++) {
        Utils.createPromotionResponse({
          scheduleID: userAndPromoID.scheduleID,
          userid: userAndPromoID.userid,
          timestamp: date,
          response: JSON.stringify([
            {
              items: "Sale,Taste",
              question: "Saletype",
              response: "Sale",
              type: "toggle"
            },
            {
              items: " n",
              question: "product",
              response: prod,
              type: "toggle"
            }
          ])
        });
      }
    }
    if (sales < 0) {
      var id = this.findIDtoDelete(userAndPromoID.responses, "Sale", sales);
      await Promise.all(id.map(Utils.deleteResponse));
      // Utils.log(deletes);
    }

    if (taste > 0) {
      for (i = 0; i < taste; i++) {
        Utils.createPromotionResponse({
          scheduleID: userAndPromoID.scheduleID,
          userid: userAndPromoID.userid,
          timestamp: date,
          response: JSON.stringify([
            {
              items: "Sale,Taste",
              question: "Saletype",
              response: "Taste",
              type: "toggle"
            },
            {
              items: " n",
              question: "product",
              response: prod,
              type: "toggle"
            }
          ])
        });
      }
    }
    if (taste < 0) {
      id = this.findIDtoDelete(userAndPromoID.responses, "Taste", taste);
      await Promise.all(id.map(Utils.deleteResponse));
      // Utils.log(deletes);
    }
    this.refesh();
    this.setState({ editModalOpen: false });
  }

  countSignIns(input) {
    var now = new Date(Date.now()).toISOString();
    var schedules = [];
    // console.log(input)
    input.forEach(function(pro) {
      pro.promotionScheduler.items.forEach(function(sch) {
        // sch.startdate.setHours(sch.startdate.getHours() - 2);

        schedules.push({
          done:
            sch.promotionPhotos.items.filter(z => z.phototype === "Sign in")
              .length > 0,
          startdate: sch.startdate,
          started: sch.startdate > now ? false : true
        });
      });
    });
    var greens = 0;
    var reds = 0;
    var yellows = 0;
    schedules.forEach(function(s) {
      if (s.started === false) yellows += 1;
      if (s.started === true && s.done === true) greens += 1;
      if (s.started === true && s.done !== true) reds += 1;
    });
    var signinpie = [
      { name: "Not signed in", value: reds },
      { name: "Signed in", value: greens },
      { name: "Promotion not started", value: yellows }
    ];
    return signinpie;
  }

  countSchedules(input) {
    var now = new Date(Date.now()).toISOString();
    var schedules = [];
    input.forEach(function(pro) {
      pro.promotionScheduler.items.forEach(function(sch) {
        // sch.startdate.setHours(sch.startdate.getHours() - 2);
        schedules.push({
          done: sch.signindone,
          startdate: sch.startdate,
          started: sch.startdate > now ? false : true
        });
      });
    });

    return schedules.length;
  }

  countSchedulesCheckins(input) {
    var now = new Date(Date.now()).toISOString();
    var schedules = [];
    // console.log(input)
    input.forEach(function(pro) {
      pro.promotionScheduler.items.forEach(function(sch) {
        // sch.startdate.setHours(sch.startdate.getHours() - 2);
        if (sch.startdate <= now)
          schedules.push({
            gps: sch.gps,
            startdate: sch.startdate
          });
      });
    });
    var yets = 0;
    var lates = 0;
    schedules.forEach(function(s) {
      if (s.gps === null) yets += 1;
      else if (s.gps !== null) {
        // console.log(s.startdate)
        // var newstime = new Date( s.startdate)

        // console.log(newstime.toISOString())
        if (s.gps.date > s.startdate) lates += 1;
      }
    });
    // console.log(lates)
    lates = (lates / schedules.length) * 100;
    // console.log(lates)
    lates = lates.toFixed(0);
    // console.log(lates)

    if (isNaN(lates)) lates = 0;
    //     console.log(lates)
    // console.log(schedules)
    return { main: yets, sugar: lates + "%" };
  }

  countSchedulesSales(input) {
    var now = new Date(Date.now()).toISOString();
    // console.log(now)
    var sales = 0;
    var interactions = 0;
    // console.log(input)
    input.forEach(function(pro) {
      pro.promotionScheduler.items.forEach(function(sch) {
        // sch.startdate.setHours(sch.startdate.getHours() - 2);
        if (sch.startdate <= now) {
          sch.responses.items.forEach(function(res) {
            interactions += 1;
            res.response.forEach(function(itm) {
              if (itm.question === "Saletype" && itm.response === "Sale")
                sales += 1;
            });
          });
        }
      });
    });
    var inter = (sales / interactions) * 100;
    inter = inter.toFixed(0);
    if (isNaN(inter)) inter = 0;
    return { main: sales, sugar: inter + "%" };
  }

  countLowstocks(input) {
    var now = new Date(Date.now()).toISOString();
    // console.log(now)
    var counter = 0;

    // console.log(input);
    input.forEach(function(pro) {
      pro.promotionScheduler.items.forEach(function(sch) {
        // sch.startdate.setHours(sch.startdate.getHours() - 2);
        if (sch.startdate <= now) {
          var tempcount = 0;
          if (sch.stocktakevalues !== null) {
            sch.stocktakevalues.forEach(function(res) {
              if (res.count < 10) tempcount += 1;
            });
          }
          if (tempcount > 0) counter += 1;
        }
      });
    });

    return { main: counter, sugar: "%" };
  }

  countSignoffs(input) {
    var now = new Date(Date.now()).toISOString();
    // console.log(now)
    var signoffsdone = 0;
    var total = 0;
    // console.log(input);
    input.forEach(function(pro) {
      pro.promotionScheduler.items.forEach(function(sch) {
        // sch.startdate.setHours(sch.startdate.getHours() - 2);
        if (sch.startdate <= now) {
          total += 1;
          if (sch.signofftimestamp !== null) signoffsdone += 1;
        }
      });
    });
    var sug = ((total - signoffsdone) / total) * 100;
    sug = sug.toFixed(0);
    if (isNaN(sug)) sug = 0;
    return { main: signoffsdone, sugar: sug + "%" };
  }

  countPromoterPhotos(input) {
    var now = new Date(Date.now()).toISOString();
    var schedules = [];
    input.forEach(function(pro) {
      pro.promotionScheduler.items.forEach(function(sch) {
        // sch.startdate.setHours(sch.startdate.getHours() - 2);
        schedules.push({
          done:
            sch.promotionPhotos.items.filter(z => z.phototype === "Promoter")
              .length > 0,
          startdate: sch.startdate,
          started: sch.startdate > now ? false : true
        });
      });
    });
    var greens = 0;
    var reds = 0;
    var yellows = 0;
    schedules.forEach(function(s) {
      if (s.started === false) yellows += 1;
      if (s.started === true && s.done === true) greens += 1;
      if (s.started === true && s.done !== true) reds += 1;
    });
    var signinpie = [
      { name: "Promoter has not taken a photo", value: reds },
      { name: "Promoter photo done", value: greens },
      { name: "Promotion not started", value: yellows }
    ];
    return signinpie;
  }

  countStandPhotos(input) {
    var now = new Date(Date.now()).toISOString();
    var schedules = [];
    input.forEach(function(pro) {
      pro.promotionScheduler.items.forEach(function(sch) {
        // sch.startdate.setHours(sch.startdate.getHours() - 2);
        schedules.push({
          done:
            sch.promotionPhotos.items.filter(z => z.phototype === "Stand")
              .length > 0,
          startdate: sch.startdate,
          started: sch.startdate > now ? false : true
        });
      });
    });
    var greens = 0;
    var reds = 0;
    var yellows = 0;
    schedules.forEach(function(s) {
      if (s.started === false) yellows += 1;
      if (s.started === true && s.done === true) greens += 1;
      if (s.started === true && s.done !== true) reds += 1;
    });
    var signinpie = [
      { name: "Promoter has not taken a stand photo", value: reds },
      { name: "Promoter stand photo done", value: greens },
      { name: "Promotion not started", value: yellows }
    ];
    return signinpie;
  }

  countOtherPhotos(input) {
    // console.log(input);
    var now = new Date(Date.now()).toISOString();
    // console.log(now);
    var schedules = [];
    input.forEach(function(pro) {
      pro.promotionScheduler.items.forEach(function(sch) {
        // sch.startdate.setHours(sch.startdate.getHours() - 2);
        schedules.push({
          done:
            sch.promotionPhotos.items.filter(
              z =>
                z.phototype === "Promoter" ||
                z.phototype === "Stand" ||
                z.phototype === "Sign in"
            ).length > 0,
          startdate: sch.startdate,
          started: sch.startdate > now ? false : true
        });
      });
    });

    var greens = 0;
    var reds = 0;
    var yellows = 0;

    schedules.forEach(function(s) {
      if (s.started === false) yellows += 1;
      if (s.started === true && s.done === true) greens += 1;
      if (s.started === true && s.done !== true) reds += 1;
    });
    // console.log(schedules);
    // console.log(greens);
    // console.log(reds);
    // console.log(yellows);
    var signinpie = [
      { name: "Promoter has not taken a customer photo", value: reds },
      { name: "Promoter customer photo done", value: greens },
      { name: "Promotion not started", value: yellows }
    ];
    return signinpie;
  }

  async getpics() {
    var zipper = new zip();
    // console.log(this.state.promotionsFiltered)
    // var filestructure=[]
    var geturis = [];

    this.state.promotionsFiltered.forEach(function(a) {
      var folder = zipper.folder(a.client.clientname);
      a.promotionScheduler.items.forEach(function(b) {
        var loc = folder.folder(b.location.address);
        var username = loc.folder(b.user.name);
        b.promotionPhotos.items.forEach(function(c) {
          username.folder(c.phototype);
          geturis.push({
            file:
              a.client.clientname +
              "/" +
              b.location.address +
              "/" +
              b.user.name +
              "/" +
              c.phototype +
              "/" +
              c.timestamp +
              "." +
              c.filename.split(".")[1],
            filename: c.filename
          });
        });
      });
    });

    for (var i = 0; i < geturis.length; i++) {
      var fname = await Utils.getPicFromS3(geturis[i].filename);
      // console.log(fname)
      var a = await fetch(fname);
      var b = await a.blob();
      this.setState({ photodlstatus: i + 1 + "/" + geturis.length });
      // console.log(geturis[0])
      zipper.file(geturis[i].file, b);
    }

    zipper
      .generateAsync({ type: "blob" })
      // console.log(zipper);import Eye from "@material-ui/icons/RemoveRedEye";
      .then(function(content) {
        // see FileSaver.js
        // saveAs(content, "example.zip");
        var data = new Blob([content], { type: "application/zip" });
        var myURL = window.URL || window.webkitURL;
        var csvURL = myURL.createObjectURL(data);
        var tempLink = document.createElement("a");
        document.body.appendChild(tempLink);

        tempLink.href = csvURL;
        tempLink.setAttribute("download", "filename.zip");
        tempLink.click();
        setTimeout(function() {
          document.body.removeChild(tempLink);
          window.URL.revokeObjectURL(csvURL);
        }, 100);
      });
  }
  filterSection(input) {
    if (!input)
      return (
        <Button
          variant="contained"
          style={{ color: "#222", backgroundColor: "#1da4f4" }}
          color="primary"
          onClick={() => this.setState({ filtersection: true })}
        >
          Filter <DownIcon />
        </Button>
      );
    else
      return (
        <Button
          style={{ color: "#222", backgroundColor: "#1da4f4" }}
          variant="outlined"
          color="primary"
          onClick={() => this.setState({ filtersection: false })}
        >
          Close <UpIcon />
        </Button>
      );
  }
  render() {
    // console.log(JSON.stringify(this.state.promotionsFiltered,null,4));
console.log(this.state.filterOptions)
    return (
      <div
        style={{
          color: "#666",
          margin: isMobile ? 0 : 10,
          backgroundColor: "#eeeeee"
        }}
      >
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={this.state.statusbar}
          onClose={() => this.setState({ statusbar: false })}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{this.state.statusbartext}</span>}
        />
        <Paper>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginLeft: 10,
              marginRight: 10
            }}
          >
            <h1
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              <PIcon
                icon={<Eye style={{ fontSize: 37 }} />}
                size="40px"
                colour="#4890e2"
              />
              Dashboard
            </h1>{" "}
            <div>{this.filterSection(this.state.filtersection)}</div>{" "}
          </div>
        </Paper>
        <ModalImage
          isOpen={this.state.modalImageIsOpen}
          closeMe={() => this.setState({ modalImageIsOpen: false })}
          images={this.state.modalImageImages}
        />
        <ModalCons
          isOpen={this.state.modalConsOpen}
          closeMe={() => this.setState({ modalConsOpen: false })}
          data={this.state.modalConsData}
        />
        <ModalStock
          isOpen={this.state.modalStockIsOpen}
          closeMe={() => this.setState({ modalStockIsOpen: false })}
          data={this.state.modalStockData}
        />
        <ModalSignoff
          isOpen={this.state.modalSignoffIsOpen}
          closeMe={() => this.setState({ modalSignoffIsOpen: false })}
          data={this.state.modalSignoffData}
        />
        <ModalEditSales
          isOpen={this.state.editModalOpen}
          closeMe={() =>
            this.setState({
              editModalOpen: false,
              editModalData: {
                sales: 0,
                taste: 0,
                userid: "",
                scheduleID: "",
                responses: []
              }
            })
          }
          data={this.state.editModalData}
          updateSalesData={data => {
            this.addNewResponses(
              data.editModalDataSales,
              data.editModalDataTaste,
              data.editModalData
            );
            this.setState({
              editModalData: {
                sales: 0,
                taste: 0,
                userid: "",
                scheduleID: "",
                responses: []
              }
            });
          }}
        />
        {this.state.filtersection ? (
          <Dash2Filters
            initialOptions={this.state.filterOptions}
            expanded={this.state.filtersection}
            onExpand={input => this.setState({ filtersection: input })}
            filterOutput={change => {
              var original = JSON.parse(JSON.stringify(this.state.promotions));

              this.setState({
                filterOptions: change,
                promotionsFiltered: this.applyFilters(original, change)
              });
            }}
            campaignFilterOutput={change => {
              this.setState({ filterOptions: change });
              this.initialise(
                change.promoliststartdate,
                change.promolistenddate,
                change.firstdate,
                change.lastdate
              );
            }}
            goToPromotion={id => {
              this.props.history.push(
                "/promotions?edit=" + id.id + "&client=" + id.client
              );
            }}
          />
        ) : (
          <div />
        )}
        <div
          style={{
            margin: 0,
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            marginTop: 30,
            marginBottom: 30
          }}
        >
          <Paper
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "column",
              width: "100%"
            }}
          >
            <div
              style={{
                marginLeft: 10,
                borderTopColor: "#fff",
                borderTopWidth: 0,
                borderLeftWidth: 0,
                borderRightWidth: 0,
                width: "98%",
                borderColor: "#eee",
                borderStyle: "solid"
              }}
            >
              <h3>Performance overview</h3>
            </div>
            <div
              style={{
                padding: 30,
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                width: "95%",
                justifyContent: "space-around"
              }}
            >
              <Blocks
                header={"Total promos"}
                main={this.countSchedules(this.state.promotionsFiltered)}
                subtext={""}
                sugar={""}
              />
              <Blocks
                header={"Yet to check in"}
                main={
                  this.countSchedulesCheckins(this.state.promotionsFiltered)
                    .main
                }
                subtext={"Promoters on time"}
                sugar={
                  this.countSchedulesCheckins(this.state.promotionsFiltered)
                    .sugar
                }
              />
              <Blocks
                header={"Sales"}
                main={
                  this.countSchedulesSales(this.state.promotionsFiltered).main
                }
                subtext={"Converstion rate"}
                sugar={
                  this.countSchedulesSales(this.state.promotionsFiltered).sugar
                }
              />
              <Blocks
                header={"Signed off"}
                main={this.countSignoffs(this.state.promotionsFiltered).main}
                subtext={"Yet to sign off"}
                sugar={this.countSignoffs(this.state.promotionsFiltered).sugar}
              />
              <Blocks
                header={"Low stock"}
                main={this.countLowstocks(this.state.promotionsFiltered).main}
                subtext={"Stores have low stock"}
                sugar={""}
              />
            </div>
          </Paper>
        </div>
        <div
          style={{
            margin: 0,
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between"
          }}
        >
          <Paper
            style={{
              padding: 30,
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              width: "100%"
            }}
          >
            <GraphPie
              data={this.countSignIns(this.state.promotionsFiltered)}
              width={"24%"}
              minWidth={300}
              title={"Sign in done"}
            />
            <GraphPie
              data={this.countPromoterPhotos(this.state.promotionsFiltered)}
              width={"24%"}
              minWidth={300}
              title={"Promoter photo"}
            />
            <GraphPie
              data={this.countStandPhotos(this.state.promotionsFiltered)}
              width={"24%"}
              minWidth={300}
              title={"Stand photo"}
            />
            <GraphPie
              data={this.countOtherPhotos(this.state.promotionsFiltered)}
              width={"24%"}
              minWidth={300}
              title={"Customer photo"}
            />
          </Paper>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              color: "#ddd"
            }}
          >
            {this.state.promotionsFiltered.length === 0 ? (
              <div />
            ) : (
              <Button
                onClick={() => {
                  this.getpics();
                }}
              >
                <PhotoIcon style={{ color: "#aaa" }} />
                <SaveIcon style={{ color: "#aaa" }} />
                {this.state.photodlstatus}
              </Button>
            )}
          </div>
        </div>
        <Promotertable
          data={this.state.promotionsFiltered}
          showImages={images => {
            this.setState({
              modalImageIsOpen: true,
              modalImageImages: images
            });
          }}
          consumptiondata={this.state.consumptiondata}
          showStock={data => {
            this.setState({ modalStockIsOpen: true, modalStockData: data });
          }}
          showSignoff={data => {
            this.setState({
              modalSignoffIsOpen: true,
              modalSignoffData: data
            });
          }}
          showConsModal={data => {
            this.setState({ modalConsOpen: true, modalConsData: data });
          }}
          showEditModal={data => {
            this.setState({ editModalOpen: true, editModalData: data });
          }}
        />
        <div style={{ width: "100%", height: "100%" }}>
          <PromotionsMaps
            markers={this.state.promotionsFiltered}
            centre={this.state.centre}
          />
        </div>
      </div>
    );
  }
}
export default withRouter(Desktop);
