import "whatwg-fetch";
import React from "react";
import { Auth } from "aws-amplify";
import { withRouter } from "react-router-dom";
import { isMobile, isIOS } from "react-device-detect";
import Button from "@material-ui/core/Button";
import CameraIcon from "@material-ui/icons/PhotoCamera";
import GPSIcon from "@material-ui/icons/GpsFixed";
import WarningIcon from "@material-ui/icons/Warning";
import DoneIcon from "@material-ui/icons/Check";
// import SyncIcon from "@material-ui/icons/Sync";
import Snackbar from "@material-ui/core/Snackbar";
import StockTakeIcon from "@material-ui/icons/Assignment";
import BackIcon from "@material-ui/icons/ArrowBack";
import StarFull from "@material-ui/icons/Star";
import ExitIcon from "@material-ui/icons/ExitToApp";
import BasketIcon from "./icons/purchase.png";
import TrolleyIcon from "./icons/shopping_cart_2.png";
import HandIcon from "./icons/hand.png";
import ManIcon from "./icons/man.png";
import WomanIcon from "./icons/woman.png";
import CoupleIcon from "./icons/couple.png";
import RepeatIcon from "./icons/reload.png";
import FirstTimeIcon from "./icons/wizard.png";
import TasteIcon from "./icons/kiss.png";
import SaleIcon from "./icons/credit_cards.png";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";

import Utils from "./utils";
import TextField from "@material-ui/core/TextField";
import { Sticky, StickyProvider } from "react-stickup";
import ImageUploader from "react-images-upload";
import imageCompression from "browser-image-compression";

const container = React.createRef();

class Action extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sbstatus: false,
      statusbar: false,
      statusbartext: "",
      sbtext: "",
      rating: 0,
      stockCount: [],
      modalIsOpen: false,
      uploading: false,
      uploadingDone: false,
      signindone: false,
      gps: false,
      standpicdone: false,
      promoterpicdone: false,
      otheritemsdone: false,
      stocktakedone: false,
      stockTakeOpen: false,
      questions: null,
      photosdone: [],
      colours: {},
      photos: [],
      promotionID: "",
      userid: "",
      scheduleID: "",
      offlineResponses: 0,
      submitbuttontext: "Submit",
      isonline: false,
      consumablesetup: {}
    };
  }

  async componentDidMount() {
    Auth.currentAuthenticatedUser().catch(() =>
      this.props.history.push("/signin")
    );
    if (!isMobile) this.props.history.push("/desktop");
    try {
      this.setState({ isonline: await Utils.isFastInternet() });
      var products = [];
      // this.props.location.state.scheduleID.consumableSetup = JSON.parse(this.props.location.state.scheduleID.consumableSetup)
      // console.log(this.props.location.state.scheduleID.consumableSetup);
      this.props.location.state.promotion.questions.forEach(function(e) {
        if (e.question === "product") products = e.items.split(",");
      });
      var stockitems = [];
      products.forEach(function(e) {
        stockitems.push({ stockItem: e.trim(), count: 0 });
      });
      // Utils.log(stockitems);
      if (this.props.location.state.scheduleID.stocktakevalues)
        stockitems = JSON.parse(
          this.props.location.state.scheduleID.stocktakevalues
        );

      products.forEach(a => {
        var found = false;
        stockitems.forEach(function(e) {
          if (e.stockItem === a.trim()) found = true;
        });
        if (!found) stockitems.push({ stockItem: a.trim(), count: 0 });
      });
      // Utils.log(stockitems);
      var g = null;
      if (this.props.location.state.scheduleID.gps === null) {
        g = localStorage.getItem(
          this.props.location.state.scheduleID.id + "gpsdone"
        );
      }
      var st = null;
      if (this.props.location.state.scheduleID.stocktakedone === null) {
        st = localStorage.getItem(
          this.props.location.state.scheduleID.id + "stocktakedone"
        );
      }
      var photosdone = [];
      if (
        this.props.location.state.scheduleID.promotionPhotos.items.length > 0
      ) {
        photosdone = this.props.location.state.scheduleID.promotionPhotos.items.map(
          a => {
            return a.phototype;
          }
        );
      }
      // console.log(photosdone)

      this.setState({
        consumablesetup: this.props.location.state.scheduleID.promotionID.client
          .consumablesetup!==null?(JSON.parse(
          this.props.location.state.scheduleID.promotionID.client
            .consumablesetup
        ).consumableAssetChips):[],
        consumablesetupcopy: this.props.location.state.scheduleID.promotionID.client
          .consumablesetup!==null?(JSON.parse(
          this.props.location.state.scheduleID.promotionID.client
            .consumablesetup
        ).consumableAssetChips):[],
        questions: this.props.location.state.promotion.questions,
        colours: this.props.location.state.promotion.colours,
        promotionID: this.props.location.state.promotion.id,
        photos: this.props.location.state.promotion.photos,
        userid: this.props.location.state.user.id,
        photosdone: photosdone,

        gps: g === null ? this.props.location.state.scheduleID.gps : g,
        stocktakedone:
          st === null ? this.props.location.state.scheduleID.stocktakedone : st,
        stockCount: stockitems,
        scheduleID: this.props.location.state.scheduleID.id
      });
    } catch (err) {
      this.props.history.push("/");
    }
  }

  checkValid(input) {
    var salevalid = false;
    var productvalid = false;
    this.performCleanup();
    input.forEach(function(e) {
      if (e.question === "Saletype" && e.response) salevalid = true;
    });
    input.forEach(function(e) {
      if (e.question === "product" && e.response) productvalid = true;
    });
    if (!salevalid)
      this.setState({ submitbuttontext: "Must select a sale or taste" });
    if (!productvalid)
      this.setState({ submitbuttontext: "Must select a product" });
    // Utils.log(salevalid);
    // Utils.log(productvalid);
    if (salevalid && productvalid) {
      this.setState({ submitbuttontext: "Submit" });
      return true;
    } else return false;
  }

  getIcon(input) {
    var output = null;
    if (input === "Sale") output = SaleIcon;
    if (input === "Basket") output = BasketIcon;
    if (input === "Trolley") output = TrolleyIcon;
    if (input === "None") output = HandIcon;
    if (input === "Male") output = ManIcon;
    if (input === "Female") output = WomanIcon;
    if (input === "Other") output = CoupleIcon;
    if (input === "Repeat") output = RepeatIcon;
    if (input === "First time") output = FirstTimeIcon;
    if (input === "Taste") output = TasteIcon;

    return output;
  }

  checkIfTextExists(resp, itm) {
    if (resp) {
      if (resp.indexOf(itm) > -1) return true;
    } else return false;
  }
  toggleText(resp, itm) {
    if (!resp) resp = "";
    if (resp.indexOf(itm) > -1) resp = resp.replace(itm + ",", "");
    else resp += itm + ",";
    return resp;
  }

  ToggleGroup(input, inputindex) {
    if (input.type === "toggle")
      return (
        <div key={inputindex}>
          {input.items.split(",").map((item, index) => (
            <Button
              style={{
                textTransform: "none",
                width:
                  input.items.split(",").length > 5
                    ? "20%"
                    : 100 / input.items.split(",").length + "%",
                //width: 100 / input.items.length + "%",
                height: item.trim().length < 13 ? 60 : 130,
                minHeight: 60,
                backgroundColor:
                  this.state.questions[inputindex].response === item.trim()
                    ? this.state.colours.activebackground
                    : this.state.colours.inactivebackground,
                color:
                  this.state.questions[inputindex].response === item.trim()
                    ? this.state.colours.activefont
                    : this.state.colours.inactivefont,
                marginBottom: 4
              }}
              key={index}
              variant="contained"
              onClick={() => {
                var newQuestion = this.state.questions;
                newQuestion[inputindex].response = item.trim();
                this.setState({ questions: newQuestion });
              }}
            >
              <img
                src={this.getIcon(item.trim())}
                alt={""}
                width={this.getIcon(item.trim()) ? 30 : 0}
                height={this.getIcon(item.trim()) ? 30 : 0}
              />
              {item.trim()}
            </Button>
          ))}
          {(this.state.questions[0].response === "Taste" ||
            this.state.questions[0].response === "Sale") &&
          inputindex === 0 ? (
            <div>
              <Table>
                <TableBody>
                  {this.state.consumablesetup.map((e, i) => (
                    <TableRow key={i}>
                      <TableCell style={{ padding: 0 }}>{e.item}</TableCell>
                      <TableCell style={{ padding: 0 }}>
                        <Button
                          onClick={() => {
                            var consumablesetup = this.state.consumablesetup;
                            consumablesetup[i].pattern =
                              parseInt(consumablesetup[i].pattern) - 1;
                            if (consumablesetup[i].pattern < 0)
                              consumablesetup[i].pattern = 0;
                            this.setState({ consumablesetup });
                          }}
                        >
                          <RemoveCircleOutlineIcon />
                        </Button>
                      </TableCell>
                      <TableCell style={{ padding: 0 }}>{e.pattern}</TableCell>
                      <TableCell style={{ padding: 0 }}>
                        <Button
                          onClick={() => {
                            var consumablesetup = this.state.consumablesetup;
                            consumablesetup[i].pattern =
                              parseInt(consumablesetup[i].pattern) + 1;
                            this.setState({ consumablesetup });
                          }}
                        >
                          <AddCircleOutlineIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          ) : null}
        </div>
      );
    else if (input.type === "multi")
      return (
        <div key={inputindex}>
          {input.items.split(",").map((item, index) => (
            <Button
              style={{
                textTransform: "none",
                width:
                  input.items.split(",").length > 5
                    ? "20%"
                    : 100 / input.items.split(",").length + "%",
                height: 60,
                backgroundColor: this.checkIfTextExists(
                  this.state.questions[inputindex].response,
                  item.trim()
                )
                  ? this.state.colours.activebackground
                  : this.state.colours.inactivebackground,
                color: this.checkIfTextExists(
                  this.state.questions[inputindex].response,
                  item.trim()
                )
                  ? this.state.colours.activefont
                  : this.state.colours.inactivefont,
                marginBottom: 4
              }}
              key={index}
              variant="contained"
              onClick={() => {
                var newQuestion = this.state.questions;
                newQuestion[inputindex].response = this.toggleText(
                  newQuestion[inputindex].response,
                  item.trim()
                );
                this.setState({ questions: newQuestion });
              }}
            >
              <img
                src={this.getIcon(item.trim())}
                alt={""}
                width={this.getIcon(item.trim()) ? 30 : 0}
                height={this.getIcon(item.trim()) ? 30 : 0}
              />
              {item.trim()}
            </Button>
          ))}
        </div>
      );
    else if (input.type === "additionalQuestions")
      return (
        <div key={inputindex} style={{ margin: 10 }}>
          {input.items}
          <TextField
            style={{ color: "#374799", width: "100%" }}
            id="outlined-name"
            label="Response"
            value={this.state.questions[inputindex].response || ""}
            onChange={change => {
              var newQuestion = this.state.questions;
              newQuestion[inputindex].response = change.target.value;
              this.setState({ questions: newQuestion });
            }}
            margin="normal"
            variant="outlined"
          />
        </div>
      );
  }

  ToggleGroupRating(input) {
    return input.map((item, index) => (
      <StarFull
        key={index}
        style={{
          width: 100 / input.length + "%",
          color:
            this.state.rating < item
              ? this.state.colours.inactivebackground
              : this.state.colours.activebackground
        }}
        fontSize="large"
        onClick={() => {
          this.setState({ rating: item });
        }}
      />
    ));
  }

  setQuestions(input) {
    return input.map((item, index) => this.ToggleGroup(item, index));
  }
  async savePicToS3(packet) {
    if (navigator.onLine && (await Utils.isFastInternet())) {
      let pic = await Utils.savePicToS3(packet);
      if (pic) {
        this.doSnackBar("Picture uploaded");
        var oldphotosdone = this.state.photosdone;
        oldphotosdone.push(packet.phototype);
        this.setState({ photosdone: oldphotosdone });
      } else this.doSnackBar("Upload failed");
      await Utils.cleanupOfflineData("savePicToS3");
      if (!isIOS) navigator.vibrate([100, 100, 100, 100, 100]);
    } else {
      try {
        Utils.addOffLineDataStore("savePicToS3", packet);
        if (!isIOS) navigator.vibrate(1000);
      } catch (err) {
        this.doSnackBar("Offline storage full, upload when online.");
      }
    }
  }

  async upload(input) {}

  doSnackBar(input) {
    this.setState({ statusbar: true, statusbartext: input });
  }

  async onTakePhoto(dataUri, type) {
    console.log(dataUri)
    this.doSnackBar("Processing picture");
    var packet = {};
    try {
      let r = await imageCompression.getFilefromDataUrl(dataUri);
      var s = await imageCompression(r, { maxSizeMB: 0.4 });
      var t = await imageCompression.getDataUrlFromFile(s);
      packet.picture = t;
      var extension = packet.picture
        .split(",")[0]
        .split(";")[0]
        .split(":")[1]
        .split("/")[1];
      this.doSnackBar("Uploading");
      packet.filename = Utils.generateName() + "." + extension;
      packet.userid = this.props.location.state.user.id;
      packet.promoterPlusPhotosScheduleId = this.state.scheduleID;
      var date = new Date(Date.now());
      date.setHours(date.getHours() + 2);
      packet.timestamp = date;
      packet.phototype = type;

      await this.savePicToS3(packet);
    } catch (err) {
      console.log(err);
    }
  }

  makeCameraButton(input) {
    return (
      <ImageUploader
        withIcon={false}
        buttonText={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <CameraIcon />
            {"Take " + input + " photo"}
            <WarningIcon
              style={{
                fontSize: this.state.photosdone.indexOf(input) === -1 ? 24 : 0
              }}
            />
            <DoneIcon
              style={{
                fontSize: this.state.photosdone.indexOf(input) > -1 ? 24 : 0
              }}
            />
          </div>
        }
        buttonClassName={"Button"}
        buttonStyles={{
          fontFamily: "Roboto",
          fontSize: 14,
          fontWeight: 500,
          width: "100%",
          borderRadius: 4,
          color: this.state.colours.inactivefont,
          backgroundColor: this.state.colours.inactivebackground,
          height: 60,
          margin: 1,
          padding: 0
        }}
        withPreview={false}
        label=""
        fileContainerStyle={{
          margin: 0,
          padding: 0,
          backgroundColor: "#eeeeee",
          shadowColor: "#ddd",
          borderWidth: 0,
          borderRadius: 0,
          borderColor: "#ddd",
          boxShadow: `0px 0px 0px 0`,
          display: "flex",
          flexDirection: "row",
          alignSelf: "center"
        }}
        singleImage={true}
        onChange={(pictureFiles, pictureDataURLs) => {
          this.onTakePhoto(pictureDataURLs[pictureDataURLs.length - 1], input);
        }}
        imgExtension={[".jpg", ".png", "jpeg"]}
        maxFileSize={7242880}
      />
    );
  }

  async updateGeoAPI(packet, user) {
    var today = new Date(Date.now());
    today.setHours(today.getHours() + 2);
    var newpacket = {};
    newpacket.date = today;
    newpacket.geo = {};
    newpacket.geo.lat = packet.coords.latitude;
    newpacket.geo.lng = packet.coords.longitude;
    var output = {};
    output.id = user;
    output.lastgeotag = newpacket;
    if (navigator.onLine && (await Utils.isFastInternet())) {
      await Utils.updateLastGeoTag(output);
      var cleanups = await Utils.cleanupOfflineData("updateLastGeoTag");
      if (!isIOS) navigator.vibrate([100, 100, 100, 100, 100]);
      this.setState({ offlineResponses: cleanups });
    } else {
      var offlineResponses = Utils.addOffLineDataStore(
        "updateLastGeoTag",
        output
      );
      if (!isIOS) navigator.vibrate(1000);
      this.setState({ offlineResponses: offlineResponses });
    }
    this.setState({ gps: "done" });
    localStorage.setItem(output.id + "gpsdone", true);
  }

  makeGPSButton() {
    return (
      <Button
        style={{
          textTransform: "none",
          backgroundColor: this.state.colours.inactivebackground,
          color: this.state.colours.inactivefont,
          marginBottom: 4,
          width: "100%",
          height: 60
        }}
        variant="contained"
        onClick={() => {
          if ("geolocation" in navigator) {
            window.navigator.geolocation.getCurrentPosition(success => {
              var datetime = new Date(Date.now());
              datetime.setHours(datetime.getHours() + 2);
              this.updateGeoAPI(success, this.state.scheduleID);
            });
          }
        }}
      >
        <GPSIcon />
        GPS
        <WarningIcon style={{ fontSize: this.state.gps ? 0 : 24 }} />
        <DoneIcon style={{ fontSize: this.state.gps ? 24 : 0 }} />
      </Button>
    );
  }

  showStockTakeProducts(input) {
    if (input) {
      return input.map((item, index) => (
        <div key={index} style={{ marginLeft: 20, marginRight: 20 }}>
          <TextField
            style={{ color: "#374799", width: "100%" }}
            id="outlined-name"
            key={index}
            label={item.stockItem}
              InputProps={{type:"number"}}
            value={this.state.stockCount[index].count || ""}
            onChange={change => {

              var newform = this.state.stockCount;
              try {
                newform[index].count = parseInt(change.target.value);

              } catch {
                newform[index].count = 0;
              }
              this.setState({ stockCount: newform });
            }}
            margin="normal"
            variant="outlined"
          />
        </div>
      ));
    } else return <div />;
  }

async doSignOffGPS(){
  if ("geolocation" in navigator) {
    window.navigator.geolocation.getCurrentPosition(success => {
      var today = new Date(Date.now());
      today.setHours(today.getHours() + 2);
      var newpacket = {};
      newpacket.date = today;
      newpacket.geo = {};
      newpacket.geo.lat = success.coords.latitude;
      newpacket.geo.lng = success.coords.longitude;
      var output = {};
      output.scheduleID = this.state.scheduleID;
      output.lastgeotag = newpacket;
      // console.log(output)
      Utils.updatesignoffLastGeoTag(output);
      // var datetime = new Date(Date.now());
      // datetime.setHours(datetime.getHours() + 2);
      // this.updateGeoAPI(success, this.state.scheduleID);
    });
  }
}

  async saveStocktake(input) {
    if (navigator.onLine && (await Utils.isFastInternet())) {
      await Utils.createPromotionStockTake(input);
      await Utils.cleanupOfflineData("createPromotionStockTake");
      if (!isIOS) navigator.vibrate([100, 100, 100, 100, 100]);
    } else {
      Utils.addOffLineDataStore("createPromotionStockTake", input);
      if (!isIOS) navigator.vibrate(1000);
    }
    this.setState({ stocktakedone: true });
    localStorage.setItem(this.state.scheduleID + "stocktakedone", true);
  }

  makeStockTakeSection(input) {
    if (this.state.stockTakeOpen) {
      return (
        <div>
          <Button
            style={{
              textTransform: "none",
              backgroundColor: this.state.colours.inactivebackground,
              color: this.state.colours.inactivefont,
              marginBottom: 4,
              height: 60,
              width: "100%"
            }}
            variant="contained"
            onClick={() => {
              this.setState({ stockTakeOpen: false });
            }}
          >
            <StockTakeIcon />
            Stock take
            <WarningIcon
              style={{ fontSize: this.state.stocktakedone ? 0 : 24 }}
            />
            <DoneIcon style={{ fontSize: this.state.stocktakedone ? 24 : 0 }} />
          </Button>
          <br />
          {this.showStockTakeProducts(input)}
          <Button
            style={{
              textTransform: "none",
              backgroundColor: this.state.colours.inactivebackground,
              color: this.state.colours.inactivefont,
              marginBottom: 4,
              height: 100,
              width: "100%"
            }}
            variant="contained"
            onClick={() => {
              this.saveStocktake({
                id: this.state.scheduleID,
                stocktakevalues: JSON.stringify(this.state.stockCount)
              });
              this.setState({ stockTakeOpen: false, stocktakedone: true });
            }}
          >
            Stock take completed
          </Button>
        </div>
      );
    } else {
      return (
        <Button
          style={{
            textTransform: "none",
            backgroundColor: this.state.colours.inactivebackground,
            color: this.state.colours.inactivefont,
            marginBottom: 4,
            height: 60,
            width: "100%"
          }}
          variant="contained"
          onClick={() => {
            this.setState({ stockTakeOpen: true });
          }}
        >
          <StockTakeIcon />
          Stock take
          <WarningIcon
            style={{ fontSize: this.state.stocktakedone ? 0 : 24 }}
          />
          <DoneIcon style={{ fontSize: this.state.stocktakedone ? 24 : 0 }} />
        </Button>
      );
    }
  }

  getSalesCounter(input) {
    var sale = localStorage.getItem(input + "Sale");
    if (!sale) sale = 0;
    if (sale > 0) return " Sale:" + sale + "";
    else return "";
  }
  getTasteCounter(input) {
    var sale = localStorage.getItem(input + "Taste");
    if (!sale) sale = 0;

    if (sale > 0) return " Taste:" + sale + "";
    else return "";
  }

  async saveResponse(input) {
    var scheduleID = this.state.scheduleID;
    input.forEach(function(e) {
      if (e.question === "Saletype" && e.response === "Sale") {
        var sale = localStorage.getItem(scheduleID + "Sale");
        if (!sale) sale = 1;
        else sale = parseInt(sale) + 1;
        localStorage.setItem(scheduleID + "Sale", sale);
      }
      if (e.question === "Saletype" && e.response === "Taste") {
        var taste = localStorage.getItem(scheduleID + "Taste");
        if (!taste) taste = 1;
        else taste = parseInt(taste) + 1;
        localStorage.setItem(scheduleID + "Taste", taste);
      }
    });

    var date = new Date(Date.now());
    date.setHours(date.getHours() + 2);
    var output = {};
    output.scheduleID = this.state.scheduleID;
    output.userid = this.state.userid;
    output.timestamp = date;
    output.response = JSON.stringify(input);
    if (navigator.onLine && (await Utils.isFastInternet())) {
      this.setState({ isonline: true });
      var consumed = [];
      this.state.consumablesetup.forEach((z, i) => {
        consumed.push({ item: z.item, amount: -z.pattern });
      });
      await Utils.createPromotionResponse(output);
      await Utils.createConsumeResponse({
        userID: this.state.userid,
        scheduleID: this.state.scheduleID,
        timestamp: date,
        usage: JSON.stringify(consumed),
        promoterPlusConsumedConsumableIDId: this.props.location.state.promotion.client
          .id
      });
      await Utils.cleanupOfflineData("createPromotionResponse");
      if (!isIOS) navigator.vibrate([100, 100, 100, 100, 100]);
    } else {
      Utils.addOffLineDataStore("createPromotionResponse", output);
      if (!isIOS) navigator.vibrate(1000);
    }
    this.setState({
      consumablesetup: JSON.parse (JSON.stringify(this.state.consumablesetupcopy))
    });
    input.forEach(function(e) {
      delete e.response;
    });
    var ratingindex = -1;
    input.forEach(function(e, index) {
      if (e.question === "rating") {
        ratingindex = index;
      }
    });
    if (ratingindex > -1) input.splice(ratingindex, 1);
    this.setState({ questions: input, rating: 0 });
    window.scrollTo(0, 0);
  }

  getCacheCount() {
    var count = localStorage.getItem("cacheCount");
    if (!count || count === "0") return "";
    else {
      return " (" + count + " offline records)";
    }
  }

  async performCleanup() {
    var online = await Utils.isFastInternet();
    this.setState({ isonline: online });
    if (navigator.onLine && online) {
      await Utils.cleanupOfflineData("savePicToS3");
      await Utils.cleanupOfflineData("createPromotionResponse");
      await Utils.cleanupOfflineData("updateLastGeoTag");
      await Utils.cleanupOfflineData("createPromotionStockTake");
      await Utils.cleanupOfflineData("signOffByScheduleId");
      this.setState({ offlineResponses: " " });
    }
  }

  render() {
    if (!this.state.questions) return <div />;
    else
      return (
        <StickyProvider>
          <div ref={container}>
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              open={this.state.statusbar}
              autoHideDuration={3000}
              onClose={() => this.setState({ statusbar: false })}
              ContentProps={{
                "aria-describedby": "message-id"
              }}
              message={<span id="message-id">{this.state.statusbartext}</span>}
            />
            <Button
              style={{
                textTransform: "none",
                width: "100%",
                borderRadius: 0,
                shadowColor: "#fff",
                height:
                  (this.state.isonline ? 65 : 85) +
                  60 *
                    this.state.photos
                      .filter(a => a.repeating === true)
                      .reduce(
                        (accumulator, currentValue) => accumulator + 1,
                        0
                      ),
                backgroundColor: "#fff",
                color: this.state.colours.activefont,
                marginBottom: 4
              }}
              variant="contained"
              onClick={() => {}}
            >
              spacer
            </Button>
            {this.state.gps ? <div /> : this.makeGPSButton()}
            {this.state.stocktakedone ? (
              <div />
            ) : (
              this.makeStockTakeSection(this.state.stockCount)
            )}
            {this.state.photos
              .filter(
                a =>
                  a.repeating === false &&
                  this.state.photosdone.indexOf(a.name) === -1
              )
              .map((a, index) => (
                <div key={index}>{this.makeCameraButton(a.name, a.name)}</div>
              ))}
            {this.setQuestions(this.state.questions)}
            {this.ToggleGroupRating(["1", "2", "3", "4", "5"])}

            {this.state.gps === null ? <div /> : this.makeGPSButton()}
            {!this.state.stocktakedone ? (
              <div />
            ) : (
              this.makeStockTakeSection(this.state.stockCount)
            )}
            {this.state.photos
              .filter(
                a =>
                  a.repeating === false &&
                  this.state.photosdone.indexOf(a.name) > -1
              )
              .map((a, index) => (
                <div key={index}>{this.makeCameraButton(a.name, a.name)}</div>
              ))}
            <div style={{ display: "flex", flexDirection: "row", height: 60 }}>
              <Button
                style={{
                  textTransform: "none",
                  backgroundColor: this.state.colours.inactivebackground,
                  color: this.state.colours.inactivefont,
                  marginBottom: 4,
                  width: "100%"
                }}
                variant="contained"
                onClick={() => {
                  this.props.history.push("/");
                }}
              >
                <BackIcon />
                Back to schedule
              </Button>

              <Button
                style={{
                  textTransform: "none",
                  backgroundColor: this.state.colours.inactivebackground,
                  color: this.state.colours.inactivefont,
                  marginBottom: 4,
                  width: "100%"
                }}
                variant="contained"
                onClick={() => {
                  this.doSignOffGPS()
                  this.props.history.push("/signoff", {
                    type: "signoff",
                    promotion: this.props.location.state.promotion,
                    user: this.props.location.state.user,
                    scheduleID: this.props.location.state.scheduleID
                  });
                }}
              >
                <ExitIcon />
                Sign off
              </Button>
            </div>
          </div>
          <Sticky container={container}>
            <Button
              style={{
                textTransform: "none",
                width: "100%",
                height: this.state.isonline ? 60 : 90,
                backgroundColor: this.state.colours.activebackground,
                color: this.state.colours.activefont,
                marginBottom: 4
              }}
              variant="contained"
              onClick={() => {
                if (this.checkValid(this.state.questions)) {
                  var response = this.state.questions;
                  response.push({
                    question: "rating",
                    type: "rating",
                    items: "Rating",
                    response: this.state.rating
                  });
                  this.saveResponse(response);
                }
              }}
            >
              {!this.state.isonline ? "Currently offline" : ""}
              {!this.state.isonline ? <br /> : ""}
              {this.state.submitbuttontext}
              {this.getCacheCount()}
              <br />
              Count to date:
              {this.getSalesCounter(this.state.scheduleID) +
                this.getTasteCounter(this.state.scheduleID)}
            </Button>
            {this.state.photos
              .filter(a => a.repeating === true)
              .map((a, index) => (
                <div key={index}>{this.makeCameraButton(a.name, a.name)}</div>
              ))}
          </Sticky>
        </StickyProvider>
      );
  }
}
export default withRouter(Action);
