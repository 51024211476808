import React, { PureComponent } from "react";
import { Auth } from "aws-amplify";
import { withRouter } from "react-router-dom";
import * as Sentry from "@sentry/browser";

// import { isMobile } from "react-device-detect";
import Utils from "./utils";
import { isMobile } from "react-device-detect";
import Dash2Filters from "./dash2filters";
import Promotertable from "./promotertableclient";
import ModalImage from "./modalimage";
import ModalStock from "./modalstock";
import ModalSignoff from "./modalsignoff";
import Button from "@material-ui/core/Button";

import DownIcon from "@material-ui/icons/ExpandMore";
import UpIcon from "@material-ui/icons/ExpandLess";
import ModalEditSales from "./modaleditsales";
import Paper from "@material-ui/core/Paper";
import Eye from "@material-ui/icons/RemoveRedEye";
import PromotionsMaps from "./mapsdashboard2";
import {
  Cell,
  Tooltip,
  PieChart,
  Pie,
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  // CartesianGrid,
  Legend
} from "recharts";
const COLORS = ["#F44336", "#8BC34A", "#FFC107", "#03A9F4"];
// import GraphPie from "./graphpie";
// import Button from "@material-ui/core/Button";
class PIcon extends React.Component {
  render() {
    return (
      <div
        style={{
          width: this.props.size,
          height: this.props.size,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: this.props.colour,
          color: "#000",
          borderRadius: 5,
          marginRight: 10
        }}
      >
        {this.props.icon}
      </div>
    );
  }
}
class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          style={{ fontSize: 12 }}
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
        >
          {payload.value}
        </text>
      </g>
    );
  }
}

class Blocks extends React.Component {
  render() {
    return (
      <div>
        <div
          style={{ fontSize: 20, justifyContent: "center", display: "flex" }}
        >
          <b>{this.props.header}</b>
        </div>
        <div
          style={{ fontSize: 40, justifyContent: "center", display: "flex" }}
        >
          <b>{this.props.main}</b>
        </div>
        <div
          style={{ fontSize: 14, justifyContent: "center", display: "flex" }}
        >
          {this.props.subtext + "  "}{" "}
          <div
            style={{
              marginLeft: 3,
              color: this.props.sugar.indexOf("-") > -1 ? "#f00" : "#0f0"
            }}
          >
            {this.props.sugar}
          </div>
        </div>
      </div>
    );
  }
}

class Desktop extends React.Component {
  constructor(props) {
    super(props);
    var promoenddate = new Date(Date.now()).toISOString().split("T")[0];

    // var promoenddate = (new Date("2021-01-01T00:00:00")).toISOString().split("T")[0];
    var enddate = new Date(promoenddate + "T23:59:59");
    var startdate = new Date(promoenddate + "T00:00:00");

    startdate.setHours(startdate.getHours() + 2);

    // console.log(startdate)
    enddate.setHours(enddate.getHours() + 2);
    this.state = {
      APIfilter: {},
      promotions: [],
      centre: {
        lat: -26.04731,
        lng: 28.095623
      },
      promotionsFiltered: [],
      modalImageIsOpen: false,
      modalImageImages: [],
      modalStockIsOpen: false,
      modalStockData: [],
      modalSignoffIsOpen: false,
      modalSignoffData: [],
      refreshInterval: 120,
      editModalOpen: false,
      editModalData: {
        sales: 0,
        taste: 0,
        userid: "",
        scheduleID: "",
        responses: []
      },
      filterOptions: {
        promolistenddate: new Date("2024-12-01T00:00:00"),
        promoliststartdate: new Date("2023-01-31T02:00:00"),
        products: [],
        promoters: [],
        promotions: [],
        regions: [],
        clients: [],
        promotersSelected: [],
        productsSelected: [],
        markers: [],
        regionsSelected: [],
        clientsSelected: [],
        firstdate: startdate,
        lastdate: enddate
      },
      photodlstatus: "0/0"
    };
    this.startTimer = this.startTimer.bind(this);
    this.stopTimer = this.stopTimer.bind(this);
    this.resetTimer = this.resetTimer.bind(this);
  }

  async initialise(startdate, enddate, fdt, ldt) {
    let p = await Auth.currentAuthenticatedUser();
    Sentry.setUser({ email: p.attributes.email });
    let t = await Utils.getUser(p.username);
    var APIfilter = {
      label: { eq: t.label },
      enddate: { le: enddate },
      startdate: { ge: startdate },
      cdates: { fdt: fdt, ldt: ldt }
    };

    if (t.access !== "all") APIfilter.client = { id: { eq: t.access } };
    this.setState({ APIfilter: APIfilter });
    // Utils.log("got here");
    // Utils.log(APIfilter)
    var promotions = await Utils.getAllPromotions2(APIfilter);
    // Utils.log("got here");
     // Utils.log(promotions);
    var filteroptions = this.getFilterOptions(promotions);
    var temppromo = JSON.parse(JSON.stringify(promotions));
    var results = this.applyFilters(temppromo, filteroptions);
    // console.log(JSON.stringify(filteroptions,null,4))
    this.setState({
      promotions: promotions,
      promotionsFiltered: results,
      filterOptions: filteroptions
    });
  }

  getFilterOptions(promos) {
    var promoters = [];
    var promotions = [];
    var products = [];
    var regions = [];
    var clients = [];
    promos.forEach(function(a) {
      clients.push(a.client.clientname);
      products = products.concat(a.products);
      promotions.push({
        client: a.client,
        colours: a.colours,
        selected: true,
        promotionName: a.promotionName,
        products: a.products.join(","),
        id: a.id,
        startdate: a.startdate.toISOString(),
        enddate: a.enddate.toISOString()
      });
      a.promotionScheduler.items.forEach(function(b) {
        // console.log(b)
        promoters.push(b.user.name);
        regions.push(b.location.region);
      });
    });
    // Utils.log(firstdate)
    // Utils.log(lastdate)
    var oldfilterOptionsAll = this.state.filterOptions;
    // oldfilterOptionsAll.firstdate = oldfilterOptionsAll.firstdate;
    // oldfilterOptionsAll.lastdate = oldfilterOptionsAll.lastdate;
    oldfilterOptionsAll.promotions = promotions;
    oldfilterOptionsAll.promoters = Utils.removeDuplicates(promoters);
    oldfilterOptionsAll.products = Utils.removeDuplicates(products);
    oldfilterOptionsAll.clients = Utils.removeDuplicates(clients);
    oldfilterOptionsAll.regions = Utils.removeDuplicates(regions);
    oldfilterOptionsAll.promotersSelected = Utils.removeDuplicates(promoters);
    oldfilterOptionsAll.productsSelected = Utils.removeDuplicates(products);
    oldfilterOptionsAll.clientsSelected = Utils.removeDuplicates(clients);
    oldfilterOptionsAll.regionsSelected = Utils.removeDuplicates(regions);
    // Utils.log(oldfilterOptionsAll);
    return oldfilterOptionsAll;
    // this.setState({ filterOptionsAll: oldfilterOptionsAll });
  }

  componentDidMount() {
    this.startTimer(this.state.refreshInterval);
    Auth.currentAuthenticatedUser().catch(() => {
      if (isMobile) this.props.history.push("/signinmobile");
      else this.props.history.push("/signin");
    });

    this.initialise(
      this.state.filterOptions.promoliststartdate,
      this.state.filterOptions.promolistenddate,
      this.state.filterOptions.firstdate,
      this.state.filterOptions.lastdate
    );
  }

  async refesh() {
    try {
      // console.log("refresh")
      var tempfilter = this.state.APIfilter;
      // console.log(tempfilter)
      tempfilter.cdates = {
        ldt: this.state.filterOptions.lastdate,
        fdt: this.state.filterOptions.firstdate
      };

      var promotions = await Utils.getAllPromotions2(tempfilter);
      // Utils.log(promotions);
      var filteroptions = this.state.filterOptions;
      var temppromo = JSON.parse(JSON.stringify(promotions));
      var results = this.applyFilters(temppromo, filteroptions);
      this.setState({
        promotions: promotions,
        promotionsFiltered: results
        // filterOptions: filteroptions
      });

      // var original = JSON.parse(JSON.stringify(this.state.promotions));
      // var filters = this.state.filterOptions
      // this.setState({
      //   promotionsFiltered: this.applyFilters(original, filters)
      // });
    } catch (err) {
      console.log("this is err");
      console.log(err);
    }
  }

  startTimer(interval) {
    this.setState({
      isOn: true,
      time: this.state.time,
      start: Date.now() - this.state.time
    });
    this.timer = setInterval(() => {
      this.refesh();
    }, interval * 1000);
  }

  stopTimer() {
    this.setState({ isOn: false });
    clearInterval(this.timer);
  }

  resetTimer() {
    this.setState({ time: 0, isOn: false });
  }

  applyFilters(input, filters) {
    var output = [];
    // Utils.log("filters");
    // Utils.log(filters);
    // Utils.log(input);
    input = JSON.parse(JSON.stringify(input));
    // Utils.log("test");
    // Utils.log(test);

    input.forEach(function(a) {
      filters.clientsSelected.forEach(function(b) {
        filters.promotions.forEach(function(c) {
          if ((c.id === a.id) & c.selected && b === a.client.clientname)
            output.push(a);
        });
      });
    });

    output.forEach(function(a) {
      var newitems = [];
      a.promotionScheduler.items.forEach(function(b) {
        // console.log(b.gps)
        filters.regionsSelected.forEach(function(c) {
          if (b.location.region === c) newitems.push(b);
        });
      });
      a.promotionScheduler.items = newitems;
    });
    output.forEach(function(a) {
      var newitems = [];
      a.promotionScheduler.items.forEach(function(b) {
        filters.promotersSelected.forEach(function(c) {
          if (b.user.name === c) newitems.push(b);
        });
      });
      a.promotionScheduler.items = newitems;
    });
    output.forEach(function(a) {
      a.promotionScheduler.items.forEach(function(b) {
        if (b.gps !== null) {
          if (typeof b.gps.date === "string") {
            b.gps.date = new Date(b.gps.date);
            b.gps.date.setHours(b.gps.date.getHours() + 2);
          }
        }
      });
    });
    output.forEach(function(a) {
      a.promotionScheduler.items.forEach(function(b) {
        var newitems = [];
        b.responses.items.forEach(function(c) {
          c.timestamp = new Date(c.timestamp);
          c.timestamp.setHours(c.timestamp.getHours() - 2);
          c.response.forEach(function(d) {
            filters.productsSelected.forEach(function(e) {
              if (d.question === "product" && d.response === e)
                newitems.push(c);
            });
          });
        });
        b.responses.items = newitems;
      });
    });
    var o2 = JSON.parse(JSON.stringify(output));
    o2.forEach(function(a) {
      var newitems = [];
      a.promotionScheduler.items.forEach(function(b) {
        // console.log(b.startdate)
        b.startdate = new Date(b.startdate);
        // b.startdate.setHours(b.startdate.getHours() + 2);
        // console.log(b.startdate)
        if (b.startdate > filters.firstdate && b.startdate < filters.lastdate)
          newitems.push(b);
      });
      a.promotionScheduler.items = newitems;
    });
    // console.log(JSON.stringify(o2,null,4))
    // var i2 = JSON.parse(JSON.stringify(input))

    // Utils.log("output");
    //  Utils.log(o2);
    return JSON.parse(JSON.stringify(o2));
  }

  async addNewResponses(sales, taste, userAndPromoID) {
    // console.log(sales)
    // console.log(taste)
    // console.log(userAndPromoID)
    var promoid = await Utils.getScheduleById(userAndPromoID.scheduleID);
    var prod = JSON.parse(promoid.promotionID.products);
    if (prod.indexOf(",") > -1) prod = prod.split(",")[0].trim();
    // console.log(prod)
    var date = new Date(Date.now());
    date.setHours(date.getHours() + 2);
    if (sales > 0) {
      for (var i = 0; i < sales; i++) {
        Utils.createPromotionResponse({
          scheduleID: userAndPromoID.scheduleID,
          userid: userAndPromoID.userid,
          timestamp: date,
          response: JSON.stringify([
            {
              items: "Sale,Taste",
              question: "Saletype",
              response: "Sale",
              type: "toggle"
            },
            {
              items: " n",
              question: "product",
              response: prod,
              type: "toggle"
            }
          ])
        });
      }
    }
    if (sales < 0) {
      var id = this.findIDtoDelete(userAndPromoID.responses, "Sale", sales);
      await Promise.all(id.map(Utils.deleteResponse));
      // Utils.log(deletes);
    }

    if (taste > 0) {
      for (i = 0; i < taste; i++) {
        Utils.createPromotionResponse({
          scheduleID: userAndPromoID.scheduleID,
          userid: userAndPromoID.userid,
          timestamp: date,
          response: JSON.stringify([
            {
              items: "Sale,Taste",
              question: "Saletype",
              response: "Taste",
              type: "toggle"
            },
            {
              items: " n",
              question: "product",
              response: prod,
              type: "toggle"
            }
          ])
        });
      }
    }
    if (taste < 0) {
      id = this.findIDtoDelete(userAndPromoID.responses, "Taste", taste);
      await Promise.all(id.map(Utils.deleteResponse));
      // Utils.log(deletes);
    }
    this.refesh();
    this.setState({ editModalOpen: false });
  }

  countSignIns(input) {
    var now = new Date(Date.now()).toISOString();
    var schedules = [];
    // console.log(input)
    input.forEach(function(pro) {
      pro.promotionScheduler.items.forEach(function(sch) {
        // sch.startdate.setHours(sch.startdate.getHours() - 2);
        schedules.push({
          done: sch.signindone,
          startdate: sch.startdate,
          started: sch.startdate > now ? false : true
        });
      });
    });
    var greens = 0;
    var reds = 0;
    var yellows = 0;
    schedules.forEach(function(s) {
      if (s.started === false) yellows += 1;
      if (s.started === true && s.done === true) greens += 1;
      if (s.started === true && s.done !== true) reds += 1;
    });
    var signinpie = [
      { name: "Not signed in", value: reds },
      { name: "Signed in", value: greens },
      { name: "Promotion not started", value: yellows }
    ];
    return signinpie;
  }

  countSchedules(input) {
    var now = new Date(Date.now()).toISOString();
    var schedules = [];
    input.forEach(function(pro) {
      pro.promotionScheduler.items.forEach(function(sch) {
        // sch.startdate.setHours(sch.startdate.getHours() - 2);
        schedules.push({
          done: sch.signindone,
          startdate: sch.startdate,
          started: sch.startdate > now ? false : true
        });
      });
    });

    return schedules.length;
  }

  countSchedulesCheckins(input) {
    var now = new Date(Date.now()).toISOString();
    var schedules = [];
    // console.log(input)
    input.forEach(function(pro) {
      pro.promotionScheduler.items.forEach(function(sch) {
        // sch.startdate.setHours(sch.startdate.getHours() - 2);
        if (sch.startdate <= now)
          schedules.push({
            gps: sch.gps,
            startdate: sch.startdate
          });
      });
    });
    var yets = 0;
    var lates = 0;
    schedules.forEach(function(s) {
      if (s.gps === null) yets += 1;
      else if (s.gps !== null) {
        // console.log(s.startdate)
        // var newstime = new Date( s.startdate)

        // console.log(newstime.toISOString())
        if (s.gps.date > s.startdate) lates += 1;
      }
    });
    // console.log(lates)
    lates = (lates / schedules.length) * 100;
    // console.log(lates)
    lates = lates.toFixed(0);
    // console.log(lates)

    if (isNaN(lates)) lates = 0;
    //     console.log(lates)
    // console.log(schedules)
    return { main: yets, sugar: lates + "%" };
  }

  countSchedulesSales(input) {
    var now = new Date(Date.now()).toISOString();
    // console.log(now)
    var sales = 0;
    var interactions = 0;
    // console.log(input)
    input.forEach(function(pro) {
      pro.promotionScheduler.items.forEach(function(sch) {
        // sch.startdate.setHours(sch.startdate.getHours() - 2);
        if (sch.startdate <= now) {
          sch.responses.items.forEach(function(res) {
            interactions += 1;
            res.response.forEach(function(itm) {
              if (itm.question === "Saletype" && itm.response === "Sale")
                sales += 1;
            });
          });
        }
      });
    });
    var inter = (sales / interactions) * 100;
    inter = inter.toFixed(0);
    if (isNaN(inter)) inter = 0;
    return { main: sales, sugar: inter + "%" };
  }

  countLowstocks(input) {
    var now = new Date(Date.now()).toISOString();
    // console.log(now)
    var counter = 0;

    // console.log(input);
    input.forEach(function(pro) {
      pro.promotionScheduler.items.forEach(function(sch) {
        // sch.startdate.setHours(sch.startdate.getHours() - 2);
        if (sch.startdate <= now) {
          var tempcount = 0;
          if (sch.stocktakevalues !== null) {
            sch.stocktakevalues.forEach(function(res) {
              if (res.count < 10) tempcount += 1;
            });
          }
          if (tempcount > 0) counter += 1;
        }
      });
    });

    return { main: counter, sugar: "%" };
  }

  countSignoffs(input) {
    var now = new Date(Date.now()).toISOString();
    // console.log(now)
    var signoffsdone = 0;
    var total = 0;
    // console.log(input);
    input.forEach(function(pro) {
      pro.promotionScheduler.items.forEach(function(sch) {
        // sch.startdate.setHours(sch.startdate.getHours() - 2);
        if (sch.startdate <= now) {
          total += 1;
          if (sch.signofftimestamp !== null) signoffsdone += 1;
        }
      });
    });
    var sug = ((total - signoffsdone) / total) * 100;
    sug = sug.toFixed(0);
    if (isNaN(sug)) sug = 0;
    return { main: signoffsdone, sugar: sug + "%" };
  }

  countPromoterPhotos(input) {
    var now = new Date(Date.now()).toISOString();
    var schedules = [];
    input.forEach(function(pro) {
      pro.promotionScheduler.items.forEach(function(sch) {
        // sch.startdate.setHours(sch.startdate.getHours() - 2);
        schedules.push({
          done: sch.promoterpicdone,
          startdate: sch.startdate,
          started: sch.startdate > now ? false : true
        });
      });
    });
    var greens = 0;
    var reds = 0;
    var yellows = 0;
    schedules.forEach(function(s) {
      if (s.started === false) yellows += 1;
      if (s.started === true && s.done === true) greens += 1;
      if (s.started === true && s.done !== true) reds += 1;
    });
    var signinpie = [
      { name: "Promoter has not taken a photo", value: reds },
      { name: "Promoter photo done", value: greens },
      { name: "Promotion not started", value: yellows }
    ];
    return signinpie;
  }

  countStandPhotos(input) {
    var now = new Date(Date.now()).toISOString();
    var schedules = [];
    input.forEach(function(pro) {
      pro.promotionScheduler.items.forEach(function(sch) {
        // sch.startdate.setHours(sch.startdate.getHours() - 2);
        schedules.push({
          done: sch.promoterpicdone,
          startdate: sch.startdate,
          started: sch.startdate > now ? false : true
        });
      });
    });
    var greens = 0;
    var reds = 0;
    var yellows = 0;
    schedules.forEach(function(s) {
      if (s.started === false) yellows += 1;
      if (s.started === true && s.done === true) greens += 1;
      if (s.started === true && s.done !== true) reds += 1;
    });
    var signinpie = [
      { name: "Promoter has not taken a stand photo", value: reds },
      { name: "Promoter stand photo done", value: greens },
      { name: "Promotion not started", value: yellows }
    ];
    return signinpie;
  }

  countOtherPhotos(input) {
    // console.log(input);
    var now = new Date(Date.now()).toISOString();
    // console.log(now);
    var schedules = [];
    input.forEach(function(pro) {
      pro.promotionScheduler.items.forEach(function(sch) {
        // sch.startdate.setHours(sch.startdate.getHours() - 2);
        schedules.push({
          done: sch.otheritemsdone,
          startdate: sch.startdate,
          started: sch.startdate > now ? false : true
        });
      });
    });

    var greens = 0;
    var reds = 0;
    var yellows = 0;

    schedules.forEach(function(s) {
      if (s.started === false) yellows += 1;
      if (s.started === true && s.done === true) greens += 1;
      if (s.started === true && s.done !== true) reds += 1;
    });

    var signinpie = [
      { name: "Promoter has not taken a customer photo", value: reds },
      { name: "Promoter customer photo done", value: greens },
      { name: "Promotion not started", value: yellows }
    ];
    return signinpie;
  }

  getProductMix(input) {
    var responses = [];
    input.forEach(a => {
      a.promotionScheduler.items.forEach(b => {
        b.responses.items.forEach(c => {
          c.response.forEach(d => {
            if (d.question === "Saletype" && d.response === "Sale")
              responses.push(c.response.filter(e => e.question === "product"));
          });
        });
      });
    });
    var products = [];
    responses.forEach(a => {
      products.push({ name: a[0].response, value: 1 });
    });

    // console.log(products);
    var productsnew = [];
    var productnames = products.map(a => a.name);
    productnames = [...new Set(productnames)];
    productnames.forEach(a => {
      var name = a;
      var total = 0;
      products.forEach(b => {
        if (b.name === name) total += 1;
      });
      productsnew.push({ name: name, value: total });
    });
    // console.log(productsnew);

    return productsnew;
  }

  getSales(input) {
    var responses = [];
    input.forEach(a => {
      a.promotionScheduler.items.forEach(b => {
        b.responses.items.forEach(c => {
          c.response.forEach(d => {
            if (d.question === "Saletype" && d.response === "Sale")
              responses.push(new Date(c.timestamp));
          });
        });
      });
    });
    responses.forEach(a => {
      a = a.setHours(a.getHours() + 2);
    });

    var min = new Date("2099-01-01T05:05:05.000Z");
    var max = new Date("2000-01-01T05:05:05.000Z");
    responses.forEach(a => {
      if (a < min) min = new Date(a);
      if (a > max) max = new Date(a);
    });
    min.setHours(6);
    min.setMinutes(0);
    min.setSeconds(0);
    max.setHours(19);
    max.setMinutes(0);
    max.setSeconds(0);
    var salesArray = [];
    var counter = min;
    var total = 0;
    while (counter <= max) {
      var nexthour = new Date(counter);
      nexthour.setHours(nexthour.getHours() + 1);
      responses.forEach(a => {
        // console.log(a)
        if (a < nexthour && a >= counter) total += 1;
      });
      salesArray.push({ time: counter, Sales: total });
      counter = nexthour;
    }
    salesArray.forEach(a => {
      a.time.setHours(a.time.getHours() + 2);
      a.time = a.time
        .toISOString()
        .substring(0, 16)
        .replace("T", " ");
    });
    return salesArray;
  }
  filterSection(input) {
    if (!input)
      return (
        <Button
          variant="contained"
          style={{ color: "#222", backgroundColor: "#1da4f4" }}
          color="primary"
          onClick={() => this.setState({ filtersection: true })}
        >
          Filter <DownIcon />
        </Button>
      );
    else
      return (
        <Button
          style={{ color: "#222", backgroundColor: "#1da4f4" }}
          variant="outlined"
          color="primary"
          onClick={() => this.setState({ filtersection: false })}
        >
          Close <UpIcon />
        </Button>
      );
  }
  render() {
    console.log(this.state.filterOptions)
    return (
      <div
        style={{
          color: "#666",
          margin: isMobile ? 0 : 10,
          backgroundColor: "#eeeeee"
        }}
      >

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginLeft: 10,
            marginRight: 10
          }}
        >
          <h1
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}
          >
            <PIcon
              icon={<Eye style={{ fontSize: 37 }} />}
              size="40px"
              colour="#4890e2"
            />
            Dashboard
          </h1>
          <div>{this.filterSection(this.state.filtersection)}</div>
        </div>
        <ModalImage
          isOpen={this.state.modalImageIsOpen}
          closeMe={() => this.setState({ modalImageIsOpen: false })}
          images={this.state.modalImageImages}
        />
        <ModalStock
          isOpen={this.state.modalStockIsOpen}
          closeMe={() => this.setState({ modalStockIsOpen: false })}
          data={this.state.modalStockData}
        />
        <ModalSignoff
          isOpen={this.state.modalSignoffIsOpen}
          closeMe={() => this.setState({ modalSignoffIsOpen: false })}
          data={this.state.modalSignoffData}
        />
        <ModalEditSales
          isOpen={this.state.editModalOpen}
          closeMe={() =>
            this.setState({
              editModalOpen: false,
              editModalData: {
                sales: 0,
                taste: 0,
                userid: "",
                scheduleID: "",
                responses: []
              }
            })
          }
          data={this.state.editModalData}
          updateSalesData={data => {
            this.addNewResponses(
              data.editModalDataSales,
              data.editModalDataTaste,
              data.editModalData
            );
            this.setState({
              editModalData: {
                sales: 0,
                taste: 0,
                userid: "",
                scheduleID: "",
                responses: []
              }
            });
          }}
        />  {this.state.filtersection ? (
        <Dash2Filters
          initialOptions={this.state.filterOptions}
          expanded={this.state.filtersection}
          onExpand={input => this.setState({ filtersection: input })}
          filterOutput={change => {
            var original = JSON.parse(JSON.stringify(this.state.promotions));

            this.setState({
              filterOptions: change,
              promotionsFiltered: this.applyFilters(original, change)
            });
          }}
          campaignFilterOutput={change => {
            this.setState({ filterOptions: change });
            this.initialise(
              change.promoliststartdate,
              change.promolistenddate,
              change.firstdate,
              change.lastdate
            );
          }}
          goToPromotion={id => {
            this.props.history.push("/promotions?edit=" + id);
          }}
        />) : (
          <div />
        )}
        <div
          style={{
            margin: 0,
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            marginTop: 30,
            marginBottom: 30
          }}
        >
          <Paper
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-around"
            }}
          >
            <div
              style={{
                marginLeft: 10,
                borderTopColor: "#fff",
                borderTopWidth: 0,
                borderLeftWidth: 0,
                borderRightWidth: 0,
                width: "98%",
                borderColor: "#eee",
                borderStyle: "solid"
              }}
            >
              <h3>Performance overview</h3>
            </div>
            <div
              style={{
                padding: 30,
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                width: "95%",
                justifyContent: "space-around"
              }}
            >
              <Blocks
                header={"Total promos"}
                main={this.countSchedules(this.state.promotionsFiltered)}
                subtext={" "}
                sugar={""}
              />
              <Blocks
                header={"Yet to check in"}
                main={
                  this.countSchedulesCheckins(this.state.promotionsFiltered)
                    .main
                }
                subtext={"Late promoters"}
                sugar={
                  this.countSchedulesCheckins(this.state.promotionsFiltered)
                    .sugar
                }
              />
              <Blocks
                header={"Sales"}
                main={
                  this.countSchedulesSales(this.state.promotionsFiltered).main
                }
                subtext={"Converstion rate"}
                sugar={
                  this.countSchedulesSales(this.state.promotionsFiltered).sugar
                }
              />
              <Blocks
                header={"Signed off"}
                main={this.countSignoffs(this.state.promotionsFiltered).main}
                subtext={"Yet to sign off"}
                sugar={this.countSignoffs(this.state.promotionsFiltered).sugar}
              />
              <Blocks
                header={"Low stock"}
                main={this.countLowstocks(this.state.promotionsFiltered).main}
                subtext={"Stores have low stock"}
                sugar={""}
              />
            </div>
          </Paper>
        </div>
        <div
          style={{
            margin: 0,
            display: "flex",
            flexDirection: "row"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",

              minWidth: "50%",
              color: "#ddd"
            }}
          >
            <Promotertable
              data={this.state.promotionsFiltered}
              showImages={images => {
                this.setState({
                  modalImageIsOpen: true,
                  modalImageImages: images
                });
              }}
              showStock={data => {
                this.setState({ modalStockIsOpen: true, modalStockData: data });
              }}
              showSignoff={data => {
                this.setState({
                  modalSignoffIsOpen: true,
                  modalSignoffData: data
                });
              }}
              showEditModal={data => {
                this.setState({ editModalOpen: true, editModalData: data });
              }}
            />
          </div>
          <div style={{}}>
            <Paper
              style={{
                padding: 10,
                marginBottom: 30,
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap"
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "#666"
                }}
              >
                <b>Product Mix</b>
              </div>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Legend />
                  <Pie
                    dataKey="value"
                    data={this.getProductMix(this.state.promotionsFiltered)}
                  >
                    {this.getProductMix(this.state.promotionsFiltered).map(
                      (entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      )
                    )}
                  </Pie>
                  <Tooltip
                    itemStyle={{ color: "#666" }}
                    contentStyle={{ color: "#666" }}
                  />
                </PieChart>
              </ResponsiveContainer>
            </Paper>
          </div>
        </div>
        <Paper
          style={{
            padding: 10,
            paddingBottom: 20,
            marginBottom: 30,
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap"
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              color: "#666"
            }}
          >
            <b>Run Rate</b>
          </div>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={this.getSales(this.state.promotionsFiltered)}>
              <XAxis
                dataKey="time"
                height={80}
                minTickGap={0}
                interval={0}
                tick={<CustomizedAxisTick />}
              />
              <YAxis width={80} />
              <Line
                type="monotone"
                dataKey="Sales"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              ></Line>
              <Tooltip
                itemStyle={{ color: "#666" }}
                contentStyle={{ color: "#666" }}
              />
            </LineChart>
          </ResponsiveContainer>
        </Paper>
        <PromotionsMaps
          markers={this.state.promotionsFiltered}
          centre={this.state.centre}
        />
      </div>
    );
  }
}
export default withRouter(Desktop);
