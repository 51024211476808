import React from "react";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import { Auth } from "aws-amplify";
import Utils from "./utils";
import StartCampIcon from "@material-ui/icons/FlashOn";
import AddUserIcon from "@material-ui/icons/PersonAdd";
import ExitIcon from "@material-ui/icons/ExitToApp";
import ReportIcon from "@material-ui/icons/Dashboard";
import Eye from "@material-ui/icons/RemoveRedEye";

import Navimg from "./navlogo.jpg";

class PIcon extends React.Component {
  render() {
    return (
      <div
        style={{
          width: this.props.size,
          height: this.props.size,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: this.props.colour,
          color: "#000",
          borderRadius: 5
        }}
      >
        {this.props.icon}
      </div>
    );
  }
}

class Nav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: ".",
      email: "",
      type: "",
      access: "",
      isAdmin: false,
      mini: true,
      label: "",
      labellogo: ""
    };
  }
  _element = React.createRef();
  async componentDidMount() {
    try {
      let s = await Auth.currentAuthenticatedUser();
      // Utils.log(s);
      let t = await Utils.getUser(s.username);
      // Utils.log(t);
      let ll = await Utils.getAllLabels();
      ll.forEach(function(e) {
        if (e.label === t.label)
          t.logo = e.config.split(",")[0].replace("logo=", "");
      });
      Utils.log(t);
      var admin = false;
      if (t.type === "Admin") admin = true;
      this.setState({
        name: t.name,
        email: s.attributes.email,
        type: t.type,
        access: t.access,
        isAdmin: admin,
        label: t.label,
        labellogo: t.logo,
        labelColour: t.label === "All" ? "#f00" : "888",
        userid: t.id
      });
      // console.log(this._element.current.clientWidth)
      this.props.getWidth(this._element.current.clientWidth);
    } catch (err) {
      console.log(err);
    }
  }

  ButtonMaker(input) {
    return input.map((item, index) => (
      <Button
        key={index}
        style={{
          justifyContent: "flex-start",
          textTransform: "none",
          color:
            this.props.history.location.pathname === item.location
              ? item.colour
              : "#888"
        }}
        onClick={() => {
          this.props.setPageName(item.name);
          this.props.history.push(item.location);
        }}
      >
        {item.icon}

        <div style={{ marginLeft: 10 }}>{item.name}</div>
      </Button>
    ));
  }
  async switch(input) {
    let user = { id: this.state.userid, label: input };
    await Utils.switchUserAccess(user);
    window.location.reload(false);
  }

  SmallButtonMaker(input) {
    return input.map((item, index) => (
      <Button
        key={index}
        style={{
          justifyContent: "flex-start",
          textTransform: "none"
        }}
        disabled={true}
      >
        {item.icon}
        {this.state.mini ? (
          ""
        ) : (
          <div style={{ marginLeft: 10 }}>{item.text}</div>
        )}
      </Button>
    ));
  }
  // {
  //   name: "Operational Dashboard",
  //   location: "/operations",
  //   icon: <OperationIcon />
  // },
  render() {
    return (
      <div
        style={{
          flexDirection: "column",
          display: "flex",
          width: this.state.mini ? 150 : 250,
          backgroundColor: "#222",
          color: "#ddd",
          height: "100%"
        }}
        ref={this._element}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center"
          }}
        >
          <img src={Navimg} alt="asd" width="100%"></img>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            margin: -20,
            maxWidth: 150
          }}
        >
          {this.state.name.substr(0, 20)}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            margin: 10
          }}
        ></div>

        {this.ButtonMaker([
          {
            name: "Dashboard",
            location: "/",
            icon: <PIcon icon={<Eye />} size="30px" colour="#4890e2" />,
            colour: "#4890e2"
          },

          {
            name: "Campaign",
            location: "/promotionsentry",
            icon: (
              <PIcon icon={<StartCampIcon />} size="30px" colour="#7ed221" />
            ),
            colour: "#7ed221"
          },

          {
            name: "Reports",
            location: "/report",
            icon: <PIcon icon={<ReportIcon />} size="30px" colour="#f5a623" />,

            colour: "#f5a623"
          },
          {
            name: "Users",
            location: "/inviteuser",
            icon: <PIcon icon={<AddUserIcon />} size="30px" colour="#f52352" />,

            colour: "#f52352"
          }
        ])}

        <Button
          style={{
            width: this.state.mini ? 150 : 160,
            color: "#888",
            justifyContent: "flex-start",
            textTransform: "none"
          }}
          onClick={() => {
            this.props.setPageName("Sign in");
            Auth.signOut();
            this.props.history.push("/signin");
            window.location.reload();
          }}
        >
          <PIcon icon={<ExitIcon />} size="30px" colour="#888" />
          <div style={{ marginLeft: 10 }}>Sign out</div>
        </Button>
        {this.state.userid === "14c77419-6109-4cf4-bbcc-b12f9ea221b8" ? (
          <div>
            <button onClick={() => this.switch("LWC")}>switch to lwc</button>
            <button onClick={() => this.switch("TEST")}>switch to test</button>
            <button onClick={() => this.switch("Promoter Plus")}>
              switch to promoterplus
            </button>
          </div>
        ) : null}
      </div>
    );
  }
}
export default withRouter(Nav);
