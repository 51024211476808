import React from "react";
import { withRouter } from "react-router-dom";
// import Utils from "./utils";
import GoogleMapReact from "google-map-react";
import Tooltip from "@material-ui/core/Tooltip";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import LocationCityIcon from "@material-ui/icons/LocationCity";

const AnyReactComponent = ({ text }) => (
  <Tooltip title={text} enterTouchDelay={700}>
    <LocationCityIcon
      style={{ marginLeft: -11, marginTop: -11, color: "#666" }}
    />
  </Tooltip>
);

function cleaninventory(input) {
  function makelist(arr) {
    return arr
      .sort((a, b) => {
        if (a.item < b.item) return -1;
        else if (a.item > b.item) return 1;
        else return 0;
      })
      .map((z, i) => (
        <div
          key={i}
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row"
          }}
        >
          <div style={{marginRight: 5}}>{z.item}</div>
          <div>{z.amount}</div>
        </div>
      ));
  }
  if (typeof input[0] !== "undefined") return makelist(input[0].usage);
  else return "";
}

const PersonReactComponent = ({ text, inventory }) => (
  <Tooltip
    title={
      <div>
        {text}
        <br />
        {cleaninventory(inventory)}
      </div>
    }
    enterTouchDelay={700}
  >
    <PersonPinIcon style={{ marginLeft: -11, marginTop: -11, color: "#00f" }} />
  </Tooltip>
);

class PromotionsMaps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  makeMapMarkers(input) {
    return input.map((item, index) => (
      <AnyReactComponent
        key={index}
        lat={item.location.geo.lat}
        lng={item.location.geo.lng}
        text={item.label}
      />
    ));
  }

  makeUserMarkers(input, inventory) {
    return input.filter(z=>typeof z.homelocation.geo !== "undefined").map((item, index) => (

      <PersonReactComponent
  key={index}
        lat={item.homelocation.geo.lat}
        lng={item.homelocation.geo.lng}
        text={item.name}
        inventory={inventory.filter(z => z.userID === item.id)}
      />


    ));

  }

  render() {
    return (
      <div style={{ height: "100vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyDqX0qSKZ-GHCA-_7FFmtAsFtC_INd9UR4"
          }}
          defaultCenter={{
            lat: -26.04731,
            lng: 28.095623
          }}
          defaultZoom={11}
        >
          {this.makeUserMarkers(this.props.users, this.props.liveConsumeData)}
          {this.makeMapMarkers(this.props.markers)}
        </GoogleMapReact>
      </div>
    );
  }
}
export default withRouter(PromotionsMaps);
