import React from "react";
import { Auth } from "aws-amplify";
import { withRouter } from "react-router-dom";
// import { isMobile } from "react-device-detect";
import Utils from "./utils";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
// import IntegrationAutosuggest from "./autosuggest";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
// import PromotionsMaps from "./promotionsmaps";
import StartCampIcon from "@material-ui/icons/FlashOn";
import DateIcon from "@material-ui/icons/DateRange";
import QuestionIcon from "@material-ui/icons/LiveHelp";
import PhotoIcon from "@material-ui/icons/PhotoCamera";
import CupIcon from "@material-ui/icons/LocalCafe";
import LocationIcon from "@material-ui/icons/AddLocation";
import Snackbar from "@material-ui/core/Snackbar";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import PromotionsPromoters from "./promotionspromoters2";
import SearchFilter from "./searchfilter";
import PromotionsQuestions from "./promotionsquestions";
import PromotionsQuestionsPhotos from "./promotionsquestionsphotos";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { SwatchesPicker } from "react-color";
import Button from "@material-ui/core/Button";
// import MenuIcon from "@material-ui/icons/Menu";
import AddIcon from "@material-ui/icons/Add";

const AnyReactComponent = ({ text }) => (
  <div style={{ fontSize: 20 }}>{text}</div>
);

class PIcon extends React.Component {
  render() {
    return (
      <div
        style={{
          width: this.props.size,
          height: this.props.size,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: this.props.colour,
          color: "#000",
          borderRadius: 5,
          marginRight: 10,
        }}
      >
        {this.props.icon}
      </div>
    );
  }
}

class Promotions extends React.Component {
  constructor(props) {
    super(props);
    var date = new Date(Date.now());
    date.setHours(date.getHours() + 2);
    this.state = {
      res: [],
      statusbarstayopen: false,
      promotionName: "",
      startDate: date,
      endDate: date,
      startTime: "07:00",
      endTime: "17:00",
      client: "",
      productline: "",
      products: "",
      fixedAssetChips: ["Table", "Coffee Machine", "Charger"],
      fixedAssetChipText: "",
      consumableAssetChips: ["Cup", "Spoon", "BSugar", "WSugar"],
      consumableAssetPattern: ["1", "1", "0", "1"],
      consumableAssetChipText: "",
      consumablehistory: [],
      consumableAdjustments: [],
      consumablehistoryheader: [],
      promoters: [],
      users: [],
      allclients: [],
      photos: [
        { name: "Sign in", repeating: false, order: 0 },
        { name: "Promoter", repeating: false, order: 1 },
        { name: "Stand", repeating: false, order: 2 },
        { name: "Other photos", repeating: true, order: 3 },
      ],
      questions: [
        {
          question: "Saletype",
          type: "toggle",
          items: "Sale,Taste",
        },
        {
          question: "product",
          type: "toggle",
          items: "This will come from the promotion details section",
        },
        {
          question: "repeattype",
          type: "toggle",
          items: "First time, Repeat",
        },
        {
          question: "carttype",
          type: "toggle",
          items: "Basket, Trolley, None",
        },
        {
          question: "gender",
          type: "toggle",
          items: "Female, Other, Male",
        },
        {
          question: "race",
          type: "toggle",
          items: "Asian, Black, Coloured, Indian, White",
        },
        {
          question: "taste",
          type: "multi",
          items: "Sour, Salty, Sweet, Bitter, Spicy",
        },
        {
          question: "age",
          type: "toggle",
          items: "16-20, 21-30, 31-50, 51+",
        },
        {
          type: "additionalQuestions",
          question: "additionalQuestions",
          items: "How much did the customer ...?",
        },
        {
          type: "additionalQuestions",
          question: "additionalQuestions",
          items: "How much did the customer ...?",
        },
      ],
      allproductlines: [],
      colours: {
        background: "#fff",
        backgroundfont: "#000",
        inactivebackground: "#90a4ae",
        inactivefont: "#000",
        activebackground: "#0d47a1",
        activefont: "#fff",
      },
      status: "",
      consumableSetup: [],
      locations: [],
      edit: false,
      editid: "",
      label: "",
      alert: "",
    };
  }
  doSnackBar(input, statusbarstayopen) {
    this.setState({ statusbar: true, statusbartext: input, statusbarstayopen });
  }

  validationCheck() {
    var valid = true;
    if (this.state.promoters.length === 0) {
      this.doSnackBar("No promoters added...");
      valid = false;
    } else if (this.state.questions.length === 0) {
      this.doSnackBar("No questions added...");
      valid = false;
    } else if (this.state.promotionName === "") {
      this.doSnackBar("No promotion name...");
      valid = false;
    } else if (this.state.client === "") {
      this.doSnackBar("No client selected...");
      valid = false;
    } else if (this.state.productline === "") {
      this.doSnackBar("No productline selected...");
      valid = false;
    } else if (this.state.products === "") {
      this.doSnackBar("No products selected...");
      valid = false;
    }
    var d1 =
      this.state.startDate.toISOString().substr(0, 10) + "T00:00:00.000Z";
    var sd1 = new Date(d1);
    var d2 = this.state.endDate.toISOString().substr(0, 10) + "T23:59:59.000Z";
    var ed1 = new Date(d2);
    if (ed1 < sd1) {
      this.doSnackBar("Campaign dates invalid");
      valid = false;
    }

    this.state.promoters.forEach((z, idx) => {
      if (typeof z.promoterLocation.geo === "undefined") {
        this.doSnackBar(
          "Promoter " + z.promoterName + idx + " location invalid",
          true
        );
        valid = false;
      }
      var d3 = z.promoterStartDate + "T" + z.promoterStartTime;
      var sd2 = new Date(d3);
      if (sd2 < sd1 || sd2 > ed1) {
        this.doSnackBar(
          "Promoter " + z.promoterName + " dates not within campaign dates",
          true
        );
        valid = false;
      }
    });

    return valid;
  }

  async saveConsumeAdjustments() {
    var s = this.state.consumableAdjustments;
    s.forEach((z) => {
      z.usage = JSON.stringify([{ item: z.element, amount: -z.value }]);
      delete z.element;
      delete z.value;
      z.scheduleID = "adjustment";
      z.promoterPlusConsumedConsumableIDId = this.state.editid;
      z.timestamp = new Date(Date.now());
      z.userID = z.id;
      delete z.id;
    });
    this.doSnackBar("Saving please wait...");
    await Promise.all(s.map(Utils.createConsumeResponse));
    this.doSnackBar("Refreshing...");
    this.getConsumableData();
  }

  async getConsumableData() {
    if (!this.state.editid) {
      console.log(this.state.promoters);
      var promoterids = this.state.promoters.map((a) => a.promoterId);
      promoterids = [...new Set(promoterids)];
      var items = [];
      promoterids.forEach((z) => {
        items.push({
          userID: z,
          scheduleID: "new",
          usage: [],
          timestamp: new Date(Date.now()),
        });
      });
    }
    if (this.state.editid) {
      this.doSnackBar("Collecting transactions", true);
      let con = await Utils.getConsumableData(this.state.editid, null);
      items = [];
      items = items.concat(con.usage.items);
      while (con.usage.nextToken !== null) {
        this.doSnackBar("Collecting transactions", true);
        con = await Utils.getConsumableData(
          this.state.editid,
          con.usage.nextToken
        );
        items = items.concat(con.usage.items);
      }
    }
    promoterids = this.state.promoters.map((a) => a.promoterId);
    promoterids = [...new Set(promoterids)];
    var oldids = [];
    items.forEach((z) => {
      oldids.push(z.userID);
    });
    oldids = [...new Set(oldids)];
    var missing = [];
    promoterids.forEach((a) => {
      var found = false;
      oldids.forEach((b) => {
        if (a === b) found = true;
      });
      if (!found) missing.push(a);
    });
    missing = [...new Set(missing)];
    missing.forEach((z) => {
      items.push({ userID: z, scheduleID: "new", usage: [] });
    });

    var newitems = [];
    items.forEach((z) => {
      if (
        newitems.filter(
          (a) => a.userID === z.userID && a.scheduleID === z.scheduleID
        ).length === 0
      ) {
        newitems.push(z);
      } else {
        newitems.forEach((a) => {
          if (a.userID === z.userID && a.scheduleID === z.scheduleID) {
            a.usage = a.usage.concat(z.usage);
          }
        });
      }
    });

    newitems.forEach((z) => {
      z.timestamp = new Date(z.timestamp);
      var result = Object.values(
        z.usage.reduce((c, { item, amount }) => {
          c[item] = c[item] || { item, amount: 0 };
          c[item].amount += amount;
          return c;
        }, {})
      );
      result.forEach((p) => {
        z[p.item] = p.amount;
      });
      delete z.usage;
      delete z.id;
      delete z.scheduleID;
    });
    items = newitems;

    items.forEach((z) => {
      z.userID = this.state.users.filter((p) => p.id === z.userID)[0];
    });
    newitems = newitems.sort((a, b) => {
      if (
        a.userID.name + a.timestamp.toDateString() <
        b.userID.name + b.timestamp.toDateString()
      )
        return 1;
      else if (
        a.userID.name + a.timestamp.toDateString() >
        b.userID.name + b.timestamp.toDateString()
      )
        return -1;
      else return 0;
    });

    var consumablehistoryheader = this.state.fixedAssetChips.concat(
      this.state.consumableAssetChips
    );

    var itemswithtotals = [];
    var total = {};
    // console.log(JSON.stringify(items,null,4))

    items.forEach((z, i) => {
      total.timestamp = new Date(Date.now());
      consumablehistoryheader.forEach((a) => {
        if (isNaN(z[a]) || typeof z[a] === "undefined") z[a] = 0;
        z[a] = -z[a];
        if (typeof total[a] === "undefined") total[a] = z[a];
        else total[a] += z[a];
        if (typeof total[a] === "undefined") total[a] = 0;
        if (isNaN(total[a])) total[a] = 0;
      });
      itemswithtotals.push(z);

      if (i === items.length - 1) {
        total.userID = z.userID;
        total.isTotal = true;
        itemswithtotals.push(total);
        total = {};
      } else if (z.userID.id !== items[i + 1].userID.id) {
        total.userID = z.userID;
        total.isTotal = true;
        itemswithtotals.push(total);
        total = {};
      }
    });
    // console.log(JSON.stringify(items,null,4))
    itemswithtotals.forEach((z, i) => {
      consumablehistoryheader.forEach((a) => {
        if (isNaN(z[a])) z[a] = 0;
      });
    });

    this.setState({
      consumablehistory: itemswithtotals,
      consumablehistoryheader,
    });
  }

  async saveConsumeSetupSection() {
    await Utils.updateConsumeSetup({
      id: this.state.editid,
      setup: JSON.stringify({
        fixedAsset: this.state.fixedAssetChips,
        consumables: this.state.consumableAssetChips,
      }),
      defaultConsumptionPattern: JSON.stringify(
        this.state.consumableAssetPattern
      ),
    });
    this.doSnackBar("Saved consumables setup", true);
    this.componentDidMount();
  }

  async deletePromoterPlusPromotion(input) {
    // Utils.log(this.props.location.state.id)
    let s = await Utils.deletePromoterPlusPromotion(
      this.props.history.location.search.replace("?edit=", "")
    );
    // console.log(s);

    for (var i = 0; i < s.promotionScheduler.items.length; i++) {
      await Utils.deletePromoterPlusSchedule(s.promotionScheduler.items[i].id);
    }

    this.props.history.push("/");
  }

  async componentDidMount() {
    // Auth.currentAuthenticatedUser().catch(() =>
    //   this.props.history.push("/signin")
    // );
    // this.doSnackBar("Getting data. Please wait...");
    // if (isMobile) this.props.history.push("/mobile");
    // var date = new Date(Date.now());
    // date.setHours(date.getHours() + 2);
    //
    this.doSnackBar("Getting data...");

    // let p = await Auth.currentAuthenticatedUser();
    //
    // let t = await Utils.getUserAlwaysOnline(p.username);
    // // if (t.label === "All") this.setState({ alert: "DONT DO THIS IN RED MODE" });
    // if (t.type !== "Admin") this.props.history.push("/");
    // this.setState({ label: t.label });
    // let cl = await Utils.getAllClients({ label: { eq: t.label } });
    // let pl = await Utils.getAllProductLines({ label: { eq: t.label } });
    // this.setState({
    //   users: await Utils.getAllUsers({
    //     label: { eq: t.label },
    //     name: { notContains: "~" }
    //   }),
    //   allclients: cl,
    //   allproductlines: pl
    // });
    // var promo = {};
    // console.log(this.props.history.location)

    var urltype = this.props.history.location.search;
    console.log({ urltype });
    // if (urltype.indexOf("edit") > -1) {
    // this.setState({ status: "Getting data. Please wait..." });
    // this.doSnackBar("Getting data. Please wait...");
    let promo = await Utils.getPromotionByPromotionIdNEW2(
      urltype.replace("?edit=", "").split("&")[0]
    );
    console.log({ promo });
    let res = promo.promotionScheduler.items
      .map((z) => z.responses.items)
      .flat()
      .map((z) => JSON.parse(z.response));

    console.log({ res });
    this.setState({ res });
    // var tt = [];
    // var r = promo.promotionScheduler.items[0];
    // r.promotionid = promo.id
    // r.promoterId = r.user.id
    // r.promoterLocation = r.location
    // r.promoterStartDate = new Date(r.startdate);
    // r.promoterEndDate = new Date(r.enddate);
    // for (var i = 0; i < 3000; i++) {
    //   tt.push(r);
    // }
    // await Promise.all(tt.map(Utils.createPromotionScheduleNORETURN));
    // var scheduleids = [];
    // promo.promotionScheduler.items.forEach(function(s) {
    //   scheduleids.push(s.id);
    // });
    // let users = await Promise.all(scheduleids.map(Utils.getScheduleById));
    // promo.promotionScheduler.items.forEach(function(s) {
    //   users.forEach(function(u) {
    //     if (s.id === u.id) {
    //       s.userid = u.user.id;
    //       s.username = u.user.name;
    //     }
    //   });
    // });
    // var promoters = [];
    // if (promo.photos === null) promo.photos = JSON.stringify(this.state.photos);
    // promo.promotionScheduler.items.forEach(function(s) {
    //   var item = {};
    //   item.promoterEndDate = s.enddate.split("T")[0];
    //   item.promoterEndTime = s.enddate.split("T")[1].substr(0, 5);
    //   item.promoterStartDate = s.startdate.split("T")[0];
    //   item.promoterStartTime = s.startdate.split("T")[1].substr(0, 5);
    //   item.promoterLocation = JSON.parse(s.location);
    //   if (item.promoterLocation === null)
    //     item.promoterLocation = { address: "" };
    //   item.promoterId = s.user.id;
    //   item.promoterName = s.user.name;
    //   item.scheduleID = s.id;
    //   promoters.push(item);
    // });
    //
    // promoters = promoters.sort((a, b) => {
    //   if (
    //     a.promoterName + a.promoterStartDate >
    //     b.promoterName + b.promoterStartDate
    //   )
    //     return 1;
    //   else if (
    //     a.promoterName + a.promoterStartDate <
    //     b.promoterName + b.promoterStartDate
    //   )
    //     return -1;
    //   else return 0;
    // });
    // let cons = await Utils.getPromoterPlusConsumeSetup(promo.id);
    // this.setState({
    //   promotionName: promo.promotionName,
    //   startDate: new Date(promo.startdate),
    //   endDate: new Date(promo.enddate),
    //   startTime: promo.startdate.split("T")[1].substr(0, 5),
    //   endTime: promo.enddate.split("T")[1].substr(0, 5),
    //   client: promo.client.id,
    //   productline: promo.productLine,
    //   products: JSON.parse(promo.products),
    //   promoters: promoters,
    //   edit: true,
    //   fixedAssetChips: JSON.parse(cons.setup).fixedAsset,
    //   consumableAssetChips: JSON.parse(cons.setup).consumables,
    //   consumableAssetPattern: JSON.parse(cons.defaultConsumptionPattern),
    //   editid: promo.id,
    //   questions: JSON.parse(promo.questions),
    //   photos: JSON.parse(promo.photos),
    //   colours: JSON.parse(promo.colours)
    // });
    // // }
    // this.setState({ statusbar: false });
  }

  getTimes() {
    return Utils.getTimeOptions().map((item, index) => (
      <MenuItem key={index} value={item}>
        {item}
      </MenuItem>
    ));
  }

  makeMapMarkers(input) {
    var output = [];
    input.forEach(function (e) {
      if (e.promoterLocation.geo) output.push(e);
    });
    // Utils.log(this.state.output);
    if (output.length > 0)
      return output.map((item, index) => (
        <AnyReactComponent
          key={index}
          lat={item.promoterLocation.geo.lat}
          lng={item.promoterLocation.geo.lng}
          text={item.name}
        />
      ));
    else return null;
  }

  getNames(input) {
    var output = "";
    input.forEach(function (e, idx) {
      if (idx > 0) output += ", ";
      output += e.name;
    });
    return output;
  }

  getClientNames() {
    return this.state.allclients.map((item, index) => (
      <MenuItem key={index} value={item.id} name={item.name}>
        {item.clientname}
      </MenuItem>
    ));
  }

  getProductNames() {
    return this.state.allproductlines.map((item, index) => (
      <MenuItem key={index} value={item.id} name={item.name}>
        {item.productline}
      </MenuItem>
    ));
  }

  checker(input) {
    // console.log(input);
  }

  async deleteSchedule(input) {
    this.doSnackBar("Deleting. Please wait...");
    await Utils.deletePromoterPlusSchedule(input);
    this.componentDidMount();
  }
  async saveSchedule(input) {
    this.doSnackBar("Saving. Please wait...");
    input.id = input.scheduleID;

    input.promoterLocation = JSON.stringify(input.promoterLocation);
    var t3 = new Date(input.promoterStartDate + "T" + input.promoterStartTime);
    t3.setHours(t3.getHours() + 2);
    input.promoterStartDate = t3;
    delete input.promoterStartTime;
    t3 = new Date(input.promoterEndDate + "T" + input.promoterEndTime);
    t3.setHours(t3.getHours() + 2);
    input.promoterEndDate = t3;
    delete input.promoterEndTime;
    await Utils.updatePromotionSchedule(input);
    this.componentDidMount();
  }

  async saveNewSchedule(input) {
    this.doSnackBar("Saving. Please wait...");
    input.promotionid = this.state.editid;

    input.promoterLocation = JSON.stringify(input.promoterLocation);
    var t3 = new Date(input.promoterStartDate + "T" + input.promoterStartTime);
    t3.setHours(t3.getHours() + 2);
    input.promoterStartDate = t3;
    delete input.promoterStartTime;
    t3 = new Date(input.promoterEndDate + "T" + input.promoterEndTime);
    t3.setHours(t3.getHours() + 2);
    input.promoterEndDate = t3;
    delete input.promoterEndTime;
    await Utils.createPromotionSchedule(input);
    this.componentDidMount();
  }

  async savePromotion(input) {
    // if (this.state.edit) {
    input.id = this.state.editid;
    await Utils.updatePromotion(input);
    var newpromoters = [];
    var existingpromoters = [];
    // var listcurrent = [];
    // s.promotionScheduler.items.forEach(function(e) {
    //   listcurrent.push(e.id);
    // });
    // listcurrent.forEach(function(e) {
    //   var del = true;
    //   input.promoters.forEach(function(q) {
    //     if (q.scheduleID === e) del = false;
    //   });
    //   if (del) {
    //     Utils.deletePromoterPlusSchedule(e);
    //   }
    // });
    console.log("input");
    console.log(input);
    console.log(this.state.editid);
    input.promoters.forEach(function (e) {
      if (!e.scheduleID) newpromoters.push(e);
      else existingpromoters.push(e);
    });
    // await Utils.getPromotionByPromotionId(
    //   this.props.history.location.search.replace("?edit=", "")
    // );
    newpromoters.forEach(function (e) {
      e.promotionid = this.state.editid;
      e.promoterLocation = JSON.stringify(e.promoterLocation);
      var t3 = new Date(e.promoterStartDate + "T" + e.promoterStartTime);
      t3.setHours(t3.getHours() + 2);
      e.promoterStartDate = t3;
      delete e.promoterStartTime;
      t3 = new Date(e.promoterEndDate + "T" + e.promoterEndTime);
      t3.setHours(t3.getHours() + 2);
      e.promoterEndDate = t3;
      delete e.promoterEndTime;
    });

    existingpromoters.forEach(function (e) {
      e.id = e.scheduleID;
      e.promoterLocation = JSON.stringify(e.promoterLocation);
      var t3 = new Date(e.promoterStartDate + "T" + e.promoterStartTime);
      t3.setHours(t3.getHours() + 2);
      e.promoterStartDate = t3;
      delete e.promoterStartTime;
      t3 = new Date(e.promoterEndDate + "T" + e.promoterEndTime);
      t3.setHours(t3.getHours() + 2);
      e.promoterEndDate = t3;
      delete e.promoterEndTime;
    });
    console.log(newpromoters.length);
    console.log(existingpromoters.length);
    await Promise.all(newpromoters.map(Utils.createPromotionSchedule));

    await Promise.all(existingpromoters.map(Utils.updatePromotionSchedule));
    await Utils.updateConsumeSetup({
      id: this.state.editid,
      setup: JSON.stringify({
        fixedAsset: this.state.fixedAssetChips,
        consumables: this.state.consumableAssetChips,
      }),
      defaultConsumptionPattern: JSON.stringify(
        this.state.consumableAssetPattern
      ),
    });
    // this.setState({ status: "Saved" });

    this.setState({ statusbar: false });

    //this.props.history.push("/");
    // } else {
    //   // Utils.log("creationinput");
    //   // Utils.log(input);
    //   let s = await Utils.createPromotion(input);
    //   // Utils.log("s");
    //   // Utils.log(s);
    //   input.promoters.forEach(function(e) {
    //     e.promotionid = s.id;
    //     e.promoterLocation = JSON.stringify(e.promoterLocation);
    //     var t3 = new Date(e.promoterStartDate + "T" + e.promoterStartTime);
    //     t3.setHours(t3.getHours() + 2);
    //     e.promoterStartDate = t3;
    //     delete e.promoterStartTime;
    //     t3 = new Date(e.promoterEndDate + "T" + e.promoterEndTime);
    //     t3.setHours(t3.getHours() + 2);
    //     e.promoterEndDate = t3;
    //     delete e.promoterEndTime;
    //   });
    //
    //   // Utils.log("input.promoters");
    //   // Utils.log(input.promoters);
    //   // let f = await Utils.createPromotionSchedule(input.promoters[0]);
    //   // Utils.log(f);
    //   const promises = input.promoters.map(Utils.createPromotionSchedule);
    //   await Promise.all(promises);
    //   await Utils.createConsumeSetup({
    //     id: s.id,
    //     setup: JSON.stringify({
    //       fixedAsset: this.state.fixedAssetChips,
    //       consumables: this.state.consumableAssetChips
    //     }),
    //     defaultConsumptionPattern: JSON.stringify(
    //       this.state.consumableAssetPattern
    //     )
    //   });
    //
    //   var sw = this.state.consumableAdjustments;
    //   sw.forEach(z => {
    //     z.usage = JSON.stringify([{ item: z.element, amount: -z.value }]);
    //     delete z.element;
    //     delete z.value;
    //     z.scheduleID = "adjustment";
    //     z.promoterPlusConsumedConsumableIDId = s.id;
    //     z.timestamp = new Date(Date.now());
    //     z.userID = z.id;
    //     delete z.id;
    //   });
    //   // this.doSnackBar("Saving please wait...");
    //   await Promise.all(sw.map(Utils.createConsumeResponse));
    //
    //   // Utils.log(results);
    //   // Utils.log("done");
    //   this.setState({ promoters: [] });
    //   // this.setState({ status: "Saved" });
    //
    //   this.doSnackBar("Saved");
    this.props.history.push("/");
    // }
  }

  fix(input) {
    if (input.length > 0) {
      let head=input[0].map((z) => z.question)
      let res = [input[0].map((z) => z.question).join(",")];


      input.forEach((item, i) => {
        let it=[]
        head.forEach((itemh, ih) => {
          let found = item.find(a=>a.question===itemh)
          if (found)
          it.push( found.response||"")
          else it.push( "")

        });
        res.push(it.join(","))


      });
console.log(res)
      return res.map(a=><div>{a}</div>);
    } else return "";
  }

  render() {
    // console.log(this.state.promoters)
    return (
      <div style={{ color: "#666" ,}}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.state.statusbar}
          autoHideDuration={this.state.statusbarstayopen ? 3000 : null}
          onClose={() => this.setState({ statusbar: false })}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{this.state.statusbartext}</span>}
        />
        <div style={{ color: "#666" , fontSize:20, margin:20}}>
        {this.fix(this.state.res)}
      </div>
      </div>
    );
  }
}
export default withRouter(Promotions);
