import React from "react";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/AddCircle";
import PersonPinCircleIcon from "@material-ui/icons/PersonPinCircle";

class SearchFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
      search: ""
    };
  }

  matcher(itm, arr) {
    var output = true;
    arr.forEach(function(e) {
      if (e.promoterId === itm) output = false;
    });
    return output;
  }
  setBlink(input){
    if (typeof input === 'undefined')
      return "#f00"
      else return null

  }

  matcherSearch(itm, searchtext) {
    var output = false;
    if (itm.toLowerCase().indexOf(searchtext.toLowerCase()) > -1) output = true;
    if (searchtext === "") output = true;
    return output;
  }

  geofilter(inputusers, inputlocation, distance) {
    let output = [];
    inputusers.forEach(a => {
      if (a.homelocation != null && typeof a.homelocation.geo !== "undefined") {
        var dLat =
          (a.homelocation.geo.lat - inputlocation.geo.lat) * 0.0174532925199433;
        var dLon =
          (a.homelocation.geo.lng - inputlocation.geo.lng) * 0.0174532925199433;
        var ae =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.sin(dLon / 2) *
            Math.sin(dLon / 2) *
            Math.cos(inputlocation.geo.lat * 0.0174532925199433) *
            Math.cos(inputlocation.geo.lng * 0.0174532925199433);
        var c = 2 * Math.atan2(Math.sqrt(ae), Math.sqrt(1 - ae)) * 6371 * 1.3;
        if (c < distance) output.push(a);
      }
    });

    return output;
  }

  render() {
    return (
      <div>
        <Dialog
          open={this.state.dialogOpen}
          onClose={() => this.setState({ dialogOpen: false })}
          aria-labelledby="simple-dialog-title"
        >
          <DialogTitle id="simple-dialog-title">{this.props.title}</DialogTitle>
          <div style={{ margin: 10, display: "flex", flexDirection: "row" }}>
            <TextField
              style={{ color: "#374799", width: "100%" }}
              id="outlined-name"
              label="Search"
              value={this.state.search}
              margin="normal"
              variant="outlined"
              onChange={change =>
                this.setState({ search: change.target.value })
              }
            />
            <Button
              color="primary"
              style={{ margin: 2 }}
              onClick={() => {
                this.setState({ search: "" });
              }}
            >
              clear
            </Button>
          </div>
          <div style={{ margin: 10 }}>Available</div>
          <div
            style={{
              width: "500px",

              margin: 10,
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap"
            }}
          >
            {this.geofilter(this.props.users, this.props.locationInput, 10).map(
              (item, index) => (
                <div key={index}>
                  {(item.type === "Promoter") &
                  this.matcherSearch(item.name, this.state.search) ? (
                    <Button
                      color="primary"
                      variant="contained"
                      style={{ margin: 2 }}
                      onClick={() => {
                        this.setState({ dialogOpen: false });
                        this.props.onChange(item);
                      }}
                    >
                      {item.name + " "}
                      <AddIcon style={{ marginLeft: 5 }} />
                    </Button>
                  ) : (
                    <div />
                  )}
                </div>
              )
            )}
          </div>

          <Button
            color="primary"
            variant="contained"
            style={{ margin: 50 }}
            onClick={() => {
              this.setState({ dialogOpen: false });
            }}
          >
            Done
          </Button>
        </Dialog>

        <Button
          onClick={() => {
            this.setState({ dialogOpen: true });
          }}
        >
          <PersonPinCircleIcon />
        </Button>
      </div>
    );
  }
}

export default SearchFilter;
